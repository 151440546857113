import React, { useRef, useState, useEffect, useCallback } from 'react';
import RecordRTC from 'recordrtc';

const VideoRecorder = () => {
    const [isRecording, setIsRecording] = useState(false);
    const [videoURL, setVideoURL] = useState('');
    const [usingFrontCamera, setUsingFrontCamera] = useState(true);
    const videoRef = useRef(null);
    const playbackVideoRef = useRef(null);
    const recorderRef = useRef(null);
    const streamRef = useRef(null);
    
    // Define getCameraConstraints outside of startCamera since it doesn't depend on any state
    const getCameraConstraints = useCallback(() => ({
        video: {
            facingMode: usingFrontCamera ? 'user' : 'environment',
            frameRate: { ideal: 240 }
        }
    }), [usingFrontCamera]);

    const startCamera = useCallback(async () => {
        if (streamRef.current) {
            streamRef.current.getTracks().forEach(track => track.stop());
        }

        try {
            streamRef.current = await navigator.mediaDevices.getUserMedia(getCameraConstraints());
            if (videoRef.current) {
                videoRef.current.srcObject = streamRef.current;
            }
        } catch (error) {
            console.error('Error accessing the camera', error);
        }
    }, [getCameraConstraints]);

    const toggleCamera = () => {
        setUsingFrontCamera(!usingFrontCamera);
    };

    const startRecording = () => {
        recorderRef.current = new RecordRTC(streamRef.current, {
            type: 'video',
        });
        recorderRef.current.startRecording();
        setIsRecording(true);
    };

    const stopRecording = () => {
        recorderRef.current.stopRecording(() => {
            const blob = recorderRef.current.getBlob();
            setVideoURL(URL.createObjectURL(blob));
        });
        setIsRecording(false);
    };

    const navigateFrames = (direction) => {
        if (!playbackVideoRef.current) return;

        const frameRate = 240; // Assuming 240 fps for demonstration
        const frameDuration = 1 / frameRate;
        playbackVideoRef.current.currentTime += frameDuration * direction;
    };

    useEffect(() => {
        startCamera();

        return () => {
            if (streamRef.current) {
                streamRef.current.getTracks().forEach(track => track.stop());
            }
        };
    }, [startCamera]);

    return (
        <div>
            <video ref={videoRef} autoPlay playsInline muted style={{ width: '100%' }}></video>
            <div>
                <button onClick={!isRecording ? startRecording : stopRecording}>
                    {!isRecording ? 'Start Recording' : 'Stop Recording'}
                </button>
                <button onClick={toggleCamera} disabled={isRecording}>Switch Camera</button>
            </div>
            {videoURL && (
                <div>
                    <video src={videoURL} controls ref={playbackVideoRef} style={{ width: '100%' }} />
                    <button onClick={() => navigateFrames(-1)}>Previous Frame</button>
                    <button onClick={() => navigateFrames(1)}>Next Frame</button>
                </div>
            )}
        </div>
    );
};

export default VideoRecorder;
