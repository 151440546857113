import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  Typography,
  MenuItem,
  Select,
  FormControl,
  IconButton,
  Tooltip
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase-config';
import Divider from '@mui/material/Divider';

const exerciseComponents = [
  "Strength",
  "Speed",
  "Endurance",
  "ROM",
  "Coordination",
  "Football",
  "Competition"
];

const ExerciseDetailIcon = ({ name, icon, selected, onSelect }) => {
  return (
    <Tooltip title={name}>
      <IconButton
        onClick={() => onSelect(name)}
        sx={{
          borderRadius: '50%',
          border: `2px solid ${selected ? '#1EB980' : 'grey'}`,
          backgroundColor: selected ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
          '&:hover': {
            backgroundColor: 'lightgrey',
          },
          margin: '8px',
        }}
      >
        <img src={`/icons/${icon}.svg`} alt={name} style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
      </IconButton>
    </Tooltip>
  );
};

const Cycle = ({ mode, selectedGroup, selectedYear, onSaveCycle }) => {
  const [phases, setPhases] = useState([]);
  const [filteredPhases, setFilteredPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState('');
  const [selectedExercises, setSelectedExercises] = useState([]);
  const [exerciseSchedule, setExerciseSchedule] = useState({});
  const defaultTheme = createTheme({ palette: { mode } });

  useEffect(() => {
    if (selectedGroup) {
      const fetchGroupData = async () => {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          if (groupData.periods) {
            setPhases(groupData.periods);
          } else {
            setPhases([]);
          }
        }
      };
      fetchGroupData();
    }
  }, [selectedGroup]);

  useEffect(() => {
    if (selectedGroup && selectedPhase !== '') {
      const fetchPhaseData = async () => {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          if (groupData.periods && groupData.periods[selectedPhase].exerciseSchedule) {
            setExerciseSchedule(groupData.periods[selectedPhase].exerciseSchedule);
          } else {
            setExerciseSchedule({});
          }
        }
      };
      fetchPhaseData();
    }
  }, [selectedGroup, selectedPhase]);

  useEffect(() => {
    const filtered = phases.filter(phase => phase.year === selectedYear);
    setFilteredPhases(filtered);

    const currentWeek = getCurrentWeekOfYear(new Date());
    const currentPhase = filtered.find(phase => phase.range[0] <= currentWeek && phase.range[1] >= currentWeek);
    if (currentPhase) {
      setSelectedPhase(filtered.indexOf(currentPhase));
    }
  }, [phases, selectedYear]);

  const getCurrentWeekOfYear = (date) => {
    const startOfYear = new Date(date.getFullYear(), 0, 1);
    const pastDaysOfYear = (date - startOfYear) / 86400000;
    return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
  };

  const getDateFromWeek = (week, dayOffset = 0) => {
    const date = new Date(Date.UTC(selectedYear, 0, 1 + (week - 1) * 7 + dayOffset));
    const day = date.getUTCDay();
    const diff = date.getUTCDate() - day + (day === 0 ? -6 : 1);
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), diff + dayOffset));
  };

  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const handleExerciseSelect = (name) => {
    setSelectedExercises((prevSelected) =>
      prevSelected.includes(name) ? prevSelected.filter((exercise) => exercise !== name) : [...prevSelected, name]
    );
  };

  const handleDayClick = (date, week, period) => {
    const dayKey = `${date.toISOString().split('T')[0]}-${week}-${period}`;
    setExerciseSchedule((prevSchedule) => ({
      ...prevSchedule,
      [dayKey]: selectedExercises,
    }));
  };

  const renderDay = (date, week) => {
    const dayKeyAM = `${date.toISOString().split('T')[0]}-${week}-AM`;
    const dayKeyPM = `${date.toISOString().split('T')[0]}-${week}-PM`;
    return (
      <Grid
        item
        xs
        key={date}
        sx={{
          padding: '0px',
          textAlign: 'center',
          border: '1px solid',
          borderColor: '#303E4A',
          cursor: 'pointer',
          backgroundColor: '#1A2B3C',
          position: 'relative',
          display: 'grid',
          gridTemplateRows: 'auto 1fr auto',
          height: '100%',
        }}
      >
        {/* Date Header */}
        <Typography sx={{ color: '#fff', fontSize: '0.6rem', opacity: 0.7 }}>
          {date.toLocaleDateString(undefined, { weekday: 'short' })}, {date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}
        </Typography>
        
        {/* AM Section */}
        <Box 
          onClick={() => handleDayClick(date, week, 'AM')} 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'flex-start', 
            alignItems: 'center',
            gap: '2px',
            minHeight: '50px',
            height: '100%',
            borderTopRightRadius: week === 0 && date.getDay() === 0 ? '10px' : '0px', // Apply radius only for first week's Sunday AM box
          }}
        >
          <Typography 
            sx={{ 
              color: '#fff',  
              fontSize: '0.6rem', 
              opacity: 0.7,
              alignSelf: 'flex-start',
            }}
          >
            AM
          </Typography>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              gap: '2px',
            }}
          >
            {exerciseSchedule[dayKeyAM]?.map((exercise, i) => (
              <img
                key={i}
                src={`/icons/${exercise}.svg`}
                alt={exercise}
                style={{ width: '25px', height: '25px', margin: '2px' }}
              />
            ))}
          </Box>
        </Box>
        
        {/* Divider Line */}
        <Divider 
          sx={{ 
            width: '110%', // Adjust width to leave 3px padding on each side
            borderColor: '#303E4A', 
            margin: '0 auto', 
            marginLeft: '-8px', // Shift to the left by 3px
          }} 
        /> 
      
        {/* PM Section */}
        <Box 
          onClick={() => handleDayClick(date, week, 'PM')} 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column',
            justifyContent: 'flex-start', 
            alignItems: 'center',
            gap: '2px',
            minHeight: '50px',
            height: '100%',
          }}
        >
          <Typography 
            sx={{ 
              color: '#fff',  
              fontSize: '0.6rem', 
              opacity: 0.7,
              alignSelf: 'flex-start',
            }}
          >
            PM
          </Typography>
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              gap: '2px',
            }}
          >
            {exerciseSchedule[dayKeyPM]?.map((exercise, i) => (
              <img
                key={i}
                src={`/icons/${exercise}.svg`}
                alt={exercise}
                style={{ width: '25px', height: '25px', margin: '2px' }}
              />
            ))}
          </Box>
        </Box>
      </Grid>
    );
    
    
    
  };

  const renderWeek = (week, index) => {
    return (
      <Box key={week} sx={{ mb: '15px', display: 'flex', borderLeft: '4px solid none', marginLeft: '9px' }}>
        <Box sx={{ flex: 1, borderRadius: '10px' }}>
          <Grid container spacing={1}>
            {/* Extra column to display the week number with custom styling */}
            <Grid
              item
              sx={{
                padding: '10px',
                textAlign: 'center',
                border: '1px solid',
                borderColor: '#1EB980',  // Different border color
                backgroundColor: '#1EB980',  // Different background color
                width: '40px',  // Custom width
                position: 'relative',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                writingMode: 'vertical-rl',  // Vertical text
                transform: 'rotate(180deg)', // Flip text direction if needed
                whiteSpace: 'nowrap',
                borderBottomRightRadius: '8px',  // Optional: round the corners
                borderTopRightRadius: '8px',
              }}
            >
              <Typography sx={{ color: '#1A2B3C', fontSize: '1rem' }}>
                Week {index + 1}
              </Typography>
            </Grid>
  
            {/* Render days of the week */}
            {daysOfWeek.map((day, index) => {
              const date = getDateFromWeek(week, index);
              return renderDay(date, week);
            })}
          </Grid>
        </Box>
      </Box>
    );
  };
  

  const renderWeeks = () => {
    if (!filteredPhases[selectedPhase]) return null;
    const { range } = filteredPhases[selectedPhase];
    const weeks = [];
    for (let week = range[0]; week <= range[1]; week++) {
      weeks.push(renderWeek(week, weeks.length));
    }
    return weeks;
  };

  const saveSchedule = useCallback(async () => {
    if (selectedGroup && selectedPhase !== '') {
      try {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          if (!groupData.periods[selectedPhase]) {
            groupData.periods[selectedPhase] = {};
          }
          groupData.periods[selectedPhase].exerciseSchedule = exerciseSchedule;
          groupData.periods[selectedPhase].year = selectedYear;
          groupData.periods[selectedPhase].group = selectedGroup;
          await setDoc(doc(db, 'groups', selectedGroup), groupData);
          alert('Schedule saved successfully!');
        }
      } catch (error) {
        console.error('Error saving schedule: ', error);
      }
    } else {
      alert('Please select a group and a phase to save the schedule.');
    }
  }, [selectedGroup, selectedPhase, selectedYear, exerciseSchedule]);

  useEffect(() => {
    if (onSaveCycle) {
      onSaveCycle(saveSchedule);
    }
  }, [onSaveCycle, saveSchedule]);

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ width: '100%', padding: '0 50px' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            borderBottomLeftRadius: '20px',
            borderBottomRightRadius: '20px',
            px: '20px',
            alignItems: 'center',
            marginBottom: 2,
            backgroundColor: '#1A2B3C',
            position: 'sticky',
            top: 0,
            zIndex: 1,
          }}
        >
          <FormControl sx={{ minWidth: 140, mt: 3, marginBottom: 1 }}>
  <Select
    value={selectedPhase}
    onChange={(e) => setSelectedPhase(e.target.value)}
    sx={{
      color: '#fff',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      height: '35px', // Set the height to 35px
      borderRadius: '10px', // Add rounded corners
      '.MuiSelect-select': {
        backgroundColor: 'rgba(255, 255, 255, 0.1)', // 10% transparent white
        borderColor: 'transparent',
        height: '35px', // Match the height here as well
        paddingTop: '0', // Adjust padding to center text vertically
        paddingBottom: '0', // Adjust padding to center text vertically
        borderRadius: '10px', // Add rounded corners
        display: 'flex',
        alignItems: 'center', // Ensure the text is centered vertically
      },
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'transparent',
      },
    }}
    inputProps={{ style: { color: 'red', height: '35px', padding: '0' } }} // Ensure the input height matches
  >
    {filteredPhases.map((phase, index) => (
      <MenuItem key={index} value={index}>
        {phase.text || `Phase ${index + 1}`}
      </MenuItem>
    ))}
  </Select>
</FormControl>


          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2 }}>
            {exerciseComponents.map((comp, index) => (
              <ExerciseDetailIcon
                key={index}
                name={comp}
                icon={comp}
                selected={selectedExercises.includes(comp)}
                onSelect={handleExerciseSelect}
              />
            ))}
          </Box>
        </Box>
        
        {selectedPhase !== '' && (
          <Box sx={{ marginTop: 2 }}>
            {renderWeeks()}
          </Box>
        )}
      </Box>
    </ThemeProvider>
  );
};

export default Cycle;
