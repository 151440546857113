import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'; // Import CardContent
import CardMedia from '@mui/material/CardMedia'; // Import CardMedia
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


const items = [
  {
    image: '/SW4.jpg',
    title: 'SMART WALL',
    description:
      'Experience seamless group training support with instant technical feedback and advanced activity monitoring, ensuring each session is enhanced for optimal results and performance.',
  },
  {
    image: '/SP2.jpg',
    title: 'SMART POINT',
    description:
      'Elevate your individual training with our top-tier performance assessment standards, delivering unmatched precision in measuring power, balance, and mobility.',
  },
  {
    image: '/vp2.jpg',
    title: 'HORIZONTAL POINT',
    description:
      'Experience a revolutionary solution for real-time measurement of open-space gym-specific training activities, including velocity, acceleration, and braking capacity.',
  },
  {
    image: '/VP1.jpg',
    title: 'VERTICAL POINT',
    description:
      'Unlock your potential with comprehensive feedback on throwing actions, where ballistic power is crucial for achieving peak performance and maximizing your athletic capabilities.',
  },
  {
    image: '/LP.jpg',
    title: 'LIFTING POINT',
    description:
      'Incorporate Velocity-Based Training into any lifting exercise in your routine, with enriched feedback that includes Range of Motion, symmetry, and other key performance indicators.',
  },
  {
    image: '/FS1.jpg',
    title: 'FitCode SYSTEM',
    description:
      'Plan, monitor and report your session with minimal admin effort, tailored to your specific needs. This training managment platform provides pioneering training load insights of strenght programs.',
  },
 
];

export default function Products() {
  return (
    <Box
      id="products"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Products
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          Discover the State-of-The-Art Tools.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  image={item.image}
                  alt={item.title}
                  sx={{ height: 140 }} // Adjust height as needed
                />
                <CardContent>
                  <Stack direction="column" color="inherit" spacing={1}>
                    <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                    <div>
                      <Typography fontWeight="medium" gutterBottom>
                        {item.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'grey.400' }}>
                        {item.description}
                      </Typography>
                    </div>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}