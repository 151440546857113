import * as React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Typography, TextField, Button, Card, CardContent, CardMedia, Grid,
  MenuItem, Select, InputLabel, FormControl, Dialog, DialogActions, DialogContent, DialogTitle, IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { collection, addDoc, getDocs, updateDoc, doc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../firebase-config';
import Webcam from 'react-webcam';

const Players = ({ groups, loadFirebaseData }) => {
  const [selectedGroup, setSelectedGroup] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [showAddPlayerModal, setShowAddPlayerModal] = React.useState(false);
  const [showPlayerDetailsModal, setShowPlayerDetailsModal] = React.useState(false);
  const [selectedPlayer, setSelectedPlayer] = React.useState(null);
  const [players, setPlayers] = React.useState([]);
  const [firstName, setFirstName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [dob, setDob] = React.useState('');
  const [sport, setSport] = React.useState('');
  const [gender, setGender] = React.useState('');
  const [sportLevel, setSportLevel] = React.useState('');
  const [photo, setPhoto] = React.useState(null);
  const [newGroupName, setNewGroupName] = React.useState('');
  const [showWebcam, setShowWebcam] = React.useState(false);
  const [webcamPhoto, setWebcamPhoto] = React.useState('');
  const webcamRef = React.useRef(null);

  React.useEffect(() => {
    loadPlayers();
  }, []);

  const loadPlayers = async () => {
    const playersSnapshot = await getDocs(collection(db, 'players'));
    const playersList = playersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setPlayers(playersList);
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setWebcamPhoto(imageSrc);
    setPhoto(imageSrc);
    setShowWebcam(false);
  };

  const uploadImage = async (imageData, fileName) => {
    try {
      const blob = await fetch(imageData).then(res => res.blob());
      const storageRef = ref(storage, `photos/${fileName}`);
      await uploadBytes(storageRef, blob);
      const url = await getDownloadURL(storageRef);
      return url;
    } catch (error) {
      console.error("Error uploading image:", error);
      throw error;
    }
  };

  const addPlayer = async () => {
    const newPlayer = { firstName, lastName, dob, sport, gender, sportLevel, group: selectedGroup, photoURL: '' };
    if (photo) {
      try {
        newPlayer.photoURL = await uploadImage(photo, `${firstName}_${lastName}.jpg`);
      } catch (error) {
        console.error("Failed to upload photo:", error);
        return;
      }
    }

    try {
      await addDoc(collection(db, 'players'), newPlayer);
      setFirstName('');
      setLastName('');
      setDob('');
      setSport('');
      setGender('');
      setSportLevel('');
      setPhoto(null);
      setSelectedGroup('');
      loadFirebaseData();
      loadPlayers();
      setShowAddPlayerModal(false);
    } catch (e) {
      console.error('Error adding player: ', e);
    }
  };

  const updatePlayer = async () => {
    try {
      const updatedPlayer = { ...selectedPlayer };

      if (photo) {
        try {
          updatedPlayer.photoURL = await uploadImage(photo, `${selectedPlayer.firstName}_${selectedPlayer.lastName}.jpg`);
        } catch (error) {
          console.error("Failed to upload photo:", error);
          return;
        }
      }

      await updateDoc(doc(db, 'players', updatedPlayer.id), updatedPlayer);
      loadFirebaseData();
      loadPlayers();
      setShowPlayerDetailsModal(false);
      setPhoto(null);
      alert('Player details updated successfully');
    } catch (e) {
      console.error('Error updating player: ', e);
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const filteredPlayers = players.filter(player =>
    (!selectedGroup || player.group === selectedGroup) &&
    (searchText === '' || Object.values(player).some(value => value.toString().toLowerCase().includes(searchText.toLowerCase())))
  );

  const handlePlayerClick = (player) => {
    setSelectedPlayer(player);
    setShowPlayerDetailsModal(true);
  };

  const handlePlayerFieldChange = (field, value) => {
    setSelectedPlayer(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const addGroup = async () => {
    if (newGroupName.trim() === '') return;
    const newGroup = { name: newGroupName.trim() };
    try {
      await addDoc(collection(db, 'groups'), newGroup);
      setNewGroupName('');
      loadFirebaseData();
    } catch (e) {
      console.error('Error adding group: ', e);
    }
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 2 }}>
          <FormControl sx={{ minWidth: 120 }}>
            <InputLabel id="group-select-label">Group</InputLabel>
            <Select
              labelId="group-select-label"
              id="group-select"
              value={selectedGroup}
              label="Group"
              onChange={(e) => setSelectedGroup(e.target.value)}
            >
              <MenuItem value=""><em>None</em></MenuItem>
              {groups.map((group, index) => (
                <MenuItem key={index} value={group.name}>{group.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Search"
            variant="outlined"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <IconButton color="primary" onClick={() => setShowAddPlayerModal(true)}>
            <AddIcon />
          </IconButton>
        </Box>
        <Grid container spacing={2}>
          {filteredPlayers.map((player, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card onClick={() => handlePlayerClick(player)}>
                {player.photoURL && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={player.photoURL}
                    alt={`${player.firstName} ${player.lastName}'s photo`}
                    style={{ borderRadius: '50%' }}
                  />
                )}
                <CardContent>
                  <Typography gutterBottom variant="h6" component="div">
                    {player.firstName} {player.lastName}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog open={showAddPlayerModal} onClose={() => setShowAddPlayerModal(false)}>
        <DialogTitle>Add Player</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              label="First Name"
              variant="outlined"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              fullWidth
            />
            <TextField
              label="Last Name"
              variant="outlined"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              fullWidth
            />
          </Box>
          <TextField
            label="Date of Birth"
            variant="outlined"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            type="date"
            InputLabelProps={{ shrink: true }}
            fullWidth
            sx={{ mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="gender-select-label">Gender</InputLabel>
            <Select
              labelId="gender-select-label"
              id="gender-select"
              value={gender}
              label="Gender"
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="sport-select-label">Sport Level</InputLabel>
            <Select
              labelId="sport-select-label"
              id="sport-select"
              value={sportLevel}
              label="Sport Level"
              onChange={(e) => setSportLevel(e.target.value)}
            >
              <MenuItem value="professional">Professional</MenuItem>
              <MenuItem value="semi-professional">Semi-Professional</MenuItem>
              <MenuItem value="recreational">Recreational</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="group-select-label">Group</InputLabel>
            <Select
              labelId="group-select-label"
              id="group-select"
              value={selectedGroup}
              label="Group"
              onChange={(e) => setSelectedGroup(e.target.value)}
            >
              {groups.map((group, index) => (
                <MenuItem key={index} value={group.name}>{group.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
            <TextField
              label="New Group"
              variant="outlined"
              value={newGroupName}
              onChange={(e) => setNewGroupName(e.target.value)}
              fullWidth
            />
            <Button variant="contained" color="primary" onClick={addGroup}>Add Group</Button>
          </Box>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileChange}
            style={{ display: 'none' }}
            id="photo-upload"
          />
          <label htmlFor="photo-upload">
            <Button component="span" variant="contained" color="primary" sx={{ mb: 2 }}>
              Upload Photo
            </Button>
          </label>
          <Button variant="contained" color="primary" onClick={() => setShowWebcam(true)}>Take Photo with Webcam</Button>
          {showWebcam && (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
              <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                width={320}
                height={240}
                videoConstraints={{ width: 320, height: 240, facingMode: 'user' }}
                style={{ borderRadius: '50%' }}
              />
              <Button variant="contained" color="primary" onClick={handleCapture} sx={{ mt: 2 }}>Capture Photo</Button>
            </Box>
          )}
          {webcamPhoto && (
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
              <img src={webcamPhoto} alt="Webcam" style={{ borderRadius: '50%' }} />
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddPlayerModal(false)} color="secondary">Cancel</Button>
          <Button onClick={addPlayer} color="primary">Add</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showPlayerDetailsModal} onClose={() => setShowPlayerDetailsModal(false)}>
        <DialogTitle>Edit Player</DialogTitle>
        {selectedPlayer && (
          <DialogContent>
            <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
              <TextField
                label="First Name"
                variant="outlined"
                value={selectedPlayer.firstName}
                onChange={(e) => handlePlayerFieldChange('firstName', e.target.value)}
                fullWidth
              />
              <TextField
                label="Last Name"
                variant="outlined"
                value={selectedPlayer.lastName}
                onChange={(e) => handlePlayerFieldChange('lastName', e.target.value)}
                fullWidth
              />
            </Box>
            <TextField
              label="Date of Birth"
              variant="outlined"
              value={selectedPlayer.dob}
              onChange={(e) => handlePlayerFieldChange('dob', e.target.value)}
              type="date"
              InputLabelProps={{ shrink: true }}
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="gender-select-label">Gender</InputLabel>
              <Select
                labelId="gender-select-label"
                id="gender-select"
                value={selectedPlayer.gender}
                label="Gender"
                onChange={(e) => handlePlayerFieldChange('gender', e.target.value)}
              >
                <MenuItem value="male">Male</MenuItem>
                <MenuItem value="female">Female</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="sport-select-label">Sport Level</InputLabel>
              <Select
                labelId="sport-select-label"
                id="sport-select"
                value={selectedPlayer.sportLevel}
                label="Sport Level"
                onChange={(e) => handlePlayerFieldChange('sportLevel', e.target.value)}
              >
                <MenuItem value="professional">Professional</MenuItem>
                <MenuItem value="semi-professional">Semi-Professional</MenuItem>
                <MenuItem value="recreational">Recreational</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="group-select-label">Group</InputLabel>
              <Select
                labelId="group-select-label"
                id="group-select"
                value={selectedPlayer.group}
                label="Group"
                onChange={(e) => handlePlayerFieldChange('group', e.target.value)}
              >
                {groups.map((group, index) => (
                  <MenuItem key={index} value={group.name}>{group.name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileChange(e)}
              style={{ display: 'none' }}
              id="edit-photo-upload"
            />
            <label htmlFor="edit-photo-upload">
              <Button component="span" variant="contained" color="primary" sx={{ mb: 2 }}>
                Upload Photo
              </Button>
            </label>
            <Button variant="contained" color="primary" onClick={() => setShowWebcam(true)}>Take Photo with Webcam</Button>
            {showWebcam && (
              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mb: 2 }}>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  width={320}
                  height={240}
                  videoConstraints={{ width: 320, height: 240, facingMode: 'user' }}
                  style={{ borderRadius: '50%' }}
                />
                <Button variant="contained" color="primary" onClick={handleCapture} sx={{ mt: 2 }}>Capture Photo</Button>
              </Box>
            )}
            {webcamPhoto && (
              <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                <img src={webcamPhoto} alt="Webcam" style={{ borderRadius: '50%' }} />
              </Box>
            )}
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={() => setShowPlayerDetailsModal(false)} color="secondary">Cancel</Button>
          <Button onClick={() => { updatePlayer(); }} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

Players.propTypes = {
  groups: PropTypes.array.isRequired,
  loadFirebaseData: PropTypes.func.isRequired
};

export default Players;
