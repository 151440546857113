import * as React from 'react';
import { Box, Avatar, Typography } from '@mui/material';
import FullCalendar from '@fullcalendar/react'; // Import FullCalendar
import dayGridPlugin from '@fullcalendar/daygrid'; // Import the dayGrid plugin
import timeGridPlugin from '@fullcalendar/timegrid'; // Import the timeGrid plugin
import interactionPlugin from '@fullcalendar/interaction'; // Import the interaction plugin
import NavbarAthlete from './NavbarAthlete'; // Import the NavbarAthlete component
import PersonIcon from '@mui/icons-material/Person';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import './CalendarPage.css';

const CalendarPage = () => {
  const currentDate = new Date().toLocaleDateString();
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleAvatarClick = () => {
    navigate('/athleteprofile'); // Navigate to the AthleteProfile page
  };

  return (
    <Box sx={{ height: '100vh-40px', backgroundColor: '#1A2B3C', overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: 2, bgcolor: '#303E4A', height: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ bgcolor: '#1EB980', width: 30, height: 30 }} onClick={handleAvatarClick} style={{ cursor: 'pointer' }}>
            <PersonIcon sx={{ color: '#ffffff', fontSize: 24 }} />
          </Avatar>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
          <Typography sx={{ color: '#ffffff', fontFamily: 'Roboto Condensed', fontWeight: 'bold', marginRight: 4 }}>
            {currentDate}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ height: 'calc(100vh - 50px - 50px - 30px)', backgroundColor: '#1A2B3C' }}> {/* Adjust the height */}
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek,timeGridDay'
          }}
          height="100%"
          contentHeight="auto"
          fixedWeekCount={false}
          editable={true}
          selectable={true}
          selectMirror={true}
          dayMaxEvents={true}
          weekends={true}
          themeSystem="bootstrap" // Use the Bootstrap theme for a modern look
          eventColor="#1EB980" // Custom event color
          eventTextColor="#FFFFFF" // Custom event text color
          select={info => alert('Selected date range: ' + info.startStr + ' to ' + info.endStr)}
          eventClick={info => alert('Event: ' + info.event.title)}
          dateClick={info => alert('Clicked on date: ' + info.dateStr)}
        />
      </Box>
      <NavbarAthlete />
    </Box>
  );
};

export default CalendarPage;
