import React, { useState, useEffect } from 'react';
import { Box, Typography, Divider, Button, Collapse, IconButton, Tooltip, Grid, Select, MenuItem, FormControl, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, List, ListItem, ListItemText, Snackbar, Alert } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ArrowBackIos, ArrowForwardIos, Visibility, VisibilityOff } from '@mui/icons-material';
import { doc, getDoc, getDocs, setDoc, collection } from 'firebase/firestore';
import { db } from './firebase-config';
import AddDrill from './AddDrill';
import ExerciseSelectDialog from './ExerciseSelectDialog';
import PlayersCard from './PlayersCard';

const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const trainingComponents = [
  'Strength', 'Speed', 'Endurance', 'ROM', 'Coordination', 'Warming Up', 'Cool Down', 'Football', 'Meeting'
];

const mainSetOptions = ['Circuit', 'Block'];
const afterSetOptions = ['Plus Sets', 'Joker Sets', 'Back-Off Sets', 'Myo Reps', 'Dynamic Effort', 'Isometric'];

const getCurrentWeek = () => {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  return Math.ceil(days / 7);
};

const getCurrentDay = () => {
  return new Date().toISOString().split('T')[0];
};

const Day = ({ mode, selectedGroup, selectedYear }) => {
  const [phases, setPhases] = useState([]);
  const [filteredPhases, setFilteredPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState('');
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek());
  const [selectedDay, setSelectedDay] = useState(getCurrentDay());
  const [exerciseSchedule, setExerciseSchedule] = useState({});
  const [expandedComponents, setExpandedComponents] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openExerciseDialog, setOpenExerciseDialog] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [periodColor, setPeriodColor] = useState('#1A2B3C');
  const [shadedPlayerId, setShadedPlayerId] = useState(null);

  const [openComponentDialog, setOpenComponentDialog] = useState(false);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [methods, setMethods] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState({});

  const defaultTheme = createTheme({ palette: { mode } });

  useEffect(() => {
    const fetchGroupData = async () => {
      if (selectedGroup) {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          if (groupData.periods) {
            setPhases(groupData.periods);
          } else {
            setPhases([]);
          }
          if (groupData.targets) {
            setSelectedTargets(groupData.targets);
          }
        }
      }
    };
    fetchGroupData();
  }, [selectedGroup]);

  useEffect(() => {
    const filtered = phases.filter(phase => phase.year === selectedYear);
    setFilteredPhases(filtered);
    if (filtered.length > 0) {
      const phaseIndex = filtered.findIndex(
        phase => selectedWeek >= phase.range[0] && selectedWeek <= phase.range[1]
      );
      setSelectedPhase(phaseIndex !== -1 ? phaseIndex : 0);
    }
  }, [phases, selectedYear, selectedWeek]);

  useEffect(() => {
    const fetchPhaseData = async () => {
      if (selectedGroup && selectedPhase !== '' && filteredPhases[selectedPhase]) {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          const phaseData = groupData.periods[selectedPhase];
          if (phaseData && phaseData.exerciseSchedule) {
            setExerciseSchedule(phaseData.exerciseSchedule);
          } else {
            setExerciseSchedule({});
          }
        }
      }
    };
    fetchPhaseData();
  }, [selectedGroup, selectedPhase, filteredPhases]);

  useEffect(() => {
    const fetchMethods = async () => {
      const methodsSnapshot = await getDocs(collection(db, 'methods'));
      const methodsList = methodsSnapshot.docs.map(doc => doc.data());
      setMethods(methodsList);
    };
    fetchMethods();
  }, []);

  const getDateFromWeek = (week, dayOffset = 0) => {
    const date = new Date(Date.UTC(selectedYear, 0, 1 + (week - 1) * 7 + dayOffset));
    const day = date.getUTCDay();
    const diff = date.getUTCDate() - day + (day === 0 ? -6 : 1);
    return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), diff + dayOffset));
  };

  const handleDayClick = (day, week) => {
    const date = getDateFromWeek(week, daysOfWeek.indexOf(day));
    setSelectedWeek(week);
    setSelectedDay(date.toISOString().split('T')[0]);
  };

  const handleWeekChange = direction => {
    setSelectedWeek(prevWeek => prevWeek + direction);
  };

  const handleExerciseDialogClose = () => {
    setOpenExerciseDialog(false);
  };

  const handleExerciseSelect = newSelectedExercises => {
    const updatedExercises = { ...exerciseSchedule };
    const dayKey = `${selectedDay}-${selectedWeek}-${selectedPeriod}`;
    if (!updatedExercises[dayKey]) {
      updatedExercises[dayKey] = [];
    }
    newSelectedExercises.forEach(exercise => {
      updatedExercises[dayKey].push(exercise.name);
    });
    setExerciseSchedule(updatedExercises);
    saveExerciseSchedule(updatedExercises);
    setOpenExerciseDialog(false);
  };

  const saveExerciseSchedule = async (updatedSchedule) => {
    if (selectedGroup && selectedPhase !== '') {
      try {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          if (!groupData.periods[selectedPhase]) {
            groupData.periods[selectedPhase] = {};
          }
          groupData.periods[selectedPhase].exerciseSchedule = updatedSchedule;
          await setDoc(doc(db, 'groups', selectedGroup), groupData);
          setSnackbarMessage('Schedule saved successfully!');
          setSnackbarSeverity('success');
        }
      } catch (error) {
        console.error('Error saving schedule: ', error);
        setSnackbarMessage('Error saving schedule');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    }
  };

  const handleOpenComponentDialog = period => {
    setSelectedPeriod(period);
    const dayKey = `${selectedDay}-${selectedWeek}-${period}`;
    setSelectedComponents(exerciseSchedule[dayKey] || []);
    setOpenComponentDialog(true);
  };

  const handleCloseComponentDialog = () => {
    setOpenComponentDialog(false);
    setSelectedComponents([]);
  };

  const handleSaveComponents = () => {
    const updatedSchedule = { ...exerciseSchedule };
    const dayKey = `${selectedDay}-${selectedWeek}-${selectedPeriod}`;
    updatedSchedule[dayKey] = selectedComponents;
    setExerciseSchedule(updatedSchedule);
    saveExerciseSchedule(updatedSchedule);
    setOpenComponentDialog(false);
  };

  const handleToggleComponent = component => {
    const currentIndex = selectedComponents.indexOf(component);
    const newChecked = [...selectedComponents];
    if (currentIndex === -1) {
      newChecked.push(component);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedComponents(newChecked);
  };

  const handleMethodChange = (dayKey, component, field, value) => {
    const updatedSchedule = { ...exerciseSchedule };
    if (!updatedSchedule[dayKey]) {
      updatedSchedule[dayKey] = {};
    }
    if (!updatedSchedule[dayKey][component]) {
      updatedSchedule[dayKey][component] = {};
    }
    updatedSchedule[dayKey][component][field] = value;
    setExerciseSchedule(updatedSchedule);
    saveExerciseSchedule(updatedSchedule);
  };

  const renderPeriodDetails = period => {
    const dayDate = new Date(selectedDay).toLocaleDateString(undefined, {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });
    const dayKey = `${selectedDay}-${selectedWeek}-${period}`;
    const componentExercises = exerciseSchedule[dayKey] || [];

    return (
      <Box key={period} sx={{ mb: 2, backgroundColor: 'transparent', borderRadius: '10px' }}>
        <Box 
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          p: 0,
          mb: 0,
          minHeight: '20px',
          backgroundColor: '#005D57',
          width: '160px',
          borderTopLeftRadius: '7px',
          borderTopRightRadius: '7px'
        }}
      >
        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center',
            mr: 1 // Margin right for spacing
          }}
        >
          <Box 
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: periodColor, // Adjust the color as needed
              borderTopLeftRadius: '7px', // This makes it a small circle, change as needed
              mr: 1,// Margin right for spacing
              ml: -2.4
            }}
          />
          <Typography
            sx={{ 
              color: 'lightgrey', 
              mb: 0, 
              cursor: 'pointer', 
              fontSize: '0.775rem', 
              textTransform: 'uppercase', 
              textAlign: 'center' 
            }}
            onClick={() => handleOpenComponentDialog(period)}
          >
            {period}
          </Typography>
        </Box>
        <Typography
          sx={{ 
            color: 'lightgrey', 
            mb: 0, 
            ml: 2, 
            textAlign: 'center', 
            fontSize: '0.775rem', 
          }}
        >
          {dayDate}
        </Typography>

        
      </Box>

        <Divider sx={{ backgroundColor: '#303E4A', height: '4px', my: 0 }} />
        {componentExercises.map((component, index) => (
          <React.Fragment key={index}>
            <Box
              sx={{
                mb: 0,
                p: 0.0,
                backgroundColor: '#1A2B3C',
                borderRadius: '4px',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '40px' }}>
                <Typography
                  sx={{ color: '#1EB980', px: 2, mb: 0, cursor: 'pointer', textTransform: 'uppercase' }}
                  onClick={() => handleOpenComponentDialog(period)}
                >
                  {component}
                </Typography>
                <Typography sx={{ color: '#fff', ml: 1 }}>
                  {selectedTargets[component] || ''}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  
                  <FormControl sx={{ minWidth: 120, mx: 1, flexGrow: 1 }}>
                    <Select
                      value={exerciseSchedule[dayKey]?.[component]?.mainSet || ''}
                      onChange={(e) => handleMethodChange(dayKey, component, 'mainSet', e.target.value)}
                      displayEmpty
                      sx={{
                        color: '#fff',
                        '.MuiOutlinedInput-notchedOutline': { border: 'transparent' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'transparent' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { border: 'transparent' },
                        '& .MuiSelect-icon': { color: '#fff' },
                        '.MuiSelect-root': { padding: '2px' },
                        fontSize: '0.875rem',
                      }}
                    >
                      <MenuItem value="">
                        <em>Main Set</em>
                      </MenuItem>
                      {mainSetOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ minWidth: 120, mx: 1, flexGrow: 1 }}>
                    <Select
                      value={exerciseSchedule[dayKey]?.[component]?.afterSet || ''}
                      onChange={(e) => handleMethodChange(dayKey, component, 'afterSet', e.target.value)}
                      displayEmpty
                      sx={{
                        color: '#fff',
                        '.MuiOutlinedInput-notchedOutline': { border: 'transparent' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'transparent' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { border: 'transparent' },
                        '& .MuiSelect-icon': { color: '#fff' },
                        '.MuiSelect-root': { padding: '2px' },
                        fontSize: '0.875rem',
                      }}
                    >
                      <MenuItem value="">
                        <em>After Set</em>
                      </MenuItem>
                      {afterSetOptions.map(option => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <FormControl sx={{ minWidth: 120, mx: 1, flexGrow: 1 }}>
                    <Select
                      value={exerciseSchedule[dayKey]?.[component]?.method || ''}
                      onChange={(e) => handleMethodChange(dayKey, component, 'method', e.target.value)}
                      displayEmpty
                      sx={{
                        color: '#fff',
                        '.MuiOutlinedInput-notchedOutline': { border: 'transparent' },
                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'transparent' },
                        '&:hover .MuiOutlinedInput-notchedOutline': { border: 'transparent' },
                        '& .MuiSelect-icon': { color: '#fff' },
                        '.MuiSelect-root': { padding: '2px' },
                        fontSize: '0.875rem',
                      }}
                    >
                      <MenuItem value="">
                        <em>Method</em>
                      </MenuItem>
                      {methods.filter(method => method['Component Name'] === component).map((method, i) => (
                        <MenuItem key={i} value={method.Method}>
                          {method.Method}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <IconButton
                    onClick={() => setExpandedComponents(prevState => ({
                      ...prevState,
                      [dayKey]: prevState[dayKey] === component ? null : component
                    }))}
                    sx={{ color: '#fff' }}
                  >
                    {expandedComponents[dayKey] === component ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Box>
              </Box>
              <Collapse in={expandedComponents[dayKey] === component} timeout="auto" unmountOnExit>
                <Box sx={{ mt: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '0px' }}>
                  <AddDrill trainingComponent={component} />
                </Box>
              </Collapse>
            </Box>
            <Divider sx={{ backgroundColor: '#303E4A', height: '4px' }} />
          </React.Fragment>
        ))}
      </Box>
    );
  };

  const renderDayDetails = () => {
    if (!selectedDay) return null;

    return (
      <Box sx={{ mt: 2 }}>
        {renderPeriodDetails('AM')}
        {renderPeriodDetails('PM')}
      </Box>
    );
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ width: '100%', padding: '0 0px', backgroundColor: '#303E4A' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 0,
            marginLeft: '50px',
            marginRight: '50px',
            justifyContent: 'space-between',
            padding: '0px',
            backgroundColor: '#1A2B3C',
            borderTopLeftRadius: '0px',
            borderTopRightRadius: '0px',
          }}
        >
          <IconButton onClick={() => handleWeekChange(-1)} sx={{ color: '#fff' }}>
            <ArrowBackIos sx={{ pt: '10px' }}/>
          </IconButton>
          <Box sx={{ display: 'flex', justifyContent: 'center',pt: '20px'  }}>
            <Grid container spacing={1}>
              {daysOfWeek.map((day, index) => {
                const date = getDateFromWeek(selectedWeek, index);
                const dayKey = date.toISOString().split('T')[0];
                return (
                  <Grid
                    item
                    xs
                    key={day}
                    sx={{
                      padding: '1px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      position: 'relative',
                    }}
                    onClick={() => handleDayClick(day, selectedWeek)}
                  >
                    <Tooltip
                      title={date.toLocaleDateString(undefined, {
                        month: 'short',
                        day: 'numeric',
                      })}
                    >
                      <Box
                        sx={{
                          width: '30px',
                          height: '30px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          borderRadius: '50%',
                          border: 'transparent',
                          backgroundColor:
                            selectedDay === dayKey
                              ? '#1EB980'
                              : 'rgba(255, 255, 255, 0.1)',
                          '&:hover': {
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                          },
                        }}
                      >
                        <Typography sx={{ color: '#fff' }}>{day[0]}</Typography>
                      </Box>
                    </Tooltip>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <IconButton onClick={() => handleWeekChange(1)} sx={{ color: '#fff', marginBottom: 0 }}>
            <ArrowForwardIos />
          </IconButton>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: 0,
            marginTop: 0,
            marginLeft: '50px',
            marginRight: '50px',
            justifyContent: 'space-between',
            padding: '5px',
            backgroundColor: '#1A2B3C',
            borderBottomLeftRadius: '15px',
            borderBottomRightRadius: '15px',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'transparent',
              borderRadius: '10px',
              padding: '10px'
            }}
          >
            <PlayersCard onPlayerSelect={setPeriodColor} onShadePlayer={setShadedPlayerId} shadedPlayerId={shadedPlayerId} />
          </Box>
        </Box>
        
        {selectedDay !== '' && (
         <Box 
            sx={{ 
              margin: '0 50px', 
              marginTop: 0, 
              padding: '0px', 
              backgroundColor: '#303E4A', 
              borderRadius: '10px', 
              width: 'calc(100% - 100px)' 
            }}
          >
            {renderDayDetails()}
          </Box>
        )}

        <ExerciseSelectDialog
          open={openExerciseDialog}
          onClose={handleExerciseDialogClose}
          onSave={handleExerciseSelect}
        />

        <Dialog open={openComponentDialog} onClose={handleCloseComponentDialog}>
          <DialogTitle>Select Training Components</DialogTitle>
          <DialogContent>
            <List>
              {trainingComponents.map(component => (
                <ListItem key={component} button onClick={() => handleToggleComponent(component)}>
                  <Checkbox checked={selectedComponents.includes(component)} />
                  <ListItemText primary={component} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseComponentDialog} color="primary">
              Cancel
            </Button>
            <Button onClick={handleSaveComponents} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
        
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
};

export default Day;
