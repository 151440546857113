import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Tooltip, Dialog, DialogActions, DialogContent, DialogTitle, Button, List, ListItem, ListItemText, Checkbox, Snackbar, Alert } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './firebase-config';

const trainingComponents = ['Strength', 'Speed', 'Endurance', 'ROM', 'Coordination', 'Warming Up', 'Cool Down', 'Football', 'Meeting'];

const getCurrentWeek = () => {
  const currentDate = new Date();
  const startDate = new Date(currentDate.getFullYear(), 0, 1);
  const days = Math.floor((currentDate - startDate) / (24 * 60 * 60 * 1000));
  return Math.ceil(days / 7);
};

const getDateFromWeek = (year, week, dayOffset = 0) => {
  const date = new Date(Date.UTC(year, 0, 1 + (week - 1) * 7 + dayOffset));
  const day = date.getUTCDay();
  const diff = date.getUTCDate() - day + (day === 0 ? -6 : 1);
  return new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), diff + dayOffset));
};

const Week = ({ mode, selectedGroup, selectedYear }) => {
  const [phases, setPhases] = useState([]);
  const [filteredPhases, setFilteredPhases] = useState([]);
  const [selectedPhase, setSelectedPhase] = useState('');
  const [selectedWeek, setSelectedWeek] = useState(getCurrentWeek());
  const [exerciseSchedule, setExerciseSchedule] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedDayKey, setSelectedDayKey] = useState('');
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [componentTimes, setComponentTimes] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const defaultTheme = createTheme({ palette: { mode } });

  useEffect(() => {
    if (selectedGroup) {
      const fetchGroupData = async () => {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          if (groupData.periods) {
            setPhases(groupData.periods);
          } else {
            setPhases([]);
          }
        }
      };
      fetchGroupData();
    }
  }, [selectedGroup]);

  useEffect(() => {
    const filtered = phases.filter(phase => phase.year === selectedYear);
    setFilteredPhases(filtered);
    if (filtered.length > 0) {
      const phaseIndex = filtered.findIndex(phase => selectedWeek >= phase.range[0] && selectedWeek <= phase.range[1]);
      setSelectedPhase(phaseIndex !== -1 ? phaseIndex : 0);
    }
  }, [phases, selectedYear, selectedWeek]);

  useEffect(() => {
    if (selectedGroup && selectedPhase !== '') {
      const fetchPhaseData = async () => {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          if (groupData.periods && groupData.periods[selectedPhase].exerciseSchedule) {
            setExerciseSchedule(groupData.periods[selectedPhase].exerciseSchedule);
          } else {
            setExerciseSchedule({});
          }
        }
      };
      fetchPhaseData();
    }
  }, [selectedGroup, selectedPhase]);

  const handleWeekClick = week => {
    setSelectedWeek(week);
  };

  const handleOpenDialog = (session, dayKey) => {
    setSelectedDayKey(dayKey);
    setSelectedComponents(exerciseSchedule[dayKey] || []);
    setComponentTimes(exerciseSchedule[`${dayKey}-times`] || {}); // Load times if they exist
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedComponents([]);
    setComponentTimes({});
  };

  const handleSaveComponents = () => {
    const updatedSchedule = { ...exerciseSchedule };
    updatedSchedule[selectedDayKey] = selectedComponents;
    updatedSchedule[`${selectedDayKey}-times`] = componentTimes; // Save times
    setExerciseSchedule(updatedSchedule);
    saveSchedule(updatedSchedule);
    setOpenDialog(false);
  };

  const handleToggleComponent = component => {
    const currentIndex = selectedComponents.indexOf(component);
    const newChecked = [...selectedComponents];
    if (currentIndex === -1) {
      newChecked.push(component);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedComponents(newChecked);
  };

  const handleTimeChange = (component, time) => {
    setComponentTimes(prevTimes => ({
      ...prevTimes,
      [component]: time,
    }));
  };

  const saveSchedule = async (updatedSchedule) => {
    if (selectedGroup && selectedPhase !== '') {
      try {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          if (!groupData.periods[selectedPhase]) {
            groupData.periods[selectedPhase] = {};
          }
          groupData.periods[selectedPhase].exerciseSchedule = updatedSchedule;
          await setDoc(doc(db, 'groups', selectedGroup), groupData);
          setSnackbarMessage('Schedule saved successfully!');
          setSnackbarSeverity('success');
        }
      } catch (error) {
        console.error('Error saving schedule: ', error);
        setSnackbarMessage('Error saving schedule');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    }
  };

  const renderWeeks = () => {
    if (!filteredPhases[selectedPhase]) return null;
    const { range } = filteredPhases[selectedPhase];
    const weeks = [];
    for (let week = range[0]; week <= range[1]; week++) {
      weeks.push(
        <Grid item xs key={week}>
          <Tooltip title={`Week ${week}`}>
            <Box
              onClick={() => handleWeekClick(week)}
              sx={{
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '50%',
                cursor: 'pointer',
                backgroundColor: selectedWeek === week ? '#1EB980' : 'rgba(255, 255, 255, 0.1)',
                '&:hover': {
                  backgroundColor: 'rgba(255, 255, 255, 0.2)',
                },
              }}
            >
              <Typography sx={{ color: '#fff', fontSize: '0.9rem' }}>{`W${week}`}</Typography>
            </Box>
          </Tooltip>
        </Grid>
      );
    }
    return weeks;
  };

  const renderDay = (date, week) => {
    const dayKeyAM = `${date.toISOString().split('T')[0]}-${week}-AM`;
    const dayKeyPM = `${date.toISOString().split('T')[0]}-${week}-PM`;

    return (
      <Grid
        item
        xs={12 / 7} // Ensures all day boxes take up 1/7th of the row
        key={date}
        sx={{
          padding: '8px',
          textAlign: 'center',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography sx={{ color: '#fff' }}>{date.toLocaleDateString(undefined, { weekday: 'short' })}</Typography>
        <Typography sx={{ color: '#fff' }}>{date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}</Typography>

        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
          {renderExercises(exerciseSchedule[dayKeyAM] || [], 'AM', dayKeyAM)}
          {renderExercises(exerciseSchedule[dayKeyPM] || [], 'PM', dayKeyPM)}
        </Box>
      </Grid>
    );
  };

  const renderExercises = (exercises, session, dayKey) => (
    <Box sx={{ flex: 1, p: 1, backgroundColor: 'rgba(255, 255, 255, 0.2)', display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
        <Typography
          sx={{ color: '#fff', fontSize: '0.8rem', fontWeight: 'bold', cursor: 'pointer', textAlign: 'left', flexBasis: '66.67%' }}
          onClick={() => handleOpenDialog(session, dayKey)}
        >
          {session}
        </Typography>
      </Box>
      {exercises.map((exercise, i) => (
        <Box key={i} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
          <Typography sx={{ color: '#fff', textAlign: 'left', flexBasis: '66.67%' }}>{exercise}</Typography>
          <input
            type="time"
            value={componentTimes[exercise] || ''}
            onChange={(e) => handleTimeChange(exercise, e.target.value)}
            style={{
              width: '33.33%', // 1/3 of the container
              color: '#fff',
              backgroundColor: '#303E4A',
              border: 'none',
              padding: '4px',
              borderRadius: '4px',
              textAlign: 'center',
            }}
          />
        </Box>
      ))}
    </Box>
  );

  const renderDays = () => {
    const days = [];
    for (let i = 0; i < 7; i++) {
      const date = getDateFromWeek(selectedYear, selectedWeek, i);
      days.push(renderDay(date, selectedWeek));
    }
    return days;
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ width: '100%', padding: '0 30px' }}>
        {/* Week Icons Container */}
        <Box sx={{ display: 'flex', justifyContent: 'center', my: '2.2px', height:'90px', borderBottomRightRadius: '20px',borderBottomLeftRadius: '20px', backgroundColor: '#1A2B3C', marginLeft: '20px', marginRight: '20px' }}>
          <Grid container spacing={0.875} justifyContent="center" sx={{ width: 'auto', marginTop:'20px' }}>
            {renderWeeks()}
          </Grid>
        </Box>

        {/* Days Content */}
        <Box sx={{ marginTop: 3, marginLeft: '30px', marginRight: '30px' }}>
          <Grid container spacing={1} alignItems="stretch">
            {renderDays()}
          </Grid>
        </Box>
      </Box>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Select Training Components</DialogTitle>
        <DialogContent>
          <List>
            {trainingComponents.map((component) => (
              <ListItem key={component} button onClick={() => handleToggleComponent(component)}>
                <Checkbox checked={selectedComponents.includes(component)} />
                <ListItemText primary={component} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveComponents} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
};

export default Week;
