import { GlobalStyles } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import {
  CssBaseline, Box, ToggleButton, ToggleButtonGroup, FormControl, InputLabel, Select, MenuItem, IconButton, Tooltip, Snackbar, Alert, InputAdornment,
  Dialog, DialogActions, DialogContent, DialogTitle, Button
} from '@mui/material';
import { Save as SaveIcon, Delete as DeleteIcon, FileCopy as CopyIcon, Group as GroupIcon, CalendarToday as CalendarTodayIcon, Upload as UploadIcon } from '@mui/icons-material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Year from './components/year';
import Cycle from './components/cycle';
import Day from './components/Day';
import Week from './components/week';
import { getDocs, collection, doc, deleteDoc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase-config';
import getLPTheme from './getLPTheme';
import Methodology from './Methodology'; // Import Methodology component
import * as XLSX from 'xlsx'; // Import XLSX to handle file uploads

const getCurrentWeekOfYear = (date) => {
  const startOfYear = new Date(date.getFullYear(), 0, 1);
  const pastDaysOfYear = (date - startOfYear) / 86400000;
  return Math.ceil((pastDaysOfYear + startOfYear.getDay() + 1) / 7);
};

const Plan = () => {
  const [mode, setMode] = useState('dark');
  const [viewType, setViewType] = useState('year');
  const [showCustomTheme] = useState(false);
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState('');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [periods, setPeriods] = useState([]);
  const [events, setEvents] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [selectedPhase, setSelectedPhase] = useState('');
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = useState(false); // State for file upload dialog
  const [uploadedMethods, setUploadedMethods] = useState([]); // State for uploaded methods
  const [players, setPlayers] = useState([]); // Add state for players
  const cycleSaveRef = useRef(null);

  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  useEffect(() => {
    const fetchGroups = async () => {
      const groupsSnapshot = await getDocs(collection(db, 'groups'));
      const groupsList = groupsSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      groupsList.sort((a, b) => a.name.localeCompare(b.name));
      setGroups(groupsList);
      if (groupsList.length > 0) {
        setSelectedGroup(groupsList[0].id);
      }
    };
    fetchGroups();
  }, []);

  useEffect(() => {
    const fetchPhases = async () => {
      if (selectedGroup) {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          const phasesList = (groupData.periods || []).filter(phase => phase.year === selectedYear);
          setPeriods(phasesList);

          const currentWeek = getCurrentWeekOfYear(new Date());
          const currentPhase = phasesList.find(phase => phase.range[0] <= currentWeek && phase.range[1] >= currentWeek);
          if (currentPhase) {
            setSelectedPhase(phasesList.indexOf(currentPhase));
          }
        }
      }
    };
    fetchPhases();
  }, [selectedGroup, selectedYear]);

  // Fetch players based on selected group
  useEffect(() => {
    const fetchPlayers = async () => {
      if (selectedGroup) {
        const playersSnapshot = await getDocs(collection(db, 'players'));
        const playersList = playersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        const filteredPlayers = playersList.filter((player) => player.group === selectedGroup);
        setPlayers(filteredPlayers);
      }
    };
    fetchPlayers();
  }, [selectedGroup]);

  const toggleColorMode = () => {
    setMode((prevMode) => (prevMode === 'dark' ? 'light' : 'dark'));
  };

  const handlePeriodsEventsChange = ({ periods, events }) => {
    setPeriods(periods);
    setEvents(events);
  };

  const saveData = async () => {
    if (selectedGroup) {
      try {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          await setDoc(doc(db, 'groups', selectedGroup), { ...groupData, periods, events });
          if (cycleSaveRef.current) {
            await cycleSaveRef.current();
          }
          setSnackbarMessage('Data saved successfully!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error('Error saving data: ', error);
        setSnackbarMessage('Error saving data. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage('Please select a group to save the data.');
      setSnackbarSeverity('warning');
      setSnackbarOpen(true);
    }
  };

  const deleteData = async () => {
    if (selectedGroup && selectedYear) {
      try {
        await deleteDoc(doc(db, 'groups', selectedGroup));
        setSnackbarMessage('Data deleted successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
        setGroups(groups.filter((group) => group.id !== selectedGroup));
        setSelectedGroup(groups.length > 0 ? groups[0].id : '');
      } catch (error) {
        console.error('Error deleting data: ', error);
        setSnackbarMessage('Error deleting data. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const copyData = async () => {
    if (selectedGroup && selectedYear) {
      try {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          const newGroupId = `${selectedGroup}_copy`;
          await setDoc(doc(db, 'groups', newGroupId), groupData);
          setGroups([...groups, { id: newGroupId, ...groupData }]);
          setSelectedGroup(newGroupId);
          setSnackbarMessage('Data copied successfully!');
          setSnackbarSeverity('success');
          setSnackbarOpen(true);
        }
      } catch (error) {
        console.error('Error copying data: ', error);
        setSnackbarMessage('Error copying data. Please try again.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    }
  };

  const handleFileUploadOpen = () => {
    setFileUploadDialogOpen(true);
  };

  const handleFileUploadClose = () => {
    setFileUploadDialogOpen(false);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      setUploadedMethods(sheet); // Update the state with the uploaded methods
    };

    reader.readAsBinaryString(file);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <GlobalStyles styles={{ html: { overflowY: 'scroll' } }} />
      <CssBaseline />
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        {/* Full Width Header */}
        <Box sx={{ width: '100%', bgcolor: 'primary.main', p: 0 }}>
          <Navbar mode={mode} toggleColorMode={toggleColorMode} />
        </Box>
  
        {/* Fixed Position for Buttons and Dropdowns */}
        <Box 
          sx={{ 
            width: 'calc(100% - 100px)', 
            height: '40px',
            bgcolor: '#1A2B3C', 
            left: '50px', 
            right: '50px', 
            color: 'text.primary', 
            position: 'fixed', 
            top: 50, 
            zIndex: 1000, 
            pt: 0, 
            pb: 0,
            borderTopLeftRadius: '20px',
            borderTopRightRadius: '20px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: 5,
            
          }}
        >
          <FormControl sx={{ minWidth: 100, height: '40px', display: 'flex', alignItems: 'center' }}>
            <InputLabel
              sx={{
                color: '#fff',
                backgroundColor: '#1A2B3C',
                padding: '0 4px',
                position: 'absolute',
                top: '50%',
                left: '10px',
                zIndex: 1,
                transform: 'translateY(-50%)',
                '&.Mui-focused': {
                  top: '50%',
                  transform: 'translateY(-50%)', // Ensure the label doesn't move when focused
                },
              }}
            >
            </InputLabel>
            <Select
              value={selectedGroup}
              onChange={(e) => setSelectedGroup(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <GroupIcon sx={{ color: '#fff', fontSize: '20px' }} />
                </InputAdornment>
              }
              sx={{
                color: '#fff',
                '.MuiOutlinedInput-notchedOutline': { border: 'none' }, // Remove the border
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Remove the border when focused
                '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Remove the border on hover
                height: '40px', // Adjust height as needed
                minHeight: 'unset', // Ensure the height is not automatically set
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 2, // Adjust padding as needed
              }}
              inputProps={{ style: { color: '#fff', padding: '8px 0', minHeight: 'unset' } }} // Adjust padding to fit the content
            >
              {groups.map((group) => (
                <MenuItem key={group.id} value={group.id}>
                  {group.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          
          <ToggleButtonGroup
  value={viewType}
  exclusive
  onChange={(event, newViewType) => {
    if (newViewType !== null) {
      setViewType(newViewType);
    }
  }}
  sx={{
    display: 'flex',
flexWrap: 'nowrap', // Keep buttons in one row
borderColor: '#303E4A',
maxWidth: '50px',
borderWidth: 1,
borderRadius: 1,
alignItems: 'center',
justifyContent: 'center', // Center horizontally
pt: 0,
width: '100%',
 // Ensure full width
  }}
>
  <ToggleButton
    value="day"
    sx={{
      flex: '1 1 auto',
      minWidth: { xs: '50px', sm: '50px', md: '100px' }, // Reduced width for smaller screens
      color: '#fff',
      backgroundColor: '#303E4A',
      borderColor: '#303E4A',
      borderWidth: 1,
      borderRadius: { xs: '0', sm: '0 0 30px 30px' },
      '&.Mui-selected': {
        backgroundColor: '#1EB980',
        color: '#fff',
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        color: '#fff',
      },
      textTransform: 'none',
      p: { xs: '4px', sm: '6px', md: '8px' }, // Adjust padding for smaller screens
      fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' }, // Adjust font size
    }}
  >
    DAY
  </ToggleButton>
  <ToggleButton
    value="week"
    sx={{
      flex: '1 1 auto',
      minWidth: { xs: '50px', sm: '70px', md: '100px' },
      color: '#fff',
      backgroundColor: '#303E4A',
      borderColor: '#303E4A',
      borderWidth: 1,
      borderRadius: { xs: '0', sm: '0 0 30px 30px' },
      '&.Mui-selected': {
        backgroundColor: '#1EB980',
        color: '#fff',
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        color: '#fff',
      },
      textTransform: 'none',
      p: { xs: '4px', sm: '6px', md: '8px' },
      fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' },
    }}
  >
    WEEK
  </ToggleButton>
  <ToggleButton
    value="cycle"
    sx={{
      flex: '1 1 auto',
      minWidth: { xs: '50px', sm: '70px', md: '100px' },
      color: '#fff',
      backgroundColor: '#303E4A',
      borderColor: '#303E4A',
      borderWidth: 1,
      borderRadius: { xs: '0', sm: '0 0 30px 30px' },
      '&.Mui-selected': {
        backgroundColor: '#1EB980',
        color: '#fff',
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        color: '#fff',
      },
      textTransform: 'none',
      p: { xs: '4px', sm: '6px', md: '8px' },
      fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' },
    }}
  >
    CYCLE
  </ToggleButton>
  <ToggleButton
    value="year"
    sx={{
      flex: '1 1 auto',
      minWidth: { xs: '50px', sm: '70px', md: '100px' },
      color: '#fff',
      backgroundColor: '#303E4A',
      borderColor: '#303E4A',
      borderWidth: 1,
      borderRadius: { xs: '0', sm: '0 0 30px 30px' },
      '&.Mui-selected': {
        backgroundColor: '#1EB980',
        color: '#fff',
      },
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        color: '#fff',
      },
      textTransform: 'none',
      p: { xs: '4px', sm: '6px', md: '8px' },
      fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' },
    }}
  >
    YEAR
  </ToggleButton>
</ToggleButtonGroup>


          
          <FormControl sx={{ minWidth: 100, height: '40px', display: 'flex', alignItems: 'center' }}>
            <InputLabel
              sx={{
                color: '#fff',
                backgroundColor: '#1A2B3C',
                padding: '0 4px',
                position: 'absolute',
                top: '50%',
                left: '10px',
                zIndex: 1,
                transform: 'translateY(-50%)',
                '&.Mui-focused': {
                  top: '50%',
                  transform: 'translateY(-50%)', // Ensure the label doesn't move when focused
                },
              }}
            >
            </InputLabel>
            <Select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              startAdornment={
                <InputAdornment position="start">
                  <CalendarTodayIcon sx={{ color: '#fff', fontSize: '20px' }} />
                </InputAdornment>
              }
              sx={{
                color: '#fff',
                '.MuiOutlinedInput-notchedOutline': { border: 'none' }, // Remove the border
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Remove the border when focused
                '&:hover .MuiOutlinedInput-notchedOutline': { border: 'none' }, // Remove the border on hover
                height: '40px', // Adjust height as needed
                minHeight: 'unset', // Ensure the height is not automatically set
                display: 'flex',
                alignItems: 'center',
                paddingLeft: 0, // Adjust padding as needed
              }}
              inputProps={{ style: { color: '#fff', padding: '8px 0', minHeight: 'unset' } }} // Adjust padding to fit the content
            >
              {Array.from(new Array(10), (v, k) => k + new Date().getFullYear()).map((year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
  
        {/* Main Content */}
        <Box sx={{ flex: 1, pt: '88px', px: 0, bgcolor: '#303E4A', color: 'text.primary' }}>
          {viewType === 'year' && <Year mode={mode} selectedGroup={selectedGroup} selectedYear={selectedYear} onPeriodsEventsChange={handlePeriodsEventsChange} />}
          {viewType === 'cycle' && <Cycle mode={mode} selectedGroup={selectedGroup} selectedYear={selectedYear} onSaveCycle={(saveFunction) => (cycleSaveRef.current = saveFunction)} selectedPhase={selectedPhase} />}
          {viewType === 'day' && <Day mode={mode} selectedGroup={selectedGroup} selectedYear={selectedYear} players={players} />}
          {viewType === 'week' && <Week mode={mode} selectedGroup={selectedGroup} selectedYear={selectedYear} />}
          {viewType === 'methodology' && <Methodology uploadedMethods={uploadedMethods} />} {/* Pass the uploaded methods to Methodology */}
        </Box>
  
        {/* Admin Icons */}
        <Box
          sx={{
            position: 'fixed',
            right: 0,
            top: 'calc(160px + 16px)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 1,
            bgcolor: 'transparent',
            borderRadius: '8px',
            width: '40px',
          }}
        >
          <Tooltip title="Save">
            <IconButton onClick={saveData} sx={{ color: 'white', mb: 1, p: 0.5 }}>
              <SaveIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton onClick={deleteData} sx={{ color: 'white', mb: 1, p: 0.5 }}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copy">
            <IconButton onClick={copyData} sx={{ color: 'white', mb: 1, p: 0.5 }}>
              <CopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Upload">
            <IconButton onClick={handleFileUploadOpen} sx={{ color: 'white', mb: 1, p: 0.5 }}>
              <UploadIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
  
        <Footer />
  
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        
        <Dialog open={fileUploadDialogOpen} onClose={handleFileUploadClose}>
          <DialogTitle>Upload Excel File</DialogTitle>
          <DialogContent>
            <input
              accept=".xlsx, .xls"
              style={{ display: 'none' }}
              id="contained-button-file"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="contained-button-file">
              <Button variant="contained" color="primary" component="span">
                Upload Excel
              </Button>
            </label>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleFileUploadClose} color="primary">Cancel</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
  
  };
  
  export default Plan;
  