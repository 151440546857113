import * as React from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { Typography, ToggleButton, ToggleButtonGroup } from '@mui/material'; // Import ToggleButton and ToggleButtonGroup

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';
import { getDocs, collection } from 'firebase/firestore'; // Removed addDoc import as it's no longer used
import { db } from './firebase-config';
import Players from './components/Players';
import Exercises from './components/Exercises';

const Admin = () => {
  const [mode, setMode] = React.useState('dark');
  const [showCustomTheme, setShowCustomTheme] = React.useState(false);
  const [groups, setGroups] = React.useState([]);
  const [itemType, setItemType] = React.useState('exercise');
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  React.useEffect(() => {
    loadFirebaseData();
  }, []);

  const loadFirebaseData = async () => {
    const groupsSnapshot = await getDocs(collection(db, 'groups'));
    const groupsList = groupsSnapshot.docs.map((doc) => doc.data());
    setGroups(groupsList);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
      <CssBaseline />
      <Navbar mode={mode} toggleColorMode={toggleColorMode} />
      <Box sx={{ bgcolor: '#1A2B3C', p: 3, minHeight: '100vh', color: 'text.primary' }}>
        <Typography variant="h4" gutterBottom>.</Typography>

        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
          <ToggleButtonGroup
            value={itemType}
            exclusive
            onChange={(event, newItemType) => {
              if (newItemType !== null) {
                setItemType(newItemType);
              }
            }}
            sx={{ display: 'flex' }}
          >
            <ToggleButton value="exercise" sx={{ flex: 1 }}>
              Exercises
            </ToggleButton>
            <ToggleButton value="player" sx={{ flex: 1 }}>
              Players
            </ToggleButton>
           
          </ToggleButtonGroup>
        </Box>

        {itemType === 'exercise' ? (
          <Exercises loadFirebaseData={loadFirebaseData} />
        ) : (
          <Players groups={groups} loadFirebaseData={loadFirebaseData} />
        )}
      </Box>
      <Footer />
      <ToggleCustomTheme
        showCustomTheme={showCustomTheme}
        toggleCustomTheme={toggleCustomTheme}
      />
    </ThemeProvider>
  );
};

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
        backgroundColor: '#0D1620',
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        {/* Content for ToggleButtonGroup can be added here */}
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.bool.isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

Admin.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default Admin;
