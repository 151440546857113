import React, { useState, useEffect } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Box,
  Slider,
  IconButton,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  MenuItem,
  Select,
  FormControl,
  Checkbox,
  ListItemText,
  Dialog,
  DialogContent,
  DialogActions,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import DirectionsRunIcon from '@mui/icons-material/DirectionsRun';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import SpeedIcon from '@mui/icons-material/Speed';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { doc, getDoc, getDocs, collection } from 'firebase/firestore';
import { db } from '../firebase-config';

const Year = ({ mode, selectedGroup, selectedYear, onPeriodsEventsChange }) => {
  const [periods, setPeriods] = useState([]);
  const [events, setEvents] = useState([]);
  const [methods, setMethods] = useState([]);
  const [selectedTargets, setSelectedTargets] = useState({});
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);


  const defaultTheme = createTheme({ palette: { mode } });

  useEffect(() => {
    const fetchGroupData = async () => {
      if (selectedGroup && selectedYear) {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          const filteredPeriods = (groupData.periods || []).filter(p => p.year === selectedYear);
          const filteredEvents = (groupData.events || []).filter(e => e.year === selectedYear);
          setPeriods(filteredPeriods);
          setEvents(filteredEvents);
        } else {
          setPeriods([]);
          setEvents([]);
        }
      } else {
        setPeriods([]);
        setEvents([]);
      }
    };

    const fetchMethods = async () => {
      const querySnapshot = await getDocs(collection(db, 'methods'));
      const methodsList = querySnapshot.docs.map((doc) => doc.data());
      setMethods(methodsList);
    };

    fetchGroupData();
    fetchMethods();
  }, [selectedGroup, selectedYear]);

  useEffect(() => {
    if (onPeriodsEventsChange) {
      onPeriodsEventsChange({ periods, events });
    }
  }, [periods, events, onPeriodsEventsChange]);

  const handleSliderChange = (event, newValue) => {
    const ranges = [];
    for (let i = 0; i < newValue.length; i += 2) {
      ranges.push([newValue[i], newValue[i + 1]]);
    }
    setPeriods(periods.map((period, index) => ({ ...period, range: ranges[index] })));
  };

  const handleTextChange = (index, event) => {
    const newPeriods = periods.map((period, i) => (i === index ? { ...period, text: event.target.value } : period));
    setPeriods(newPeriods);
  };

  const handleMethodChange = (periodIndex, component, row, method) => {
    const newPeriods = periods.map((period, i) => {
      if (i === periodIndex) {
        const updatedMethods = { ...period.methods, [row]: { ...period.methods?.[row], [component]: method } };
        return {
          ...period,
          methods: updatedMethods,
        };
      }
      return period;
    });
    setPeriods(newPeriods);
    setSelectedTargets({ ...selectedTargets, [component]: method });
  };

  const addPeriod = () => {
    const lastPeriod = periods[periods.length - 1];
    const newStart = lastPeriod ? lastPeriod.range[1] + 1 : 0;
    const newEnd = newStart + 4 <= 56 ? newStart + 4 : 56;
    setPeriods([...periods, { id: periods.length + 1, range: [newStart, newEnd], text: '', year: selectedYear, methods: { row1: {}, row2: {} } }]);
  };

  const deleteLastPeriod = () => {
    const newPeriods = periods.slice(0, -1);
    setPeriods(newPeriods);
  };

  const addEvent = () => {
    setEvents([...events, { id: events.length + 1, name: '', startDate: '', endDate: '', location: '', notes: '', year: selectedYear }]);
  };

  const handleEventChange = (index, field, value) => {
    const newEvents = events.map((event, i) => (i === index ? { ...event, [field]: value } : event));
    setEvents(newEvents);
  };

  const handleDateSelection = (event) => {
    const selectedDate = event.target.value;

    if (selectedDates.includes(selectedDate)) {
      setSelectedDates(selectedDates.filter(date => date !== selectedDate));
    } else {
      setSelectedDates([...selectedDates, selectedDate]);
    }
  };

  const calculateLeftPosition = (date) => {
    const startDate = new Date(selectedYear, 0, 1);
    const endDate = new Date(selectedYear, 11, 31);
    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    const selectedDate = new Date(date);
    const daysFromStart = (selectedDate - startDate) / (1000 * 60 * 60 * 24);
    return (daysFromStart / totalDays) * 100;
  };

  const trainingComponents = [
    { name: 'Strength', icon: <FitnessCenterIcon /> },
    { name: 'Speed', icon: <SpeedIcon /> },
    { name: 'Endurance', icon: <DirectionsRunIcon /> },
    { name: 'Football', icon: <SportsSoccerIcon /> },
  ];

  const getUniqueTargets = (component) => {
    const uniqueTargets = new Set();
    methods.forEach((method) => {
      if (method['Component Name'] === component) {
        uniqueTargets.add(method.Target);
      }
    });
    return [...uniqueTargets];
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ width: '100%', padding: '0 50px', backgroundColor: '#303E4A' }}>
        <Box sx={{ position: 'relative', width: '100%', marginBottom: 2, borderBottomLeftRadius: '20px', WebkitBorderBottomRightRadius: '20px', backgroundColor: '#1A2B3C', height: '130px' }}>
          {periods.map((period, index) => {
            const startWeek = period.range[0];
            const endWeek = period.range[1];
            const midpoint = (startWeek + endWeek) / 2;
            const leftPosition = (midpoint / (new Date(selectedYear, 11, 31).getDay() === 4 ? 53 : 52)) * 100;

            return (
              <Box
                key={period.id}
                sx={{
                  position: 'absolute',
                  left: `${leftPosition}%`,
                  transform: 'translateX(-50%)',
                  width: '150px',
                  textAlign: 'center',
                  top: -10,
                  color: '#FFCF44',
                  marginTop: 4,
                }}
              >
                <TextField
                  variant="outlined"
                  value={period.text}
                  onChange={(event) => handleTextChange(index, event)}
                  sx={{
                    marginBottom: 1,
                    width: '120px',
                    textAlign: 'center',
                    '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: period.text ? 'transparent' : '#fff' } },
                    input: { textAlign: 'center', color: '#fff' },
                  }}
                  inputProps={{ style: { textAlign: 'center', color: '#B5C0D0', fontSize: '13px', textTransform: 'uppercase' } }}
                />
              </Box>
            );
          })}
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', paddingTop: '20px' }}>
            <Box sx={{ flex: 1, px: 2, display: 'flex', alignItems: 'center', marginTop: 4 }}>
              <IconButton onClick={deleteLastPeriod} sx={{ color: '#fff' }}>
                <RemoveIcon />
              </IconButton>
              <Slider
                value={periods.length > 0 ? periods.flatMap(period => period.range) : [0, 0]}
                onChange={handleSliderChange}
                valueLabelDisplay="auto"
                min={0}
                max={new Date(selectedYear, 11, 31).getDay() === 4 ? 53 : 52}
                sx={{
                  color: '#fff',
                  '& .MuiSlider-mark': { backgroundColor: '#1EB980', height: '4px', width: '4px', borderRadius: '50%' },
                  '& .MuiSlider-thumb': { color: '#fff' },
                  '& .MuiSlider-rail': { color: '#fff' },
                  '& .MuiSlider-track': { color: '#fff' },
                }}
              />
              <IconButton onClick={addPeriod} sx={{ color: '#fff' }}>
                <AddIcon />
              </IconButton>
            </Box>
          </Box>
          {periods.map((period, index) => {
            const startWeek = period.range[0];
            const endWeek = period.range[1];
            const midpoint = (startWeek + endWeek) / 2;
            const leftPosition = (midpoint / (new Date(selectedYear, 11, 31).getDay() === 4 ? 53 : 52)) * 100;

            return (
              <Typography
                key={period.id}
                variant="body2"
                sx={{
                  position: 'absolute',
                  left: `${leftPosition}%`,
                  transform: 'translateX(-50%)',
                  width: 'max-content',
                  textAlign: 'center',
                  top: 70,
                  color: '#B5C0D0',
                  whiteSpace: 'nowrap',
                  backgroundColor: 'none',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  marginTop: 2,
                }}
              >
                {`${new Date(selectedYear, 0, 1 + (period.range[0] - 1) * 7).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })} - ${new Date(selectedYear, 0, 1 + (period.range[1] - 1) * 7).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}`}
              </Typography>
            );
          })}
        </Box>

        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2 }}>
          <Button onClick={() => setShowCalendar(true)}>Select Dates</Button>
        </Box>

        <Dialog open={showCalendar} onClose={() => setShowCalendar(false)}>
          <DialogContent>
            <TextField
              type="date"
              onChange={handleDateSelection}
              InputLabelProps={{ shrink: true }}
              sx={{ backgroundColor: '#fff', borderRadius: 1, mr: 1 }}
            />
            <Box sx={{ mt: 2 }}>
              {selectedDates.map((date, index) => (
                <Box key={index}>{date}</Box>
              ))}
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowCalendar(false)} color="primary">Close</Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', mt: 2, position: 'relative' }}>
          {selectedDates.map((date, index) => (
            <Box
              key={index}
              sx={{
                position: 'absolute',
                left: `${calculateLeftPosition(date)}%`,
                transform: 'translateX(-50%)',
                width: 8,
                height: 8,
                borderRadius: '50%',
                backgroundColor: 'primary.main',
                margin: 0.5,
              }}
            />
          ))}
        </Box>

        <Box sx={{ mt: 2, display: 'grid', gridTemplateColumns: 'repeat(8, 1fr)', gap: 2 }}>
          {periods.map((period, periodIndex) => (
            <Box
              key={period.id}
              sx={{
                backgroundColor: '#1A2B3C',
                borderTopLeftRadius: 5,
                borderTopRightRadius: 5,
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 2,
                p: 0,
                mt: 2,
                flexGrow: 1,
                minWidth: '150px',
              }}
            >
              <Box sx={{ backgroundColor: '#1EB980', borderRadius: '10px 10px 0 0', p: 0, height: '60px', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant="h6" sx={{ color: '#1A2B3C', textTransform: 'uppercase', textAlign: 'center', fontSize: '15px' }}>
                  {period.text || `Period ${periodIndex + 1}`}
                </Typography>
                <Typography variant="body2" sx={{ color: '#1A2B3C', textAlign: 'center', fontSize: '10px' }}>
                  {`${new Date(selectedYear, 0, 1 + (period.range[0] - 1) * 7).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })} - ${new Date(selectedYear, 0, 1 + (period.range[1] - 1) * 7).toLocaleDateString(undefined, { month: 'short', day: 'numeric' })}`}
                </Typography>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
                {trainingComponents.map((component) => (
                  <Box key={component.name} sx={{ mb: 2, position: 'relative', width: '150px' }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#B5C0D0',
                        textAlign: 'center',
                        textTransform: 'uppercase',
                        fontSize: '9px',
                        position: 'absolute',
                        top: '-0px',
                        left: 0,
                        right: 0,
                      }}
                    >
                      {component.name}
                    </Typography>
                    <FormControl sx={{ minWidth: 150 }}>
                      <Select
                        value={Array.isArray(period.methods?.row1?.[component.name]) ? period.methods.row1[component.name] : []}
                        onChange={(e) => handleMethodChange(periodIndex, component.name, 'row1', e.target.value)}
                        displayEmpty
                        multiple
                        renderValue={(selected) => {
                          selected = Array.isArray(selected) ? selected.slice(0, 3) : [];
                          return (
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              {selected.map((value, index) => (
                                <Typography
                                  key={value}
                                  variant={index === 0 ? 'body1' : 'body2'}
                                  sx={index === 1 ? { fontSize: '0.8rem' } : index === 2 ? { fontSize: '0.6rem' } : {}}
                                >
                                  {value}
                                </Typography>
                              ))}
                            </Box>
                          );
                        }}
                        IconComponent={ArrowDropDownIcon}
                        sx={{
                          color: '#fff',
                          '.MuiOutlinedInput-notchedOutline': { borderColor: '#1EB980' },
                          '&.Mui-focused .MuiOutlinedInput-notchedOutline': { borderColor: '#fff' },
                          '&:hover .MuiOutlinedInput-notchedOutline': { borderColor: '#fff' },
                          '& .MuiSelect-select': {
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            textAlign: 'center',
                            paddingRight: '24px',
                          },
                          height: '80px',
                          fontSize: '0.8rem',
                          width: '150px',
                          textAlign: 'center',
                        }}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              '& .MuiMenuItem-root': {
                                textAlign: 'center',
                                color: '#fff',
                              },
                              '& .Mui-selected': {
                                backgroundColor: 'grey',
                                '&:hover': {
                                  backgroundColor: 'darkred',
                                },
                              },
                            },
                          },
                        }}
                      >
                        {getUniqueTargets(component.name).map((target) => (
                          <MenuItem key={target} value={target}>
                            <Checkbox checked={selectedDates.includes(target)} />
                            <ListItemText primary={target} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
        <Button variant="contained" onClick={addEvent} sx={{ backgroundColor: 'rgba(255, 255, 255, 0.2)', color: '#fff', mt: 4 }}>
          Add Event
        </Button>
        <TableContainer component={Paper} sx={{ mt: 2, backgroundColor: 'transparent' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ color: '#fff' }}>Event</TableCell>
                <TableCell sx={{ color: '#fff' }}>Start Date</TableCell>
                <TableCell sx={{ color: '#fff' }}>End Date</TableCell>
                <TableCell sx={{ color: '#fff' }}>Location</TableCell>
                <TableCell sx={{ color: '#fff' }}>Notes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {events.map((event, index) => (
                <TableRow key={event.id}>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={event.name}
                      onChange={(e) => handleEventChange(index, 'name', e.target.value)}
                      sx={{ width: '100%', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' } }, input: { color: '#fff' } }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={event.startDate}
                      onChange={(e) => handleEventChange(index, 'startDate', e.target.value)}
                      sx={{ width: '100%', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' } }, input: { color: '#fff' } }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={event.endDate}
                      onChange={(e) => handleEventChange(index, 'endDate', e.target.value)}
                      sx={{ width: '100%', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' } }, input: { color: '#fff' } }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={event.location}
                      onChange={(e) => handleEventChange(index, 'location', e.target.value)}
                      sx={{ width: '100%', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' } }, input: { color: '#fff' } }}
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      variant="outlined"
                      value={event.notes}
                      onChange={(e) => handleEventChange(index, 'notes', e.target.value)}
                      sx={{ width: '100%', '& .MuiOutlinedInput-root': { '& fieldset': { borderColor: '#fff' } }, input: { color: '#fff' } }}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </ThemeProvider>
  );
};

export default Year;
