import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';

const managers = [

  {
    image: '/Janez.jpg',
    name: 'Janez Lovenjak',
    position: 'Founder',
  },
  {
    image: '/Bojan.jpg',
    name: 'Bojan Krajnc',
    position: 'CEO',
  },

  {
    image: '/Mitja.jpg',
    name: 'Mitja Blagajac',
    position: 'CMO',
  },
  
  {
    image: '/Luka.jpg',
    name: 'Luka Lovenjak',
    position: 'CQO',
  },

  
  
];

export default function AboutUs() {
  return (
    <Container
      id="about-us"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: { xs: 3, sm: 6 },
      }}
    >
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
        }}
      >
        <Typography component="h2" variant="h4" color="text.primary">
          Our Vision
        </Typography>
        <Typography variant="body1" color="text.secondary">
        "FitCode bridges the gap between IT services and elite sports. We strive to revolutionize the way athletes move, train, and perform by providing cutting-edge tools to ensure they achieve peak performance."
        </Typography>
      </Box>
      <Box
        sx={{
          width: { sm: '100%', md: '60%' },
          textAlign: { sm: 'left', md: 'center' },
          mt: 4,
        }}
      >
        <Typography component="h3" variant="h5" color="text.primary">
          Key Members
        </Typography>
      </Box>
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {managers.map((manager, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
            <Box sx={{ textAlign: 'center' }}>
              <Avatar
                alt={manager.name}
                src={manager.image}
                sx={{ width: 120, height: 120, marginBottom: 2 }}
              />
              <Typography variant="h6" color="text.primary">
                {manager.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {manager.position}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
