import React, { useState } from 'react';
import {
  Container, TextField, Button, Box, Avatar, MenuItem, FormControl, InputLabel, Select
} from '@mui/material';
import NavbarAthlete from './NavbarAthlete';
import PersonIcon from '@mui/icons-material/Person';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AthleteProfile = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [dob, setDob] = useState(null);
  const [sport, setSport] = useState('');
  const [gender, setGender] = useState('');
  const [sportLevel, setSportLevel] = useState('');
  const [photo, setPhoto] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState('');

  const handleSave = () => {
    // Save profile information logic
    alert('Profile saved successfully!');
  };

  return (
    <Box sx={{ height: '100vh', backgroundColor: '#1A2B3C', overflow: 'hidden' }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100vh - 50px)', // Subtract the height of the BottomNavigation
        }}
      >
        <Avatar
          alt="Profile Photo"
          src={photo}
          sx={{ bgcolor: '#1EB980', width: 80, height: 80, mt: 2, mb: 2 }}
        >
          {!photo && <PersonIcon sx={{ fontSize: 50, color: '#ffffff' }} />}
        </Avatar>
        <Button
          variant="contained"
          component="label"
          sx={{ mb: 2, backgroundColor: '#1EB980', '&:hover': { backgroundColor: '#17A472' } }}
        >
          Upload Photo
          <input type="file" hidden onChange={(e) => setPhoto(URL.createObjectURL(e.target.files[0]))} />
        </Button>
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <TextField
            label="First Name"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            fullWidth
            sx={{ mb: 2, backgroundColor: '#303E4A', borderRadius: '10px' }}
          />
          <TextField
            label="Last Name"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            fullWidth
            sx={{ mb: 2, backgroundColor: '#303E4A', borderRadius: '10px' }}
          />
        </Box>
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <FormControl fullWidth sx={{ mb: 2, backgroundColor: '#303E4A', borderRadius: '10px' }}>
            <InputLabel id="gender-select-label">Gender</InputLabel>
            <Select
              labelId="gender-select-label"
              id="gender-select"
              value={gender}
              label="Gender"
              onChange={(e) => setGender(e.target.value)}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
            </Select>
          </FormControl>
          <DatePicker
            selected={dob}
            onChange={(date) => setDob(date)}
            customInput={
              <TextField
                label="Date of Birth"
                fullWidth
                sx={{ mb: 2, backgroundColor: '#303E4A', borderRadius: '10px' }}
              />
            }
            wrapperClassName="datePicker"
            dateFormat="MM/dd/yyyy"
          />
        </Box>
        <TextField
          label="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          sx={{ mb: 2, backgroundColor: '#303E4A', borderRadius: '10px' }}
        />
        <TextField
          label="Phone Number"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          fullWidth
          sx={{ mb: 2, backgroundColor: '#303E4A', borderRadius: '10px' }}
        />
        <Box sx={{ display: 'flex', gap: 2, width: '100%' }}>
          <FormControl fullWidth sx={{ mb: 2, backgroundColor: '#303E4A', borderRadius: '10px' }}>
            <InputLabel id="sport-select-label">Sport</InputLabel>
            <Select
              labelId="sport-select-label"
              id="sport-select"
              value={sport}
              label="Sport"
              onChange={(e) => setSport(e.target.value)}
            >
              <MenuItem value="football">Football</MenuItem>
              <MenuItem value="basketball">Basketball</MenuItem>
              <MenuItem value="tennis">Tennis</MenuItem>
              <MenuItem value="swimming">Swimming</MenuItem>
              <MenuItem value="athletics">Athletics</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2, backgroundColor: '#303E4A', borderRadius: '10px' }}>
            <InputLabel id="sport-level-select-label">Sport Level</InputLabel>
            <Select
              labelId="sport-level-select-label"
              id="sport-level-select"
              value={sportLevel}
              label="Sport Level"
              onChange={(e) => setSportLevel(e.target.value)}
            >
              <MenuItem value="professional">Professional</MenuItem>
              <MenuItem value="semi-professional">Semi-Professional</MenuItem>
              <MenuItem value="recreational">Recreational</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <TextField
          label="Selected Group"
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          fullWidth
          sx={{ mb: 2, backgroundColor: '#303E4A', borderRadius: '10px' }}
        />
        <Button variant="contained" onClick={handleSave} sx={{ backgroundColor: '#1EB980', '&:hover': { backgroundColor: '#17A472' } }}>
          Save Profile
        </Button>
      </Container>
      <NavbarAthlete />
    </Box>
  );
};

export default AthleteProfile;