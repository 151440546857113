import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  IconButton,
  Tooltip,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Snackbar,
  Alert,
  Fab, Collapse,
  
  
} from '@mui/material';
import * as XLSX from 'xlsx';
import { Save as SaveIcon,Visibility, VisibilityOff, Delete as DeleteIcon, FileCopy as CopyIcon, Add as AddIcon } from '@mui/icons-material';
import { db } from './firebase-config';
import { collection, addDoc, setDoc, updateDoc, doc, deleteDoc, getDocs } from 'firebase/firestore';
import AddDrill from './components/AddDrill';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import getLPTheme from './getLPTheme';

const constantsData = [
  { RM: 'RM-1', percentage: 99 },
  { RM: 'RM-2', percentage: 94 },
  { RM: 'RM-3', percentage: 91 },
  { RM: 'RM-4', percentage: 88 },
  { RM: 'RM-5', percentage: 86 },
  { RM: 'RM-6', percentage: 83 },
  { RM: 'RM-7', percentage: 81 },
  { RM: 'RM-8', percentage: 79 },
  { RM: 'RM-9', percentage: 77 },
  { RM: 'RM-10', percentage: 75 },
  { RM: 'RM-11', percentage: 73 },
  { RM: 'RM-12', percentage: 71 },
  { RM: 'RM-13', percentage: 70 },
  { RM: 'RM-14', percentage: 68 },
  { RM: 'RM-15', percentage: 67 },
];

const exerciseComponents = [
  { name: 'Strength', icon: 'strength' },
  { name: 'Speed', icon: 'speed' },
  { name: 'Endurance', icon: 'endurance' },
  { name: 'ROM', icon: 'rom' },
  { name: 'Coordination', icon: 'coordination' },
  { name: 'Warming Up', icon: 'warming up' },
  { name: 'Cool Down', icon: 'cool down' },
  { name: 'football', icon: 'football' },
];


const ExerciseDetailIcon = ({ name, icon, selected, onSelect }) => (
  <Tooltip title={name}>
    <IconButton
      onClick={() => onSelect(name)}
      sx={{
        borderRadius: '50%',
        border: `2px solid ${selected ? '#1EB980' : 'grey'}`,
        backgroundColor: selected ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
        '&:hover': { backgroundColor: 'lightgrey' },
        margin: '8px',
      }}
    >
      <img src={`/icons/${icon}.svg`} alt={name} style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
    </IconButton>
  </Tooltip>
);

const Methodology = ({ uploadedMethods }) => {
  const [mode, setMode] = React.useState('dark');
  const [showCustomTheme, setShowCustomTheme] = React.useState(false);
  const [itemType, setItemType] = React.useState('method');
  const [trainingComponent, setTrainingComponent] = React.useState('Strength');
  const [tags, setTags] = React.useState([]);
  const [selectedTag, setSelectedTag] = React.useState('');
  const [methods, setMethods] = React.useState([]);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [currentMethod, setCurrentMethod] = React.useState(null);
  const [isEditing, setIsEditing] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
  const [fileUploadDialogOpen, setFileUploadDialogOpen] = React.useState(false);
  const LPtheme = createTheme(getLPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });
  const [editingTagIndex, setEditingTagIndex] = useState(null);
  const [tagRows, setTagRows] = React.useState({});
  const [expandedRows, setExpandedRows] = useState({});


  React.useEffect(() => {
    if (uploadedMethods && uploadedMethods.length > 0) {
      setMethods(uploadedMethods);
    }
  }, [uploadedMethods]);

  const handleExpandRow = (tag) => {
    setExpandedRows((prev) => ({
      ...prev,
      [tag]: !prev[tag]
    }));
  };

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  const handleComponentChange = (event, newItemType) => {
    if (newItemType !== null) {
      setItemType(newItemType);
      setTrainingComponent(null); // Reset training component selection when item type changes
      setTags([]); // Reset tags when item type changes
      setSelectedTag(''); // Reset selected tag when item type changes
    }
  };

  const handleTrainingComponentChange = (component) => {
    if (trainingComponent !== component) {
      setTrainingComponent(component);
      setTags([]);
      setTagRows({});
      setSelectedTag('');
      setEditingTagIndex(null);
    }
  };

  
  


  useEffect(() => {
    const fetchTagsAndRows = async () => {
      try {
        if (itemType === 'protocols') {
          const fetchedTags = [];
          const fetchedRows = {};

          if (trainingComponent) {
            // Fetch tags for the selected training component
            const componentRef = doc(db, 'trainingComponents', trainingComponent);
            const tagsSnapshot = await getDocs(collection(componentRef, 'tags'));

            tagsSnapshot.forEach(tagDoc => {
              const tagData = tagDoc.data();
              fetchedTags.push(tagData.name);
              fetchedRows[tagData.name] = tagData.rows || [];
            });
          } else {
            // Fetch all tags across all training components
            const componentsSnapshot = await getDocs(collection(db, 'trainingComponents'));
            for (const componentDoc of componentsSnapshot.docs) {
              const componentRef = doc(db, 'trainingComponents', componentDoc.id);
              const tagsSnapshot = await getDocs(collection(componentRef, 'tags'));

              tagsSnapshot.forEach(tagDoc => {
                const tagData = tagDoc.data();
                fetchedTags.push(tagData.name);
                fetchedRows[tagData.name] = tagData.rows || [];
              });
            }
          }

          setTags(fetchedTags);
          setTagRows(fetchedRows);

          if (fetchedTags.length > 0) {
            setSelectedTag(fetchedTags[0]);
          } else {
            setSelectedTag('');
          }
        } else {
          // Reset tags and rows when itemType is not protocols
          setTags([]);
          setTagRows({});
          setSelectedTag('');
        }
      } catch (error) {
        console.error('Error fetching tags and rows:', error);
      }
    };

    fetchTagsAndRows();
  }, [trainingComponent, itemType]);
  
  
  
  // Function to save tags and rows to Firebase
  const saveTagsAndRowsToFirebase = async () => {
    try {
      if (trainingComponent) {
        const componentRef = doc(db, 'trainingComponents', trainingComponent);
    
        // Save all rows and their exercises for the selected tag
        await Promise.all(tags.map(async (tag) => {
          const tagDocRef = doc(collection(componentRef, 'tags'), tag);
          await setDoc(tagDocRef, { name: tag, rows: tagRows[tag] || [] });
        }));
    
        setSnackbarMessage('Data saved successfully!');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error saving tags and rows:', error);
      setSnackbarMessage('Error saving data. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  

  const handleAddTag = () => {
    const newTag = 'text'; // Predefined name for new tags
    const updatedTags = [...tags, newTag];
    setTags(updatedTags);
    
    // Automatically select the new tag if it's the only one
    if (updatedTags.length === 1) {
      setSelectedTag(newTag);
    } else {
      setSelectedTag(updatedTags[0]); // Ensure the first tag is selected
    }
    setEditingTagIndex(updatedTags.length - 1); // Focus on the new tag
  };
  
  const handleTagChange = (index, value) => {
    const newTags = [...tags];
    newTags[index] = value;
    setTags(newTags);
  
    if (newTags.length > 0) {
      setSelectedTag(newTags[0]);
    }
  };
  
  const handleDeleteTag = (index) => {
    const tagToDelete = tags[index];
    const newTags = tags.filter((_, i) => i !== index);
    const newTagRows = { ...tagRows };
    delete newTagRows[tagToDelete];
  
    setTags(newTags); // Update local state only
    setTagRows(newTagRows); // Update local state only
    setSelectedTag('');
  };
  

  
  const handleTagSelect = (tag) => {
    setSelectedTag(tag);
  };
  const handleAddTableRow = () => {
    setTagRows(prev => {
      const newRows = [...(prev[selectedTag] || []), ''];
      return { ...prev, [selectedTag]: newRows };
    });
    saveTagsAndRowsToFirebase();
  };
  
  const handleRowChange = (tag, rowIndex, value) => {
    setTagRows(prev => {
      const newRows = prev[tag].map((row, i) => (i === rowIndex ? value : row));
      return { ...prev, [tag]: newRows };
    });
  };

  const filteredMethods = trainingComponent
    ? methods.filter((method) => method['Component Name'] === trainingComponent)
    : methods;

  const handleDelete = async (index) => {
    const methodToDelete = methods[index];
    if (methodToDelete.id) {
      await deleteDoc(doc(db, 'methods', methodToDelete.id));
    }
    const updatedMethods = [...methods];
    updatedMethods.splice(index, 1);
    setMethods(updatedMethods);
  };

  const handleEdit = (method, index) => {
    setCurrentMethod({ ...method, index });
    setIsEditing(true);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setCurrentMethod(null);
    setIsEditing(false);
  };

  const handleDialogSave = async () => {
    const updatedMethods = [...methods];
    try {
      if (isEditing) {
        const methodToUpdate = { ...currentMethod };
        if (methodToUpdate.id) {
          await updateDoc(doc(db, 'methods', methodToUpdate.id), methodToUpdate);
        } else {
          const docRef = await addDoc(collection(db, 'methods'), methodToUpdate);
          methodToUpdate.id = docRef.id;
        }
        updatedMethods[currentMethod.index] = methodToUpdate;
      } else {
        const newMethod = { ...currentMethod };
        const docRef = await addDoc(collection(db, 'methods'), newMethod);
        newMethod.id = docRef.id;
        updatedMethods.push(newMethod);
      }
      setMethods(updatedMethods);
      handleDialogClose();
      setSnackbarMessage('Method saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving method:', error);
      setSnackbarMessage('Error saving method. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCurrentMethod((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const saveData = async () => {
    try {
      await Promise.all(
        methods.map(async (method) => {
          if (method.id) {
            await updateDoc(doc(db, 'methods', method.id), method);
          } else {
            const docRef = await addDoc(collection(db, 'methods'), method);
            method.id = docRef.id;
          }
        })
      );
      setSnackbarMessage('Data saved successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error saving data:', error);
      setSnackbarMessage('Error saving data. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const deleteData = async () => {
    try {
      await Promise.all(methods.map(async (method) => {
        if (method.id) {
          await deleteDoc(doc(db, 'methods', method.id));
        }
      }));
      setMethods([]);
      setSnackbarMessage('Data deleted successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error deleting data:', error);
      setSnackbarMessage('Error deleting data. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const copyData = async () => {
    try {
      const copiedMethods = methods.map((method) => ({
        ...method,
        id: undefined, // Clear id for new document creation
      }));
      await Promise.all(copiedMethods.map(async (method) => {
        const docRef = await addDoc(collection(db, 'methods'), method);
        method.id = docRef.id;
      }));
      setMethods(copiedMethods);
      setSnackbarMessage('Data copied successfully!');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error copying data:', error);
      setSnackbarMessage('Error copying data. Please try again.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  React.useEffect(() => {
    const fetchMethods = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'methods'));
        const fetchedMethods = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        setMethods(fetchedMethods);
      } catch (error) {
        console.error('Error fetching methods:', error);
      }
    };

    fetchMethods();
  }, []);

  const handleFileUploadOpen = () => {
    setFileUploadDialogOpen(true);
  };

  const handleFileUploadClose = () => {
    setFileUploadDialogOpen(false);
  };

  

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const binaryStr = event.target.result;
      const workbook = XLSX.read(binaryStr, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);
      setMethods(sheet); // Update the state with the uploaded methods
      setFileUploadDialogOpen(false); // Close the dialog after upload
    };

    reader.readAsBinaryString(file);
  };

  return (
  <ThemeProvider theme={showCustomTheme ? LPtheme : defaultTheme}>
    <CssBaseline />
    <Navbar mode={mode} toggleColorMode={toggleColorMode} />
    <Box
      sx={{
        width: 'calc(100%)', // Full page width minus 40px (20px padding on each side)
        height: '130px', // Fixed height of 200px
        margin: '0 0px', // Center the box horizontally with 20px margin on each side
        backgroundColor: '#303E4A', // Example background color
       
      }}
    >
    <Box
      sx={{
        width: 'calc(100% - 100px)', // Full page width minus 40px (20px padding on each side)
        height: '130px', // Fixed height of 200px
        margin: '0 50px', // Center the box horizontally with 20px margin on each side
        backgroundColor: '#1A2B3C', // Example background color
        borderRadius: '30px',
      }}
    >
      {/* Your content goes here */}
       {/* Toggle between Methods, Protocols, and Constants */}
       <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2, mt: 6,  }}>
  <ToggleButtonGroup
    value={itemType}
    exclusive
    onChange={handleComponentChange}
    sx={{
      display: 'flex',
      flexWrap: 'nowrap',
      borderColor: '#303E4A',
      maxWidth: '30%',
      borderWidth: 1,
      borderRadius: 1,
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      
      
      
     
    }}
  >
    <ToggleButton
      value="method"
      sx={{
        flex: '1 1 auto',
        minWidth: { xs: '50px', sm: '70px', md: '100px' },
        color: '#fff',
        backgroundColor: '#303E4A',
        borderColor: '#303E4A',
        borderWidth: 1,
        borderRadius: { xs: '0', sm: '0 0 30px 30px' },
        '&.Mui-selected': {
          backgroundColor: '#1EB980',
          color: '#fff',
        },
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          color: '#fff',
        },
        textTransform: 'none',
        p: { xs: '4px', sm: '6px', md: '8px' },
        fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' },
      }}
    >
      METHOD
    </ToggleButton>

    <ToggleButton
      value="protocols"
      sx={{
        flex: '1 1 auto',
        minWidth: { xs: '50px', sm: '70px', md: '100px' },
        color: '#fff',
        backgroundColor: '#303E4A',
        borderColor: '#303E4A',
        borderWidth: 1,
        borderRadius: { xs: '0', sm: '0 0 30px 30px' },
        '&.Mui-selected': {
          backgroundColor: '#1EB980',
          color: '#fff',
        },
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          color: '#fff',
        },
        textTransform: 'none',
        p: { xs: '4px', sm: '6px', md: '8px' },
        fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' },
      }}
    >
      PROTOCOLS

    </ToggleButton>
    <ToggleButton
      value="constants"
      sx={{
        flex: '1 1 auto',
        minWidth: { xs: '50px', sm: '70px', md: '100px' },
        color: '#fff',
        backgroundColor: '#303E4A',
        borderColor: '#303E4A',
        borderWidth: 1,
        borderRadius: { xs: '0', sm: '0 0 30px 30px' },
        '&.Mui-selected': {
          backgroundColor: '#1EB980',
          color: '#fff',
        },
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          color: '#fff',
        },
        textTransform: 'none',
        p: { xs: '4px', sm: '6px', md: '8px' },
        fontSize: { xs: '0.7rem', sm: '0.8rem', md: '1rem' },
      }}
    >
      CONSTANTS
    </ToggleButton>
  </ToggleButtonGroup>
</Box>


<Box sx={{ display: 'flex', justifyContent: 'center', gap: 2 }}>
      {exerciseComponents.map((comp) => (
        <ExerciseDetailIcon
          key={comp.name}
          name={comp.name}
          icon={comp.icon}
          selected={trainingComponent === comp.name}
          onSelect={handleTrainingComponentChange}
        />
      ))}
    </Box>
    
    </Box>
    </Box>

    <Box sx={{ bgcolor: '#303E4A', pt: 2, px: '0px', minHeight: '100vh', color: 'text.primary' }}>
      
  
 


      {/* Methods Section */}
      {itemType === 'method' && (
        <>
         

          

         <Box
  sx={{
    backgroundColor: 'red', // Your desired background color
    width: 'calc(100% - 100px)', // Full width minus 100px (50px on each side)
    margin: '0 auto', // Center the box horizontally
    overflowX: 'auto',
    borderRadius: '20px', // Rounded corners
  }}
>
  <Table
    sx={{
      width: '100%',
      backgroundColor: '#1A2B3C', // Set the background color for the table
      borderCollapse: 'separate',
      borderSpacing: '0', // Ensure proper spacing and rounded corners
    }}
  >
    <TableHead>
      <TableRow
        sx={{
          backgroundColor: '#1A2B3C',
          '& th': {
            backgroundColor: '#1A2B3C', // Set background for the header cells
            color: '#1EB980',
            fontSize: '0.8rem',
            fontWeight: 'bold',
            borderTopLeftRadius: '20px', // Rounded top left corner for the first cell
            borderTopRightRadius: '20px', // Rounded top right corner for the last cell
            textAlign: 'center', // Center text in the header cells
            paddingX: '10px', // Horizontal padding for cells
          },
        }}
      >
        <TableCell sx={{ borderRight: '10px solid #303E4A', paddingRight: '5px' }}>COMPONENT NAME</TableCell>
        <TableCell sx={{ borderRight: '10px solid #303E4A', paddingRight: '5px' }}>METHOD</TableCell>
        <TableCell sx={{ borderRight: '10px solid #303E4A', paddingRight: '5px' }}>ABILITY</TableCell>
        <TableCell sx={{ borderRight: '10px solid #303E4A', paddingRight: '5px' }}>REPETITION</TableCell>
        <TableCell sx={{ borderRight: '10px solid #303E4A', paddingRight: '5px' }}>INTENSITY</TableCell>
        <TableCell sx={{ borderRight: '10px solid #303E4A', paddingRight: '5px' }}>SET</TableCell>
        <TableCell sx={{ borderRight: '10px solid #303E4A', paddingRight: '5px' }}>TEMPO</TableCell>
        <TableCell sx={{ borderRight: '10px solid #303E4A', paddingRight: '5px' }}>RECOVERY</TableCell>
        <TableCell sx={{ borderRight: '10px solid #303E4A', paddingRight: '5px' }}>TARGET</TableCell>
        <TableCell sx={{ paddingRight: '5px' }}>ACTIONS</TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredMethods.map((method, index) => {
        const isFirstRow = index === 0;
        const isLastRow = index === filteredMethods.length - 1;

        return (
          <React.Fragment key={index}>
            {isFirstRow && (
              <TableRow>
                <TableCell colSpan={10} sx={{ padding: 0 }}>
                  <Box sx={{ height: '5px', backgroundColor: '#303E4A' }} /> {/* Space between header and first row */}
                </TableCell>
              </TableRow>
            )}
            <TableRow
              onDoubleClick={() => handleEdit(index)}
              sx={{
                backgroundColor: '#1A2B3C',
                cursor: 'pointer',
                '&:first-of-type': {
                  borderTopLeftRadius: isFirstRow ? '20px' : '0',
                  borderTopRightRadius: isFirstRow ? '20px' : '0',
                },
                '&:last-of-type': {
                  borderBottomLeftRadius: isLastRow ? '20px' : '0',
                  borderBottomRightRadius: isLastRow ? '20px' : '0',
                },
              }}
            >
              <TableCell sx={{ color: '#fff', fontSize: '0.7rem', textAlign: 'center', borderRight: '2px solid #303E4A' }}>
                {method['Component Name']}
              </TableCell>
              <TableCell sx={{ color: '#fff', fontSize: '0.7rem', textAlign: 'center', borderRight: '2px solid #303E4A' }}>
                {method.Method}
              </TableCell>
              <TableCell sx={{ color: '#fff', fontSize: '0.7rem', textAlign: 'center', borderRight: '2px solid #303E4A' }}>
                {method.Ability}
              </TableCell>
              <TableCell sx={{ color: '#fff', fontSize: '0.7rem', textAlign: 'center', borderRight: '2px solid #303E4A' }}>
                {method.Repetition}
              </TableCell>
              <TableCell sx={{ color: '#fff', fontSize: '0.7rem', textAlign: 'center', borderRight: '2px solid #303E4A' }}>
                {method.Intensity}
              </TableCell>
              <TableCell sx={{ color: '#fff', fontSize: '0.7rem', textAlign: 'center', borderRight: '2px solid #303E4A' }}>
                {method.Set}
              </TableCell>
              <TableCell sx={{ color: '#fff', fontSize: '0.7rem', textAlign: 'center', borderRight: '2px solid #303E4A' }}>
                {method.Tempo}
              </TableCell>
              <TableCell sx={{ color: '#fff', fontSize: '0.7rem', textAlign: 'center', borderRight: '2px solid #303E4A' }}>
                {method.Recovery}
              </TableCell>
              <TableCell sx={{ color: '#fff', fontSize: '0.7rem', textAlign: 'center', borderRight: '2px solid #303E4A' }}>
                {method.Target}
              </TableCell>
              <TableCell sx={{ color: '#fff', fontSize: '0.7rem', textAlign: 'center' }}>
                <Button onClick={() => handleEdit(method, index)} sx={{ fontSize: '0.6rem' }} color="primary">Edit</Button>
                <Button onClick={() => handleDelete(index)} sx={{ fontSize: '0.6rem' }} color="secondary">Delete</Button>
              </TableCell>
            </TableRow>
            {!isLastRow && (
              <TableRow>
                <TableCell colSpan={10} sx={{ padding: 0 }}>
                  <Box sx={{ height: '1px', backgroundColor: '#303E4A' }} /> {/* Line between rows */}
                </TableCell>
              </TableRow>
            )}
          </React.Fragment>
        );
      })}
    </TableBody>
  </Table>
</Box>






          {/* Floating Action Button to Add New Method */}
          <Fab
            color="primary"
            aria-label="add"
            sx={{
              position: 'fixed',
              bottom: 16,
              right: 16,
            }}
            onClick={() => {
              setCurrentMethod({});
              setIsEditing(false);
              setOpenDialog(true);
            }}
          >
            <AddIcon />
          </Fab>
        </>
      )}


{itemType === 'protocols' && (
<Box
      sx={{
        width: 'calc(100%-50px)', // Full page width minus 40px (20px padding on each side)
        height: '130px', // Fixed height of 200px
        margin: '0 50px', // Center the box horizontally with 20px margin on each side
        backgroundColor: '#1A2B3C',
        borderRadius:'30px' // Example background color
       
      }}
    >    
<Box
  sx={{
    width: '100%', // Full page width
    margin: '0 auto', // Center the box horizontally
    padding: '10px 0px', // Optional padding for some space around the content
    backgroundColor: '#1A2B3C', // Background color of the red box
    display: 'flex',
    flexDirection: 'column', // Stack items vertically
    borderRadius: '30px',
    position: 'relative', // Make the box relative for absolute positioning of the button
  }}
>
  {/* Add Tag Icon Button */}
  <IconButton
    color="primary"
    onClick={handleAddTag}
    sx={{
      position: 'absolute', // Position the button absolutely within the red box
      top: '10px', // Position it 10px from the top of the red box
      right: '20px', // Position it 20px from the right edge of the red box
      backgroundColor: '#1A2B3C', // Optional: background color for the button
      '&:hover': {
        backgroundColor: '#3f4d5b', // Optional: darker color on hover
      },
    }}
  >
    <AddIcon sx={{ fontSize: 24 }} /> {/* Adjust icon size as needed */}
  </IconButton>

  {/* Box Containing Tags */}
  <Box
    sx={{
      width: '95%', // Full width minus a margin for centering
      margin: '0 auto', // Center the box horizontally
      padding: '10px 0px', // Optional padding for some space around the content
      display: 'flex',
      flexWrap: 'wrap', // Allow tags to wrap to the next line if necessary
      gap: 2,
      justifyContent: 'center', // Center tags horizontally within the box
      backgroundColor: '#1A2B3C', // Background color of the tag box
      borderRadius: '30px',
    }}
  >
    {tags.map((tag, index) => (
      <Box key={index} sx={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
        {editingTagIndex === index ? (
          <TextField
            variant="outlined"
            size="small"
            value={tag}
            onChange={(e) => handleTagChange(index, e.target.value)}
            onBlur={() => setEditingTagIndex(null)}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                setEditingTagIndex(null);
              }
            }}
            autoFocus
            sx={{ 
              marginRight: 1,
              width: '120px',
              fontSize: '14px',
            }}
          />
        ) : (
          <Button
            variant="contained"
            color={selectedTag === tag ? "error" : "primary"}
            onClick={() => handleTagSelect(tag)}
            onDoubleClick={() => setEditingTagIndex(index)}
            sx={{
              marginRight: -1,
              width: '120px',
              fontSize: '14px',
              color: '#fff',
              textTransform: 'none',
              border: selectedTag === tag ? '2px solid #1EB980' : 'none',
              backgroundColor: selectedTag === tag ? '#1EB980' : '#3f4d5b',
              '&:hover': {
                backgroundColor: selectedTag === tag ? '#22D3A3' : '#556675', // Adjusted color for 20% brightness
              },
            }}
          >
            {tag}
          </Button>
        )}
      </Box>
    ))}
  </Box>

  {/* Box for additional content below tags */}
  

  <Box
            sx={{
            width: '100%', // Ensures each row takes up the full width of the container
            display: 'flex',
            flexDirection: 'column', // Stack row content and expandable content vertically
            padding: '3px',
            backgroundColor: '#303E4A'
          }}
        >
        </Box>


 {/* Render Rows for Selected Tag */}
{selectedTag && (
  <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 0 }}>
    {tagRows[selectedTag]?.map((row, rowIndex) => {
      const isFirstRow = rowIndex === 0;
      const isLastRow = rowIndex === tagRows[selectedTag].length - 1;

      return (
        <React.Fragment key={rowIndex}>
          {/* Row Box */}
          <Box
            sx={{
              width: '100%', // Ensures each row takes up the full width of the container
              display: 'flex',
              flexDirection: 'column', // Stack row content and expandable content vertically
              padding: '10px',
              backgroundColor: isFirstRow ? '#1A2B3C' : isLastRow ? '#1A2B3C' : '#1A2B3C',
              borderRadius: isFirstRow
                ? '10px 10px 0 0'
                : isLastRow
                ? '0 0 10px 10px'
                : '0',
              boxShadow: '0px 0px 0px rgba(0, 0, 0, 0.2)',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <TextField
  variant="outlined"
  size="small"
  value={row}
  onChange={(e) => handleRowChange(selectedTag, rowIndex, e.target.value)}
  sx={{
    marginRight: 1,
    width: '100%',
    fontSize: '12px',
    padding: '0px',
    textTransform: 'uppercase', // Ensures the text is uppercase
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none', // Removes the border
      },
      '& input': {
        color: '#1EB980', // Sets the text color
        textTransform: 'uppercase', // Ensures the text is uppercase
      },
    },
  }}
  InputProps={{
    style: {
      height: '30px',
      padding: '0px',
      display: 'flex',
      alignItems: 'center',
    },
  }}
/>



              <IconButton
                onClick={() => handleExpandRow(rowIndex)}
                sx={{ color: '#fff', padding: '5px', display: 'flex', alignItems: 'center' }}
              >
                {expandedRows[rowIndex] ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </Box>

            {/* Render AddDrill inside expanded row */}
            <Collapse in={expandedRows[rowIndex]} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  padding: '8px',
                  width: '100%',
                  backgroundColor: 'transparent',
                  boxSizing: 'border-box',
                }}
              >
                <AddDrill
                  trainingComponent={trainingComponent}
                  selectedTag={selectedTag}
                  selectedRow={rowIndex}
                  onAddExercise={(newExercise) => {
                    setTagRows((prev) => {
                      const updatedTagRows = { ...prev };
                      if (!updatedTagRows[selectedTag]) {
                        updatedTagRows[selectedTag] = [];
                      }

                      updatedTagRows[selectedTag] = updatedTagRows[selectedTag].map((rowContent, i) => {
                        if (i === rowIndex) {
                          const exercises = rowContent.exercises ? [...rowContent.exercises, newExercise] : [newExercise];
                          return { ...rowContent, exercises };
                        }
                        return rowContent;
                      });

                      return updatedTagRows;
                    });

                    saveTagsAndRowsToFirebase();
                  }}
                  onSave={saveTagsAndRowsToFirebase}
                />
              </Box>
            </Collapse>
          </Box>

          {/* Box between rows */}
          {rowIndex < tagRows[selectedTag].length - 1 && (
            <Box
              sx={{
                width: '100%', // Ensures each row takes up the full width of the container
                display: 'flex',
                flexDirection: 'column', // Stack row content and expandable content vertically
                padding: '1px',
                backgroundColor: '#303E4A',
              }}
            />
          )}
        </React.Fragment>
      );
    })}

<Box
            sx={{
            width: '100%', // Ensures each row takes up the full width of the container
            display: 'flex',
            flexDirection: 'column', // Stack row content and expandable content vertically
            padding: '3px',
            backgroundColor: '#303E4A'
          }}
        >
        </Box>

   
  </Box>
)}





</Box>

 {/* Add Protocol Button */}
 <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 2 }}>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleAddTableRow}
        startIcon={<AddIcon />}
        sx={{
          width: '120px',
          fontSize: '14px',
        }}
      >
        Protocol
      </Button>
    </Box>

</Box>
)}

{/* Floating Save and Delete Buttons */}
<Box
  sx={{
    position: 'fixed',
    bottom: 16,
    right: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }}
>
  <Fab
    aria-label="save"
    onClick={saveTagsAndRowsToFirebase}
    sx={{ 
      mb: 2, 
      bgcolor: '#37EFBA',  // Custom color for save button
      '&:hover': {
        bgcolor: '#2BD29F',  // Slightly darker color on hover
      },
      transform: 'scale(0.75)',  // Reduce size to half
    }}
  >
    <SaveIcon />
  </Fab>
  <Fab
    aria-label="delete"
    onClick={() => handleDeleteTag(tags.indexOf(selectedTag))}
    disabled={!selectedTag}
    sx={{ 
      bgcolor: '#FF6859',  // Custom color for delete button
      '&:hover': {
        bgcolor: '#E0584E',  // Slightly darker color on hover
      },
      transform: 'scale(0.75)',  // Reduce size to half
    }}
  >
    <DeleteIcon />
  </Fab>
</Box>




      {/* Constants Section */}
      {itemType === 'constants' && (
        <Paper sx={{ mt: 3 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>RM</TableCell>
                <TableCell>Intensity %1RM</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {constantsData.map((constant, index) => (
                <TableRow key={index}>
                  <TableCell>{constant.RM}</TableCell>
                  <TableCell>{constant.percentage}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Paper>
      )}
    </Box>
      <Footer />
      <ToggleCustomTheme showCustomTheme={showCustomTheme} toggleCustomTheme={toggleCustomTheme} />

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{isEditing ? 'Edit Method' : 'Add Method'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {isEditing ? 'Edit the details of the method.' : 'Enter the details of the new method.'}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            name="Component Name"
            label="Component Name"
            type="text"
            fullWidth
            value={currentMethod?.['Component Name'] || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="Method"
            label="Method"
            type="text"
            fullWidth
            value={currentMethod?.Method || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="Ability"
            label="Ability"
            type="text"
            fullWidth
            value={currentMethod?.Ability || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="Repetition"
            label="Repetition"
            type="text"
            fullWidth
            value={currentMethod?.Repetition || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="Intensity"
            label="Intensity"
            type="text"
            fullWidth
            value={currentMethod?.Intensity || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="Set"
            label="Set"
            type="text"
            fullWidth
            value={currentMethod?.Set || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="Tempo"
            label="Tempo"
            type="text"
            fullWidth
            value={currentMethod?.Tempo || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="Recovery"
            label="Recovery"
            type="text"
            fullWidth
            value={currentMethod?.Recovery || ''}
            onChange={handleInputChange}
          />
          <TextField
            margin="dense"
            name="Target"
            label="Target"
            type="text"
            fullWidth
            value={currentMethod?.Target || ''}
            onChange={handleInputChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">Cancel</Button>
          <Button onClick={handleDialogSave} color="primary">Save</Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          position: 'fixed',
          right: 0,
          top: 'calc(80px + 16px)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          p: 1,
          bgcolor: 'none',
          borderRadius: '8px',
          width: '40px',
        }}
      >
        <Tooltip title="Save">
          <IconButton onClick={saveData} sx={{ color: 'white', mb: 1, p: 0.5 }}>
            <SaveIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton onClick={deleteData} sx={{ color: 'white', mb: 1, p: 0.5 }}>
            <DeleteIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Copy">
          <IconButton onClick={copyData} sx={{ color: 'white', mb: 1, p: 0.5 }}>
            <CopyIcon fontSize="small" />
          </IconButton>
        </Tooltip>
        <Tooltip title="Upload">
          <IconButton onClick={handleFileUploadOpen} sx={{ color: 'white', mb: 1, p: 0.5 }}>
            <AddIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </Box>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Dialog open={fileUploadDialogOpen} onClose={handleFileUploadClose}>
        <DialogTitle>Upload Excel File</DialogTitle>
        <DialogContent>
          <input
            accept=".xlsx, .xls"
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={handleFileChange}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              Upload Excel
            </Button>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleFileUploadClose} color="primary">Cancel</Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

function ToggleCustomTheme({ showCustomTheme, toggleCustomTheme }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100dvw',
        position: 'fixed',
        bottom: 24,
        backgroundColor: '#0D1620',
      }}
    >
      <ToggleButtonGroup
        color="primary"
        exclusive
        value={showCustomTheme}
        onChange={toggleCustomTheme}
        aria-label="Platform"
        sx={{
          backgroundColor: 'background.default',
          '& .Mui-selected': {
            pointerEvents: 'none',
          },
        }}
      >
        {/* Content for ToggleButtonGroup can be added here */}
      </ToggleButtonGroup>
    </Box>
  );
}

ToggleCustomTheme.propTypes = {
  showCustomTheme: PropTypes.bool.isRequired,
  toggleCustomTheme: PropTypes.func.isRequired,
};

export default Methodology;
