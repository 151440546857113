import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CssBaseline, Box, Divider, ThemeProvider, createTheme, Container, Paper, Typography, Button } from '@mui/material';
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer';
import ChatInput from './components/ChatInput';
import './App.css'; // Assuming the CSS for flashing text is added here

const baseQuestions = [
  "Hello! It's a pleasure to meet you. I don't think we've met before. May I ask your name?",
  "placeholder", // This will dynamically update based on the name.
  "That's great! Please enter your height",
  "Please enter your weight",
  "Alright. Now step to the base to proceed and stand still. You have 5 seconds to take your position. Press the button below to proceed.",
   
];

export default function TryPage() {
  const [mode, setMode] = useState('dark');
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [chatHistory, setChatHistory] = useState([]);
  const [name, setName] = useState('');
  const [questions, setQuestions] = useState([...baseQuestions]);
  const [isTyping, setIsTyping] = useState(false);
  const navigate = useNavigate();

  const theme = createTheme({
    palette: { mode },
  });

  const toggleColorMode = () => setMode((prevMode) => (prevMode === 'dark' ? 'light' : 'dark'));

  useEffect(() => {
    if (name) {
      const newQuestions = [...baseQuestions];
      newQuestions[1] = `Hi ${name}, Would you like to test me?`;
      setQuestions(newQuestions);
  
      setIsTyping(true);
      setTimeout(() => {
        setChatHistory(current => [...current, { type: 'question', text: newQuestions[1] }]);
        setIsTyping(false);
        setCurrentQuestionIndex(1);
      }, 3000); // 3 seconds delay
    }
  }, [name]);
  

  useEffect(() => {
    if (chatHistory.length === 0 && !isTyping) {
      setIsTyping(true);
      setTimeout(() => {
        setIsTyping(false);
        setChatHistory([{ type: 'question', text: questions[0] }]);
      }, 3000); // 3 seconds delay
    }
  }, [chatHistory.length, questions, isTyping]);
  
  const handleAnswerSubmit = (answer) => {
    const updatedChatHistory = [...chatHistory, { type: 'answer', text: answer }];
    setChatHistory(updatedChatHistory);
  
    if (currentQuestionIndex === 0) {
      setName(answer); // This triggers the useEffect that updates the question for index 1
    } else {
      // Increment question index and show next question
      const nextIndex = currentQuestionIndex + 1;
      if (nextIndex < questions.length) {
        setIsTyping(true);
        setTimeout(() => {
          setIsTyping(false);
          setCurrentQuestionIndex(nextIndex);
          setChatHistory((current) => [...current, { type: 'question', text: questions[nextIndex] }]);
        }, 3000); // Simulate delay for typing the next question
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppAppBar mode={mode} toggleColorMode={toggleColorMode} />
      <Container sx={{ mt: 12, bgcolor: theme.palette.background.default, minHeight: 'calc(100vh - 64px)' }}>
        <Paper elevation={3} sx={{ margin: 2, padding: 2, backgroundColor: theme.palette.background.paper }}>
          <Typography variant="h5" gutterBottom>Welcome to your free trial</Typography>
          <Box sx={{ p: 2, borderRadius: 2 }}>
            {chatHistory.map((chat, index) => (
              <Box key={index} sx={{
                display: 'flex',
                justifyContent: chat.type === 'question' ? 'flex-start' : 'flex-end',
                mb: 1,
              }}>
                <Typography sx={{
                  padding: 1,
                  borderRadius: '20px',
                  bgcolor: 'grey',
                  border: '1px solid white',
                }}>
                  {chat.text}
                </Typography>
              </Box>
            ))}
            {isTyping && (
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                mb: 1,
              }}>
                <Typography sx={{
                  padding: 1,
                  borderRadius: '20px',
                  bgcolor: 'grey',
                  border: '1px solid white',
                }} className="flashing-text">
                  Typing...
                </Typography>
              </Box>
            )}
          </Box>
          {currentQuestionIndex < questions.length - 1 && !isTyping ? (
            <ChatInput onAnswerSubmit={handleAnswerSubmit} />
          ) : null}
          {currentQuestionIndex >= questions.length - 1 && !isTyping ? (
            <Button variant="contained" color="primary" onClick={() => navigate('/scanpage')} sx={{ mt: 2 }}>
              Go to Scan Page
            </Button>
          ) : null}
        </Paper>
      </Container>
      <Box sx={{ bgcolor: theme.palette.background.default }}>
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
}
