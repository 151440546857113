// src/openaiApi.js
import axios from 'axios';

const API_KEY = process.env.REACT_APP_OPENAI_API_KEY;

const instance = axios.create({
  baseURL: 'https://api.openai.com/v1/',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${API_KEY}`,
  },
});

export const fetchChatGPTResponse = async (prompt) => {
  const data = {
    model: 'gpt-3.5-turbo',
    messages: [{ role: 'user', content: prompt }],
  };
  try {
    const response = await instance.post('chat/completions', data);
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error fetching data from OpenAI API:', error);
    if (error.response) {
      console.error('Error response data:', error.response.data);
    }
    return null;
  }
};
