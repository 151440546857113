// src/firebase-config.js

import { initializeApp } from "firebase/app";

import { getAuth } from "firebase/auth";

import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
  apiKey: "AIzaSyC4r_7E0cMw8s8VZ1uz2RBEz2oni7XDHSQ",
  authDomain: "jump-15645.firebaseapp.com",
  projectId: "jump-15645",
  storageBucket: "jump-15645.appspot.com",
  messagingSenderId: "828899030922",
  appId: "1:828899030922:web:0977fdb455418f287bfa73",
  measurementId: "G-41C26H7S39"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app); // Make sure this line is exactly like this
export const db = getFirestore(app);
export const database = getDatabase(app);
export const analytics = getAnalytics(app);
export const storage = getStorage(app); // Export storage instance