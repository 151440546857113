import * as React from 'react';
import PropTypes from 'prop-types';
import { Box, MenuItem, Toolbar, Container, AppBar, Button, Drawer, Tooltip, List, ListItem, ListItemText, Collapse } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

const logoStyle = {
  maxHeight: '20px',
  height: 'auto',
  marginTop: '-15px',
  cursor: 'pointer',
};

const Navbar = ({ mode, toggleColorMode }) => {
  const [open, setOpen] = React.useState(false);
  const [openAssessment, setOpenAssessment] = React.useState(false);

  const handleMouseEnter = () => {
    setOpen(true);
  };

  const handleMouseLeave = () => {
    setOpen(false);
  };

  const handleAssessmentClick = () => {
    setOpenAssessment(!openAssessment);
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: '#303E4A', // Set the desired background color
          backgroundImage: 'none',
          mt: 0,
          width: '100%',
          height: '50px'
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center', // Center align all items
              height: '64px', // Set a consistent height for the navbar
              padding: 0,
            }}
          >
            <Box
              component="img"
              src={`${process.env.PUBLIC_URL}/logo.png`}
              alt="logo"
              sx={logoStyle}
            />
          </Toolbar>
        </Container>
      </AppBar>

      <Box
        sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          height: '50px',
          width: '50px',
          zIndex: 1300,
          '&:hover': {
            width: '250px',
            transition: 'width 0.3s',
          },
          transition: 'width 0.3s',
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Box
          sx={{
            height: '100%',
            bgcolor: '#303E4A',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Tooltip title="Menu">
            <MenuIcon />
          </Tooltip>
        </Box>

        <Drawer
          anchor="left"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{
            sx: {
              width: 250,
              p: 2,
              backgroundColor: '#1A2B3C',
            },
          }}
          variant="persistent"
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <MenuItem component={Link} to="/admin">
              Admin
            </MenuItem>
            <MenuItem component={Link} to="/plan">
              Plan
            </MenuItem>
            <MenuItem onClick={handleAssessmentClick}>
              Assessment {openAssessment ? <ExpandLess /> : <ExpandMore />}
            </MenuItem>
            <Collapse in={openAssessment} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: 2 }}>
                <ListItem button component={Link} to="/assessment/wellness">
                  <ListItemText primary="Wellness" sx={{ color: '#fff' }} />
                </ListItem>
                <ListItem button component={Link} to="/assessment/performance">
                  <ListItemText primary="Performance" sx={{ color: '#fff' }} />
                </ListItem>
                <ListItem button component={Link} to="/assessment/anthropometry">
                  <ListItemText primary="Anthropometry" sx={{ color: '#fff' }} />
                </ListItem>
              </List>
            </Collapse>
            <MenuItem component={Link} to="/methodology">
              Methodology
            </MenuItem>
            <MenuItem component={Link} to="/settings">
              Settings
            </MenuItem>
            <MenuItem component={Link} to="/tutorials">
              Tutorials
            </MenuItem>
            <MenuItem component={Link} to="/athletepage" sx={{ color: '#1EB980' }}>
              Athlete Login
            </MenuItem>
            <MenuItem component={Link} to="/contact">
              FAQ
            </MenuItem>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              to="/"
              sx={{ width: '100%', mt: 1 }}
            >
              Sign out
            </Button>
          </Box>
        </Drawer>
      </Box>
    </div>
  );
};

Navbar.propTypes = {
  mode: PropTypes.oneOf(['dark', 'light']).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default Navbar;
