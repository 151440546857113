import React, { useState, useEffect } from 'react';
import { CssBaseline, Box, Divider, Container, Paper, Typography, ThemeProvider, createTheme } from '@mui/material';
import AppAppBar from './components/AppAppBar';
import Footer from './components/Footer';
import './App.css';
import scannerImage from './assets/scanner.png';


const ScanPage = () => {
  const [countdown, setCountdown] = useState(5);
  const [showScanningEffect, setShowScanningEffect] = useState(false);
  const [scanningMessage, setScanningMessage] = useState('Please Prepare for Scanning');

  useEffect(() => {
    let countdownTimer;
    let scanningEffectTimer;

    const processPose = () => {
      // Mock function for processing pose
      console.log('Pose processed');
    };

    if (countdown > 0) {
      countdownTimer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else {
      setShowScanningEffect(true);
      setScanningMessage(''); // Clear message when scanning starts

      scanningEffectTimer = setTimeout(() => {
        setShowScanningEffect(false);
        setScanningMessage('Scanning Completed'); // Display message after scanning is completed
        processPose(); // Call your pose processing function here
      }, 5000); // Scanning lasts for 5 seconds
    }

    return () => {
      clearTimeout(countdownTimer);
      clearTimeout(scanningEffectTimer);
    };
  }, [countdown]);

  // Define a dark theme
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <AppAppBar />
      <Container sx={{
        mt: { xs: 14, sm: 16 },
        
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '90vh',
        width: '60vh',
        maxWidth: '100%',
        position: 'relative',
        border: '2px solid #333',
        borderRadius: '15px',
      }}>
       <Paper elevation={3} sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          position: 'relative',
          overflow: 'hidden',
          backgroundImage: `url(${scannerImage})`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat', // Ensure the image does not repeat
        }}>
          {/* Image Box */}
          <Box
            sx={{
              position: 'absolute',
              
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              zIndex: -1, // Ensure the image is behind your text/content
            }}
          />
          {scanningMessage && <Typography variant="h4" gutterBottom>{scanningMessage}</Typography>}
          {countdown > 0 && <Typography variant="h1" gutterBottom className="flashing-text">{countdown}</Typography>}
          {showScanningEffect && (
            <>
              <Box className="scanning-line vertical-down" />
              <Box className="scanning-line vertical-up" />
              <Box className="scanning-line horizontal-right" />
              <Box className="scanning-line horizontal-left" />
            </>
          )}
        </Paper>

      </Container>
      <Box sx={{ bgcolor: 'background.default' }}>
        <Divider />
        <Footer />
      </Box>
    </ThemeProvider>
  );
};

export default ScanPage;
