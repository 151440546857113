import * as React from 'react';
import { Container, Box,  Avatar } from '@mui/material';
import NavbarAthlete from './components/NavbarAthlete';

const AthletePage = () => {
  

  return (
    <Box sx={{ height: '100vh', backgroundColor: '#1A2B3C', overflow: 'hidden' }}>
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100vh - 50px)', // Subtract the height of the BottomNavigation
        }}
      >
        <Avatar
          alt="Player Avatar"
          src="/path/to/player/avatar.jpg" // Update this with the correct path to the player's avatar
          sx={{ width: 70, height: 70, marginTop: 2 }}
        />
        
      </Container>
      <NavbarAthlete />
    </Box>
  );
};

export default AthletePage;
