// src/components/Chat.js
import React, { useState } from 'react';
import { fetchChatGPTResponse } from '../openaiApi';
import { Box, TextField, Button, Typography } from '@mui/material';

const Chat = () => {
  const [input, setInput] = useState('');
  const [response, setResponse] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();
    console.log('Sending request:', input);
    const chatResponse = await fetchChatGPTResponse(input);
    if (chatResponse) {
      console.log('Received response:', chatResponse);
      setResponse(chatResponse);
    } else {
      console.error('Failed to fetch response');
      setResponse('Error fetching response. Please try again.');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4">Chat with GPT-3</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Your message"
          variant="outlined"
          fullWidth
          value={input}
          onChange={(e) => setInput(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" type="submit">
          Send
        </Button>
      </form>
      {response && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Response:</Typography>
          <Typography variant="body1">{response}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Chat;
