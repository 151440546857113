import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Navbar from './components/Navbar';
import Tools from './components/Tools';

import Footer from './components/Footer';

export default function TrainingPointPage() {
  const [mode, setMode] = React.useState('dark'); // Assuming you still want the ability to toggle dark/light mode
  const defaultTheme = createTheme({ palette: { mode } });

  const toggleColorMode = () => {
    setMode((prev) => (prev === 'dark' ? 'light' : 'dark'));
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline />
      <Navbar mode={mode} toggleColorMode={toggleColorMode} />
     
        <Tools/>
        <Divider />
        <Footer />
      
    </ThemeProvider>
  );
}
