import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Typography, IconButton, Snackbar, Button, Alert, Divider, 
  Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, List, ListItem, ListItemText, Avatar
} from '@mui/material';
import { Visibility, VisibilityOff, Person } from '@mui/icons-material';
import { doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from './firebase-config';
import ExerciseSelectDialog from './ExerciseSelectDialog';
import AddDrill from './AddDrill'; // Ensure AddDrill can handle preselected exercises
import NavbarAthlete from './NavbarAthlete'; // Import NavbarAthlete

const trainingComponents = [
  'Strength', 'Speed', 'Endurance', 'ROM', 'Coordination', 'Warming Up', 'Cool Down', 'Sport Specific', 'Meeting'
];

// Hardcoded exercises for Strength component
const strengthExercises = [
  { id: '1A', name: 'Back Squat' },
  { id: '1B', name: 'High Plank Reach' },
  { id: '2A', name: 'Bench Press' },
  { id: '2B', name: 'Knee Extension' },
  { id: '3A', name: 'Deadlift' },
  { id: '3B', name: 'Bench Press' },
  { id: '4A', name: 'Deadlift' },
  { id: '4B', name: 'Bent Over Row - BB' },
];

const Training = ({ selectedGroup, selectedYear, mode }) => {
  const [components, setComponents] = useState({ AM: [], PM: [] });
  const [exerciseSchedule, setExerciseSchedule] = useState({});
  const [expandedComponents, setExpandedComponents] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [openComponentDialog, setOpenComponentDialog] = useState(false);
  const [selectedComponents, setSelectedComponents] = useState([]);
  const [openExerciseDialog, setOpenExerciseDialog] = useState(false);
  const [selectedPeriod, setSelectedPeriod] = useState('');

  useEffect(() => {
    const fetchGroupData = async () => {
      if (selectedGroup) {
        const groupDoc = await getDoc(doc(db, 'groups', selectedGroup));
        if (groupDoc.exists()) {
          const groupData = groupDoc.data();
          setComponents(groupData.components || {
            AM: ['Warming Up', 'Strength', 'Football'],  // Hardcoded AM components
            PM: ['Meeting', 'Warming Up', 'Endurance', 'Football', 'Cool Down'] // Hardcoded PM components
          });
          setExerciseSchedule(groupData.exerciseSchedule || {});
        }
      } else {
        setComponents({
          AM: ['Warming Up', 'Strength', 'Football'],  // Hardcoded AM components
          PM: ['Meeting', 'Warming Up', 'Endurance', 'Football', 'Cool Down'] // Hardcoded PM components
        });
      }
    };
    fetchGroupData();
  }, [selectedGroup]);

  const handleComponentDialogClose = () => {
    setOpenComponentDialog(false);
  };

  const handleToggleComponent = (component) => {
    const currentIndex = selectedComponents.indexOf(component);
    const newChecked = [...selectedComponents];
    if (currentIndex === -1) {
      newChecked.push(component);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setSelectedComponents(newChecked);
  };

  const handleSaveComponents = async () => {
    const updatedComponents = {
      ...components,
      [selectedPeriod]: [...components[selectedPeriod], ...selectedComponents.filter(component => !components[selectedPeriod].includes(component))]
    };
    setComponents(updatedComponents);

    if (selectedGroup) {
      try {
        await setDoc(doc(db, 'groups', selectedGroup), { components: updatedComponents }, { merge: true });
        setSnackbarMessage('Components saved successfully!');
        setSnackbarSeverity('success');
      } catch (error) {
        console.error('Error saving components: ', error);
        setSnackbarMessage('Error saving components');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    }
    setOpenComponentDialog(false);
  };

  const saveExerciseSchedule = async (updatedSchedule) => {
    if (selectedGroup) {
      try {
        await setDoc(doc(db, 'groups', selectedGroup), { exerciseSchedule: updatedSchedule }, { merge: true });
        setSnackbarMessage('Schedule saved successfully!');
        setSnackbarSeverity('success');
      } catch (error) {
        console.error('Error saving schedule: ', error);
        setSnackbarMessage('Error saving schedule');
        setSnackbarSeverity('error');
      }
      setSnackbarOpen(true);
    }
  };

  const handleExerciseDialogClose = () => {
    setOpenExerciseDialog(false);
  };

  const handleExerciseSelect = (newSelectedExercises) => {
    const updatedExercises = { ...exerciseSchedule };
    const dayKey = `Training-${selectedPeriod}`;
    if (!updatedExercises[dayKey]) {
      updatedExercises[dayKey] = [];
    }
    newSelectedExercises.forEach(exercise => {
      updatedExercises[dayKey].push(exercise.name);
    });
    setExerciseSchedule(updatedExercises);
    saveExerciseSchedule(updatedExercises);
    setOpenExerciseDialog(false);
  };

  const handleOpenComponentDialog = (period) => {
    setSelectedPeriod(period);
    setSelectedComponents(components[period]);
    setOpenComponentDialog(true);
  };

  const renderComponentDetails = (period) => {
    const dayKey = `Training-${period}`;

    return (
      <Box key={period} sx={{ mb: 2, backgroundColor: 'transparent', borderRadius: '10px' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: 0,
            mb: 0,
            minHeight: '20px',
            backgroundColor: '#005D57',
            width: '100%',
            borderTopLeftRadius: '7px',
            borderTopRightRadius: '7px'
          }}
          onClick={() => handleOpenComponentDialog(period)}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mr: 1 // Margin right for spacing
            }}
          >
            <Box
              sx={{
                width: '20px',
                height: '20px',
                backgroundColor: '#fff', // Adjust the color as needed
                borderTopLeftRadius: '7px', // This makes it a small circle, change as needed
                mr: 1,// Margin right for spacing
                ml: -2.4
              }}
            />
            <Typography
              sx={{
                color: 'lightgrey',
                mb: 0,
                cursor: 'pointer',
                fontSize: '0.775rem',
                textTransform: 'uppercase',
                textAlign: 'center'
              }}
            >
              {period}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ backgroundColor: '#303E4A', height: '4px', my: 0 }} />
        {components[period].map((component, index) => (
          <React.Fragment key={index}>
            <Box
              sx={{
                mb: 0,
                p: 0.0,
                backgroundColor: '#1A2B3C',
                borderRadius: '4px',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '70px' }}>
                <Typography
                  sx={{ color: '#1EB980', px: 2, mb: 0, cursor: 'pointer', textTransform: 'uppercase', fontSize:'20px' }}
                >
                  {component}
                </Typography>
                <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                  <IconButton
                    onClick={() => setExpandedComponents(prevState => ({
                      ...prevState,
                      [dayKey]: prevState[dayKey] === component ? null : component
                    }))}
                    sx={{ color: '#fff' }}
                  >
                    {expandedComponents[dayKey] === component ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </Box>
                </Box>
              {/*
                In the renderComponentDetails function inside Training component
              */}
              <Collapse in={expandedComponents[dayKey] === component} timeout="auto" unmountOnExit>
                <Box sx={{ mt: 0, backgroundColor: 'rgba(0, 0, 0, 0.5)', padding: '0px' }}>
                  {component === 'Strength' ? (
                    <AddDrill trainingComponent={component} preselectedExercises={strengthExercises} />
                  ) : (
                    <AddDrill trainingComponent={component} />
                  )}
                </Box>
              </Collapse>

              </Box>

            <Divider sx={{ backgroundColor: '#303E4A', height: '4px' }} />
          </React.Fragment>
        ))}
      </Box>
    );
  };

  return (
    <Box sx={{ height: '100vh', backgroundColor: '#1A2B3C', overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: 2, bgcolor: '#303E4A', height: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{ bgcolor: '#1EB980', width: 30, height: 30, cursor: 'pointer' }}
            onClick={() => console.log('Navigate to profile')}
          >
            <Person sx={{ color: '#ffffff', fontSize: 24 }} />
          </Avatar>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
          <Typography sx={{ color: '#ffffff', fontFamily: 'Roboto Condensed', fontWeight: 'bold', marginRight: 4 }}>
            {new Date().toLocaleDateString()}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ mt: 2 }}>
        {renderComponentDetails('AM')}
        {renderComponentDetails('PM')}
      </Box>

      <Dialog open={openComponentDialog} onClose={handleComponentDialogClose}>
        <DialogTitle>Select Training Components</DialogTitle>
        <DialogContent>
          <List>
            {trainingComponents.map(component => (
              <ListItem key={component} button onClick={() => handleToggleComponent(component)}>
                <Checkbox checked={selectedComponents.includes(component)} />
                <ListItemText primary={component} />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleComponentDialogClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSaveComponents} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>

      <ExerciseSelectDialog
        open={openExerciseDialog}
        onClose={handleExerciseDialogClose}
        onSave={handleExerciseSelect}
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <NavbarAthlete />
    </Box>
  );
};

Training.propTypes = {
  selectedGroup: PropTypes.string.isRequired,
  selectedYear: PropTypes.number.isRequired,
  mode: PropTypes.string.isRequired,
};

export default Training;
