import * as React from 'react';
import { Container, Typography, Box, Slider, Button, TextField, Avatar } from '@mui/material';
import { collection, addDoc } from 'firebase/firestore';
import { db } from './firebase-config';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import NavbarAthlete from './NavbarAthlete';
import PersonIcon from '@mui/icons-material/Person';

const WellnessPage = () => {
  const [sleep, setSleep] = React.useState(4);
  const [fatigue, setFatigue] = React.useState(4);
  const [soreness, setSoreness] = React.useState(4);
  const [comment, setComment] = React.useState('');
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSliderChange = (setter) => (event, newValue) => {
    setter(newValue);
  };

  const handleSend = async () => {
    try {
      await addDoc(collection(db, 'wellness'), {
        sleep,
        fatigue,
        soreness,
        comment,
        timestamp: new Date(),
      });
      alert('Data sent successfully!');
    } catch (error) {
      console.error('Error sending data: ', error);
      alert('Error sending data');
    }
  };

  const currentDate = new Date().toLocaleDateString();

  return (
    <Box sx={{ height: '100vh', backgroundColor: '#1A2B3C', overflow: 'hidden' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: 2, bgcolor: '#303E4A', height: '20px' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            sx={{ bgcolor: '#1EB980', width: 30, height: 30, cursor: 'pointer' }}
            onClick={() => navigate('/athleteprofile')}
          >
            <PersonIcon sx={{ color: '#ffffff', fontSize: 24 }} />
          </Avatar>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flex: 1 }}>
          <Typography sx={{ color: '#ffffff', fontFamily: 'Roboto Condensed', fontWeight: 'bold', marginRight: 4 }}>
            {currentDate}
          </Typography>
        </Box>
      </Box>

      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: 'calc(100vh - 100px)', // Subtract the height of the header and BottomNavigation
          maxWidth: { md: '33%', xs: '100%' }, // Limit max width to one-third on larger screens
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: { xs: 2, md: 4 }, width: '100%' }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Slider
              orientation="vertical"
              value={sleep}
              onChange={handleSliderChange(setSleep)}
              aria-labelledby="vertical-slider-sleep"
              valueLabelDisplay="auto"
              min={1}
              max={7}
              sx={{
                height: { xs: 400, md: 300 }, // Higher height on mobile screens
                width: '12px', // Thinner slider
                '& .MuiSlider-track': {
                  backgroundColor: '#303E4A',
                },
                '& .MuiSlider-thumb': {
                  width: '40px', // Bigger sliding circle
                  height: '40px', // Bigger sliding circle
                  backgroundColor: '#1EB980',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&::after': {
                    content: `"${sleep}"`, // Display the value inside the thumb
                    position: 'absolute', // Ensure absolute positioning
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Center the content
                    color: 'white',
                    fontWeight: 'bold',
                  },
                },
                '& .MuiSlider-rail': {
                  backgroundColor: '#ffffff', // Non-selected part of the slider
                },
              }}
              ThumbComponent={(props) => (
                <span {...props} data-value={sleep} />
              )}
            />
            <Typography
              variant="body1"
              sx={{
                marginTop: 1,
                color: '#1EB980',
                textTransform: 'uppercase',
                fontFamily: 'Roboto Condensed',
                fontWeight: 'bold', // Bold text
                width: '80px', // Fixed width to ensure same length as the longest label
                textAlign: 'center', // Center align the text
              }}
            >
              Sleep
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Slider
              orientation="vertical"
              value={fatigue}
              onChange={handleSliderChange(setFatigue)}
              aria-labelledby="vertical-slider-fatigue"
              valueLabelDisplay="auto"
              min={1}
              max={7}
              sx={{
                height: { xs: 400, md: 300 }, // Higher height on mobile screens
                width: '12px', // Thinner slider
                '& .MuiSlider-track': {
                  backgroundColor: '#303E4A',
                },
                '& .MuiSlider-thumb': {
                  width: '40px', // Bigger sliding circle
                  height: '40px', // Bigger sliding circle
                  backgroundColor: '#1EB980',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&::after': {
                    content: `"${fatigue}"`, // Display the value inside the thumb
                    position: 'absolute', // Ensure absolute positioning
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Center the content
                    color: 'white',
                    fontWeight: 'bold',
                  },
                },
                '& .MuiSlider-rail': {
                  backgroundColor: '#ffffff', // Non-selected part of the slider
                },
              }}
              ThumbComponent={(props) => (
                <span {...props} data-value={fatigue} />
              )}
            />
            <Typography
              variant="body1"
              sx={{
                marginTop: 1,
                color: '#1EB980',
                textTransform: 'uppercase',
                fontFamily: 'Roboto Condensed',
                fontWeight: 'bold', // Bold text
                width: '80px', // Fixed width to ensure same length as the longest label
                textAlign: 'center', // Center align the text
              }}
            >
              Fatigue
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Slider
              orientation="vertical"
              value={soreness}
              onChange={handleSliderChange(setSoreness)}
              aria-labelledby="vertical-slider-soreness"
              valueLabelDisplay="auto"
              min={1}
              max={7}
              sx={{
                height: { xs: 400, md: 300 }, // Higher height on mobile screens
                width: '12px', // Thinner slider
                '& .MuiSlider-track': {
                  backgroundColor: '#303E4A',
                },
                '& .MuiSlider-thumb': {
                  width: '40px', // Bigger sliding circle
                  height: '40px', // Bigger sliding circle
                  backgroundColor: '#1EB980',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&::after': {
                    content: `"${soreness}"`, // Display the value inside the thumb
                    position: 'absolute', // Ensure absolute positioning
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Center the content
                    color: 'white',
                    fontWeight: 'bold',
                  },
                },
                '& .MuiSlider-rail': {
                  backgroundColor: '#ffffff', // Non-selected part of the slider
                },
              }}
              ThumbComponent={(props) => (
                <span {...props} data-value={soreness} />
              )}
            />
            <Typography
              variant="body1"
              sx={{
                marginTop: 1,
                color: '#1EB980',
                textTransform: 'uppercase',
                fontFamily: 'Roboto Condensed',
                fontWeight: 'bold', // Bold text
                width: '80px', // Fixed width to ensure same length as the longest label
                textAlign: 'center', // Center align the text
              }}
            >
              Soreness
            </Typography>
          </Box>
        </Box>
        <TextField
          label="Comment"
          variant="outlined"
          multiline
          rows={2}
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          sx={{ marginTop: 4, width: '80%', backgroundColor: '#303E4A', borderRadius: '10px' }}
        />
        <Button
          variant="contained"
          onClick={handleSend}
          sx={{
            marginTop: 4,
            marginBottom: 2, // Reduce marginBottom to move closer to the navbar
            backgroundColor: '#1EB980',
            '&:hover': { backgroundColor: '#17A472' },
          }}
        >
          SEND
        </Button>
      </Container>
      <NavbarAthlete />
    </Box>
  );
};

export default WellnessPage;
