import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: '100%',
        height: '100vh',
        position: 'relative', // Ensure the Box is relatively positioned
        overflow: 'hidden', // Prevent overflow from the video
        backgroundImage:
          theme.palette.mode === 'light'
            ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
            : 'linear-gradient(#121212, #121212)',
        backgroundSize: '100% 5%',
        backgroundRepeat: 'no-repeat',
      })}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          zIndex: -1, // Place the video behind the content
        }}
      >
        <video
          style={{ width: '100%', height: '100%', objectFit: 'cover' }}
          src={`${process.env.PUBLIC_URL}/LandingPagePromo.mp4`}
          autoPlay
          loop
          muted
        >
          Your browser does not support the video tag.
        </video>
      </Box>
      <Container
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center', // Center content horizontally
          height: '100%', // Ensure the container also covers the full height
          position: 'relative', // Ensure the text is positioned relative to the Box
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <Typography
            variant="body1"
            textAlign="center"
            color="text.secondary"
            sx={{ marginBottom: '1em' }}
          >
            <Typography
              component="span"
              variant="h3"
              sx={(theme) => ({
                display: 'inline',
                fontWeight: 'bold',
                marginRight: '8px',
                fontStyle: 'regular',
                fontFamily: 'Roboto',
                color: '#9FDAFF',
                fontSize: '3rem', // Default font size for larger screens
                [theme.breakpoints.down('sm')]: {
                  fontSize: '2rem', // Smaller font size for mobile devices
                },
              })}
            >
              PRACTICE PERFECTED BY TECHNOLOGY
            </Typography>
            <br />
            
            <Typography
              component="span"
              variant="h4"
              sx={(theme) => ({
                display: 'inline',
                fontWeight: 'bold',
                fontFamily: 'Roboto',
                color: '#FFF',
                fontSize: '2.0rem', // Default font size for larger screens
                [theme.breakpoints.down('sm')]: {
                  fontSize: '1.0rem', // Smaller font size for mobile devices
                },
              })}
            >
              Unleash Your Performance
            </Typography>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
}
