import React, { useEffect, useState } from 'react';
import { Grid, Avatar, Tooltip, CardContent, Box, Button, IconButton, Collapse, Typography, createTheme, ThemeProvider } from '@mui/material';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import GroupIcon from '@mui/icons-material/Group';
import { styled } from '@mui/system';

// Styled Card Component
const CustomCard = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: 0,
  borderRadius: 0,
  border: '1px solid #1A2B3C',
}));

// Player Avatar Component
const PlayerAvatar = ({ player, onSelect, onUpperClick, isSelected, shadedPlayerId, isLowerHalfActive }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'PLAYER',
    item: { player },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const isShaded = shadedPlayerId && shadedPlayerId !== player.id;

  return (
    <Tooltip title={`${player.firstName} ${player.lastName}`}>
      <div ref={drag} style={{ margin: '8px', opacity: isDragging ? 0.5 : 1, cursor: 'move', position: 'relative' }}>
        <Avatar
          src={player.photoURL}
          alt={`${player.firstName} ${player.lastName}`}
          sx={{
            width: 60,
            height: 60,
            border: `2px solid ${player.subGroupColor || 'transparent'}`,
            boxShadow: isSelected ? '0 0 10px 2px #1EB980' : 'none',
            backgroundColor: isShaded ? 'rgba(0, 0, 0, 0.3)' : 'grey',
            filter: isShaded ? 'grayscale(100%)' : 'none',
          }}
          className="player-avatar"
        />
        <div
          onClick={(e) => {
            e.stopPropagation();
            onUpperClick(player);
          }}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            height: '50%',
            cursor: 'pointer',
            backgroundColor: 'rgba(0, 0, 0, 0)',
          }}
        />
        <div
          onClick={(e) => {
            if (isLowerHalfActive) {
              e.stopPropagation();
              onSelect(player);
            }
          }}
          style={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            height: '50%',
            cursor: isLowerHalfActive ? 'pointer' : 'default',
            backgroundColor: 'rgba(0, 0, 0, 0)',
          }}
        />
      </div>
    </Tooltip>
  );
};

// Drop Zone Component
const DropZone = ({ players, onDropPlayer, subGroupName, color, onSelect, onUpperClick, selectedPlayerId, shadedPlayerId }) => {
  const [, drop] = useDrop({
    accept: 'PLAYER',
    drop: (item) => {
      onDropPlayer(item.player, subGroupName, color);
    },
  });

  return (
    <Box
      ref={drop}
      sx={{
        backgroundColor: '#1A2B3C',
        minWidth: '200px',
        padding: '8px',
        borderRadius: '10px',
        border: `1px solid ${color}`,
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'flex-start',
        minHeight: '150px',
        marginBottom: '8px',
        position: 'relative',
      }}
    >
      <Typography variant="subtitle2" color="textPrimary" sx={{ position: 'absolute', top: 0, left: 0, padding: '2px 4px', backgroundColor: '#303E4A', borderRadius: '0 0 4px 0' }}>
        {subGroupName}
      </Typography>
      {players.map((player) => (
        <PlayerAvatar
          key={player.id}
          player={{ ...player, subGroupColor: color }}
          onSelect={onSelect}
          onUpperClick={onUpperClick}
          isSelected={selectedPlayerId === player.id}
          shadedPlayerId={shadedPlayerId}
          isLowerHalfActive={!shadedPlayerId}
        />
      ))}
    </Box>
  );
};

// Main PlayersCard Component
const PlayersCard = ({ onPlayerSelect }) => {
  const [groupingOpen, setGroupingOpen] = useState(false);
  const [subGroups, setSubGroups] = useState({ Absent: [], Default: [] });
  const [selectedPlayerId, setSelectedPlayerId] = useState(null);
  const [shadedPlayerId, setShadedPlayerId] = useState(null);

  const colorPalette = ['#FF6859', '#FFCF44', '#B15DFF', '#72DEFF', '#1E90FF', '#FF69B4', '#32CD32', '#FFA500'];

  useEffect(() => {
    const fetchPlayersAndSubGroups = async () => {
      const playersSnapshot = await getDocs(collection(db, 'players'));
      const playersList = playersSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

      const subGroupsSnapshot = await getDocs(collection(db, 'subGroups'));
      const subGroupsData = subGroupsSnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data().players || [];
        return acc;
      }, {});

      setSubGroups(prevSubGroups => ({
        ...prevSubGroups,
        Default: playersList.filter(player => !subGroupsData.Absent?.some(p => p.id === player.id)),
        ...subGroupsData,
      }));
    };
    fetchPlayersAndSubGroups();

    const handleClickOutside = (event) => {
      if (!event.target.closest('.player-avatar')) {
        setSelectedPlayerId(null);
        setShadedPlayerId(null);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const handleToggleGrouping = () => {
    if (selectedPlayerId) {
      return; // Prevent toggling group selection if a player is selected
    }
    setGroupingOpen(!groupingOpen);
  };

  const handleDropPlayer = (player, subGroupName, color) => {
    setSubGroups(prevSubGroups => {
      const newSubGroups = { ...prevSubGroups };
      Object.keys(newSubGroups).forEach(subGroup => {
        newSubGroups[subGroup] = newSubGroups[subGroup].filter(p => p.id !== player.id);
      });
      newSubGroups[subGroupName].push({ ...player, subGroupColor: color });
      return newSubGroups;
    });
  };

  const handleSelectPlayer = (player) => {
    setSelectedPlayerId(player.id);
    onPlayerSelect(player.subGroupColor);
  };

  const handleUpperClick = (player) => {
    setSelectedPlayerId(player.id);
    setShadedPlayerId(player.id);
    onPlayerSelect(player.subGroupColor);
  };

  const handleAddSubGroup = () => {
    const newSubGroupName = `SubGroup ${Object.keys(subGroups).length - 1}`;
    setSubGroups({ ...subGroups, [newSubGroupName]: [] });
  };

  const handleSaveSubGroups = async () => {
    const savePromises = Object.keys(subGroups).map(subGroupName =>
      setDoc(doc(db, 'subGroups', subGroupName), { players: subGroups[subGroupName] })
    );

    await Promise.all(savePromises);

    const allPlayersWithSubGroups = Object.values(subGroups).flat().map(player => ({
      ...player,
      subGroupColor: colorPalette[Object.keys(subGroups).findIndex(subGroup => subGroups[subGroup].includes(player)) % colorPalette.length]
    }));

    setSubGroups(prevSubGroups => ({
      ...prevSubGroups,
      Default: allPlayersWithSubGroups.filter(player => !prevSubGroups.Absent.includes(player)),
      ...prevSubGroups,
    }));

    setGroupingOpen(false);
  };

  const theme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const groupOrder = ['Default', ...Object.keys(subGroups).filter(subGroupName => subGroupName !== 'Absent' && subGroupName !== 'Default')];
  const orderedPlayers = groupOrder.flatMap(groupName => subGroups[groupName] || []);

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <CustomCard>
          <CardContent sx={{ backgroundColor: 'transparent', padding: 0 }}>
            <Grid container spacing={1}>
              {orderedPlayers.map(player => (
                <Grid item key={player.id}>
                  <Tooltip title={`${player.firstName} ${player.lastName}`}>
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                      <Avatar
                        src={player.photoURL}
                        alt={`${player.firstName} ${player.lastName}`}
                        sx={{
                          width: 45,
                          height: 45,
                          border: `3px solid ${player.subGroupColor || 'transparent'}`,
                          backgroundColor: shadedPlayerId && shadedPlayerId !== player.id ? 'rgba(0, 0, 0, 0.3)' : 'grey',
                          filter: shadedPlayerId && shadedPlayerId !== player.id ? 'grayscale(100%)' : 'none',
                        }}
                        className="player-avatar"
                      />
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          handleUpperClick(player);
                        }}
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          right: 0,
                          height: '50%',
                          cursor: 'pointer',
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                        }}
                      />
                      <div
                        onClick={(e) => {
                          if (!shadedPlayerId) {
                            e.stopPropagation();
                            handleSelectPlayer(player);
                          }
                        }}
                        style={{
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          right: 0,
                          height: '50%',
                          cursor: !shadedPlayerId ? 'pointer' : 'default',
                          backgroundColor: 'rgba(0, 0, 0, 0)',
                        }}
                      />
                    </div>
                  </Tooltip>
                </Grid>
              ))}
              <Grid item>
                <IconButton onClick={handleToggleGrouping}>
                  <GroupIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Grid>
            </Grid>
          </CardContent>

          <Collapse in={groupingOpen}>
            <Box sx={{ marginTop: 2, padding: '30px', backgroundColor: '#303E4A', borderRadius: '8px' }}>
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                <Box sx={{ width: '50%' }}>
                  <DropZone
                    players={subGroups.Default}
                    onDropPlayer={(player) => handleDropPlayer(player, 'Default', '#fff')}
                    subGroupName="Default"
                    color="#fff"
                    onSelect={handleSelectPlayer}
                    onUpperClick={handleUpperClick}
                    selectedPlayerId={selectedPlayerId}
                    shadedPlayerId={shadedPlayerId}
                  />
                </Box>
                <Box sx={{ width: '25%' }}>
                  <DropZone
                    players={subGroups.Absent}
                    onDropPlayer={(player) => handleDropPlayer(player, 'Absent', 'transparent')}
                    subGroupName="Absent"
                    color="transparent"
                    onSelect={handleSelectPlayer}
                    onUpperClick={handleUpperClick}
                    selectedPlayerId={selectedPlayerId}
                    shadedPlayerId={shadedPlayerId}
                  />
                </Box>
                {Object.keys(subGroups).filter(subGroupName => subGroupName !== 'Absent' && subGroupName !== 'Default').map((subGroupName, index) => (
                  <Box key={subGroupName} sx={{ width: '25%' }}>
                    <DropZone
                      players={subGroups[subGroupName]}
                      onDropPlayer={(player) => handleDropPlayer(player, subGroupName, colorPalette[index % colorPalette.length])}
                      subGroupName={subGroupName}
                      color={colorPalette[index % colorPalette.length]}
                      onSelect={handleSelectPlayer}
                      onUpperClick={handleUpperClick}
                      selectedPlayerId={selectedPlayerId}
                      shadedPlayerId={shadedPlayerId}
                    />
                  </Box>
                ))}
              </Box>
              <Button onClick={handleAddSubGroup} sx={{ marginTop: 2 }}>Add SubGroup</Button>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                <Button onClick={handleSaveSubGroups} color="primary">Save</Button>
                <Button onClick={handleToggleGrouping} color="secondary">Cancel</Button>
              </Box>
            </Box>
          </Collapse>
        </CustomCard>
      </DndProvider>
    </ThemeProvider>
  );
};

export default PlayersCard;




