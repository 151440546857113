import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent'; // Import CardContent
import CardMedia from '@mui/material/CardMedia'; // Import CardMedia
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';


const items = [
  {
    image: '/access2.jpeg',
    title: 'ACCESSIBILITY',
    description:
      'We are moving beyond traditional lab-based routines to offer solutions that provide faster and more convenient access to information, allowing you to make informed decisions right away.',
  },
  {
    image: '/Custom7.jpg',
    title: 'SPECIFICITY',
    description:
      'We prioritize understanding your specific needs in each sport. The result? Meticulously crafted products with thoughtful details that elevate your experience.',
  },
  {
    image: '/ind3.jpeg',
    title: 'PERSONALIZATION',
    description:
      'Practice smarter, not harder. We offer customized solutions that fit to your clients unique training needs.',
  },
  {
    image: '/inov.jpeg',
    title: 'INNOVATION',
    description:
      'Stay Ahead with Standard-Setting Features. We revolutionize the way you coach & the way your clients move, train, and perform.',
  },
  {
    image: '/Custom8.jpeg',
    title: 'CUSTOMIZATION',
    description:
      'Athlete or Enthusiast? Set your clients goals, get matched with the perfect training plan.',
  },
  {
    image: '/Auto.jpeg',
    title: 'HANDS-OFF PROCESS',
    description:
      'Coach, Not Admin? We Got You! Set it and forget it. Focus on Performance, We Handle the Rest',
  },
];

export default function Trademark() {
  return (
    <Box
      id="trademark"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#06090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: { sm: 'left', md: 'center' },
          }}
        >
          <Typography component="h2" variant="h4">
            Trademark
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
          Discover the Unbeatable Combination of Our Standards.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card>
                <CardMedia
                  component="img"
                  image={item.image}
                  alt={item.title}
                  sx={{ height: 140 }} // Adjust height as needed
                />
                <CardContent>
                  <Stack direction="column" color="inherit" spacing={1}>
                    <Box sx={{ opacity: '50%' }}>{item.icon}</Box>
                    <div>
                      <Typography fontWeight="medium" gutterBottom>
                        {item.title}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'grey.400' }}>
                        {item.description}
                      </Typography>
                    </div>
                  </Stack>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}