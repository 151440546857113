import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Card, CardContent, Typography } from '@mui/material';

const ExerciseSelectionDialog = ({ open, onClose, exercises, trainingComponent }) => {
  const [selectedExercises, setSelectedExercises] = useState([]);

  const handleSelectExercise = (exercise) => {
    setSelectedExercises([...selectedExercises, exercise]);
  };

  const handleDeselectExercise = (exercise) => {
    setSelectedExercises(selectedExercises.filter((e) => e.name !== exercise.name));
  };

  const handleSave = () => {
    onClose(selectedExercises);
    setSelectedExercises([]);
  };

  const handleCancel = () => {
    onClose([]);
    setSelectedExercises([]);
  };

  // Filter exercises based on training component, except for "Warming Up"
  const filteredExercises = trainingComponent === 'Warming Up'
    ? exercises
    : exercises.filter((exercise) => exercise.component === trainingComponent);
  console.log('Filtered exercises:', filteredExercises); // Debug log

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="md" fullWidth>
      <DialogTitle>Select Exercises for {trainingComponent}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {filteredExercises.map((exercise) => (
            <Grid item xs={12} sm={6} md={4} key={exercise.name}>
              <Card
                sx={{
                  border: selectedExercises.some((e) => e.name === exercise.name)
                    ? '2px solid #000'
                    : '1px solid #ccc',
                }}
              >
                <CardContent>
                  <Typography variant="h6">{exercise.name}</Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      selectedExercises.some((e) => e.name === exercise.name)
                        ? handleDeselectExercise(exercise)
                        : handleSelectExercise(exercise)
                    }
                  >
                    {selectedExercises.some((e) => e.name === exercise.name)
                      ? 'Deselect'
                      : 'Select'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ExerciseSelectionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  exercises: PropTypes.array.isRequired,
  trainingComponent: PropTypes.string.isRequired, // Add prop type validation
};

export default ExerciseSelectionDialog;
