import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';


import Box from '@mui/material/Box';
import { Dialog, DialogTitle, DialogContent, IconButton } from '@mui/material';

import { Typography, TextField, Snackbar, Tooltip,Alert, Select, Divider, MenuItem, FormControl} from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import InfoIcon from '@mui/icons-material/Info';
import ExerciseSelectionDialog from './ExerciseSelectionDialog';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import { MultiBackend, TouchTransition } from 'dnd-multi-backend';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase-config';

const ItemType = {
  EXERCISE: 'exercise',
};

const DraggableExercise = ({ exercise, cardIndex, exerciseIndex, updateExercise, trainingComponent }) => {
  const [{ isDragging }, drag] = useDrag({
    type: ItemType.EXERCISE,
    item: { exerciseIndex, cardIndex },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const opacity = isDragging ? 0.4 : 1;

  const handleInputChange = (field) => (e) => {
    const updatedExercise = { ...exercise, [field]: e.target.value };
    updateExercise(cardIndex, exerciseIndex, updatedExercise);
  };

  const renderInputs = () => (
    <>
      <Box sx={{ display: 'flex', gap: 0, backgroundColor: 'transparent', alignItems: 'center', '& > *': { margin: 0, padding: 0 } }}>
        <Typography 
          variant="caption" 
          sx={{ 
            position: 'absolute', 
            top: '0px', 
            color: 'grey',
            left: '2px', 
            fontSize: '0.6rem', 
            fontWeight: 'light',
          }}
        >
          {`${cardIndex + 1}${String.fromCharCode(65 + exerciseIndex)}`}
        </Typography>
    

 
  
        <FormControl
  variant="filled"
  size="small"
  sx={{
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
    width: '60px',
    '& .MuiFilledInput-root': {
      padding: 0,
      minHeight: 'auto',
      height: '25px',
      backgroundColor: 'transparent',
      borderBottom: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiSelect-select': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      height: '25px',
      width: '60px',
      fontSize: '0.75rem',
      color: 'lightgrey',
      backgroundColor: 'transparent',
      borderBottom: 'none',
      textAlign: 'center',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  }}
>
  <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
    <Typography variant="body2" sx={{ marginRight: 1, fontSize: '0.6rem', color: '#303E4A' }}>
      R
    </Typography>
    <Tooltip title="Number of Sets" arrow>
      <Select
        value={exercise.volumeType || 'Set'}
        onChange={handleInputChange('volumeType')}
        sx={{
          fontSize: '0.6rem',
          width: '60px',
          color: 'grey',
          textAlign: 'center',
          backgroundColor: 'transparent',
          borderBottom: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
        }}
        disableUnderline
      >
        <MenuItem value="Set" sx={{ fontSize: '0.6rem', color: 'grey', textAlign: 'center' }}>
          Set
        </MenuItem>
      </Select>
    </Tooltip>
  </Box>

  {/* Conditionally apply styling based on prescription type */}
  {exercise.prescriptionType === 'EL UNI' || !exercise.prescriptionType ? (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
        <Typography variant="body2" sx={{ marginRight: 1, fontSize: '0.6rem', color: 'grey' }}>
          R
        </Typography>
        <Select
          value={exercise.volumeValue1 || '3'}
          onChange={handleInputChange('volumeValue1')}
          size="small"
          disableUnderline
          IconComponent={() => null}
          sx={{
            fontSize: '0.75rem',
            width: '60px',
            '& .MuiInputBase-root': {
              borderBottom: 'none',
            },
            '& .MuiInputBase-input': {
              padding: '2px',
              height: '20px',
              textAlign: 'center',
              backgroundColor: 'transparent',
            },
            height: '25px',
          }}
        >
          {Array.from({ length: 10 }, (_, i) => (
            <MenuItem key={i + 1} value={i + 1} sx={{ fontSize: '0.75rem' }}>
              {i + 1}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {exercise.prescriptionType === 'EL UNI' && (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
          <Typography variant="body2" sx={{ marginRight: 1, fontSize: '0.6rem', color: 'grey' }}>
            L
          </Typography>
          <Select
            value={exercise.volumeValue2 || '3'}
            onChange={handleInputChange('volumeValue2')}
            size="small"
            disableUnderline
            IconComponent={() => null}
            sx={{
              fontSize: '0.75rem',
              width: '60px',
              '& .MuiInputBase-root': {
                borderBottom: 'none',
              },
              '& .MuiInputBase-input': {
                padding: '2px',
                height: '20px',
                textAlign: 'center',
                backgroundColor: 'transparent',
              },
              height: '25px',
            }}
          >
            {Array.from({ length: 10 }, (_, i) => (
              <MenuItem key={i + 1} value={i + 1} sx={{ fontSize: '0.75rem' }}>
                {i + 1}
              </MenuItem>
            ))}
          </Select>
        </Box>
      )}
    </>
  ) : (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: '0 0 25.5px 0' }}>
      <Typography variant="body2" sx={{ marginRight: 1, fontSize: '0.6rem', color: '#303E4A' }}>
        T
      </Typography>
      <Select
        value={exercise.volumeValue1 || '3'}
        onChange={handleInputChange('volumeValue1')}
        size="small"
        disableUnderline
        IconComponent={() => null}
        sx={{
          fontSize: '0.75rem',
          width: '60px',
          '& .MuiInputBase-root': {
            borderBottom: 'none',
          },
          '& .MuiInputBase-input': {
            padding: '2px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: 'transparent',
          },
          height: '25px',
        }}
      >
        {Array.from({ length: 10 }, (_, i) => (
          <MenuItem key={i + 1} value={i + 1} sx={{ fontSize: '0.75rem' }}>
            {i + 1}
          </MenuItem>
        ))}
      </Select>
    </Box>
  )}

  <Divider sx={{ width: '100%', backgroundColor: '#1A2B3C', height: '3px', marginTop: '5px' }} />
</FormControl>





<FormControl
  variant="filled"
  size="small"
  sx={{
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
    width: '60px',
    '& .MuiFilledInput-root': {
      padding: 0,
      minHeight: 'auto',
      height: '25px',
      backgroundColor: 'transparent',
      borderBottom: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiSelect-select': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      height: '25px',
      width: '60px',
      fontSize: '0.75rem',
      color: 'lightgrey',
      backgroundColor: 'transparent',
      borderBottom: 'none',
      textAlign: 'center',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  }}
>
  <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
    <Select
      value={exercise.volume2Type || 'VO2'}
      onChange={handleInputChange('volume2Type')}
      sx={{
        fontSize: '0.75rem',
        width: '60px',
        color: 'lightgrey',
        textAlign: 'center',
        backgroundColor: 'transparent',
        borderBottom: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '25px',
      }}
      disableUnderline
    >
      <MenuItem value="VO2" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>VO2</MenuItem>
      <MenuItem value="REP" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>REP</MenuItem>
      <MenuItem value="Time" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Time</MenuItem>
      <MenuItem value="Dist" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Dist</MenuItem>
    </Select>
  </Box>

  {/* Default to 'EL BIL' if no prescriptionType is provided */}
  {exercise.prescriptionType === 'EL UNI' || !exercise.prescriptionType ? (
    <>
      {exercise.volume2Type === 'REP' ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
            <Select
              value={exercise.volume2Value1 || '10'}
              onChange={handleInputChange('volume2Value1')}
              size="small"
              disableUnderline
              IconComponent={() => null}
              sx={{ 
                fontSize: '0.75rem', 
                width: '60px',
                '& .MuiInputBase-root': {
                  borderBottom: 'none',
                },
                '& .MuiInputBase-input': {
                  padding: '2px',
                  height: '20px',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                },
                height: '25px',
              }}
            >
              {Array.from({ length: 20 }, (_, i) => (
                <MenuItem key={i + 1} value={i + 1} sx={{ fontSize: '0.75rem' }}>{i + 1}</MenuItem>
              ))}
            </Select>
          </Box>
          {exercise.prescriptionType === 'EL UNI' && (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
              <Select
                value={exercise.volume2Value2 || '10'}
                onChange={handleInputChange('volume2Value2')}
                size="small"
                disableUnderline
                IconComponent={() => null}
                sx={{ 
                  fontSize: '0.75rem', 
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                  },
                  height: '25px',
                }}
              >
                {Array.from({ length: 20 }, (_, i) => (
                  <MenuItem key={i + 1} value={i + 1} sx={{ fontSize: '0.75rem' }}>{i + 1}</MenuItem>
                ))}
              </Select>
            </Box>
          )}
        </>
      ) : (exercise.volume2Type === 'Time' || exercise.volume2Type === 'Dist') ? (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
            <Select
              value={exercise.volume2Value1 || '1'}
              onChange={handleInputChange('volume2Value1')}
              size="small"
              disableUnderline
              IconComponent={() => null}
              sx={{ 
                fontSize: '0.75rem', 
                width: '60px',
                '& .MuiInputBase-root': {
                  borderBottom: 'none',
                },
                '& .MuiInputBase-input': {
                  padding: '2px',
                  height: '20px',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                },
                height: '25px',
              }}
            >
               {exercise.volume2Type === 'Time' ? (
        Array.from({ length: 36 }, (_, i) => (i + 1) * 5).map(value => (
          <MenuItem key={value} value={`${value}s`} sx={{ fontSize: '0.75rem' }}>
            {value}s
          </MenuItem>
        ))
      ) : (
        Array.from({ length: 36 }, (_, i) => (i + 1) * 5).map(value => (
          <MenuItem key={value} value={`${value}m`} sx={{ fontSize: '0.75rem' }}>
            {value}m
          </MenuItem>
        ))
      )}

            </Select>
          </Box>
          {exercise.prescriptionType === 'EL UNI' && (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
              <Select
                value={exercise.volume2Value2 || '1'}
                onChange={handleInputChange('volume2Value2')}
                size="small"
                disableUnderline
                IconComponent={() => null}
                sx={{ 
                  fontSize: '0.75rem', 
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                  },
                  height: '25px',
                }}
              >
                 {exercise.volume2Type === 'Time' ? (
        Array.from({ length: 36 }, (_, i) => (i + 1) * 5).map(value => (
          <MenuItem key={value} value={`${value}s`} sx={{ fontSize: '0.75rem' }}>
            {value}s
          </MenuItem>
        ))
      ) : (
        Array.from({ length: 36 }, (_, i) => (i + 1) * 5).map(value => (
          <MenuItem key={value} value={`${value}m`} sx={{ fontSize: '0.75rem' }}>
            {value}m
          </MenuItem>
        ))
      )}

              </Select>
            </Box>
          )}
        </>
      ) : (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
            <TextField
              type="text"
              value={exercise.volume2Value1 || ''}
              onChange={handleInputChange('volume2Value1')}
              size="small"
              sx={{ 
                mt: 0,
                width: '60px',
                '& .MuiInputBase-root': {
                  borderBottom: 'none',
                },
                '& .MuiInputBase-input': {
                  padding: '2px',
                  height: '20px',
                  textAlign: 'center',
                  backgroundColor: 'transparent',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                },
                height: '25px',
              }}
              InputLabelProps={{ shrink: true }}
              variant="filled"
            />
          </Box>
          {exercise.prescriptionType === 'EL UNI' && (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
              <TextField
                type="text"
                value={exercise.volume2Value2 || ''}
                onChange={handleInputChange('volume2Value2')}
                size="small"
                sx={{ 
                  mt: 0,
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  height: '25px',
                }}
                InputLabelProps={{ shrink: true }}
                variant="filled"
              />
            </Box>
          )}
        </>
      )}
    </>
  ) : (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0, marginBottom: '25.5px' }}>
  {exercise.volume2Type === 'REP' ? (
    <Select
      value={exercise.volume2Value1 || '10'}
      onChange={handleInputChange('volume2Value1')}
      size="small"
      disableUnderline
      IconComponent={() => null}
      sx={{ 
        fontSize: '0.75rem', 
        width: '60px',
        '& .MuiInputBase-root': {
          borderBottom: 'none',
        },
        '& .MuiInputBase-input': {
          padding: '2px',
          height: '20px',
          textAlign: 'center',
          backgroundColor: 'transparent',
        },
        height: '25px',
      }}
    >
      {Array.from({ length: 20 }, (_, i) => i + 1).map(value => (
        <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
          {value}
        </MenuItem>
      ))}
    </Select>
  ) : (exercise.volume2Type === 'Time' || exercise.volume2Type === 'Dist') ? (
    <Select
      value={exercise.volume2Value1 || '60s'}
      onChange={handleInputChange('volume2Value1')}
      size="small"
      disableUnderline
      IconComponent={() => null}
      sx={{ 
        fontSize: '0.75rem', 
        width: '60px',
        '& .MuiInputBase-root': {
          borderBottom: 'none',
        },
        '& .MuiInputBase-input': {
          padding: '2px',
          height: '20px',
          textAlign: 'center',
          backgroundColor: 'transparent',
        },
        height: '25px',
      }}
    >
      {exercise.volume2Type === 'Time' ? (
        Array.from({ length: 36 }, (_, i) => (i + 1) * 5).map(value => (
          <MenuItem key={value} value={`${value}s`} sx={{ fontSize: '0.75rem' }}>
            {value}s
          </MenuItem>
        ))
      ) : (
        Array.from({ length: 36 }, (_, i) => (i + 1) * 5).map(value => (
          <MenuItem key={value} value={`${value}m`} sx={{ fontSize: '0.75rem' }}>
            {value}m
          </MenuItem>
        ))
      )}
    </Select>
  ) : (
    <TextField
      type="text"
      value={exercise.volume2Value1 || '--'}
      onChange={handleInputChange('volume2Value1')}
      size="small"
      sx={{ 
        mt: 0,
        width: '60px',
        '& .MuiInputBase-root': {
          borderBottom: 'none',
        },
        '& .MuiInputBase-input': {
          padding: '2px',
          height: '20px',
          textAlign: 'center',
          backgroundColor: 'transparent',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        height: '25px',
      }}
      InputLabelProps={{ shrink: true }}
      variant="filled"
    />
  )}
</Box>

    </>
  )}
  <Divider sx={{ width: '100%', backgroundColor: '#1A2B3C', height: '3px', marginTop: '5px' }} />
</FormControl>







{trainingComponent !== 'Football' && (
<FormControl
    variant="filled"
    size="small"
    sx={{
      backgroundColor: 'transparent',
      margin: 0,
      padding: 0,
      width: '60px',
      '& .MuiFilledInput-root': {
        padding: 0,
        minHeight: 'auto',
        height: '25px',
        backgroundColor: 'transparent',
        borderBottom: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .MuiSelect-select': {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        height: '25px',
        width: '60px',
        fontSize: '0.75rem',
        color: 'lightgrey',
        backgroundColor: 'transparent',
        borderBottom: 'none',
        textAlign: 'center',
      },
      '& .MuiSelect-icon': {
        display: 'none',
      },
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
      <Select
        value={exercise.intensityType || 'INT'}
        onChange={handleInputChange('intensityType')}
        sx={{
          fontSize: '0.75rem',
          width: '60px',
          color: 'lightgrey',
          textAlign: 'center',
          backgroundColor: 'transparent',
          borderBottom: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
        }}
        disableUnderline
      >
        <MenuItem value="INT" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>INT</MenuItem>
        {trainingComponent !== 'Football' && (
        <MenuItem value="Kg" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Kg</MenuItem>
        )}
        <MenuItem value="RM" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>RM</MenuItem>
        <MenuItem value="BW%" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>BW%</MenuItem>
      </Select>
    </Box>

    {exercise.prescriptionType === 'EL UNI' || !exercise.prescriptionType ? (
      <>
        {exercise.intensityType === 'Kg' ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
              <Select
                value={exercise.intensityValue1 || '1'}
                onChange={handleInputChange('intensityValue1')}
                size="small"
                disableUnderline
                IconComponent={() => null}
                sx={{
                  fontSize: '0.75rem',
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                  },
                  height: '25px',
                }}
              >
                {Array.from({ length: 100 }, (_, i) => (i + 1) * 2.5).map(value => (
                  <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </Box>
            {exercise.prescriptionType === 'EL UNI' && (
              <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
                <Select
                  value={exercise.intensityValue2 || '1'}
                  onChange={handleInputChange('intensityValue2')}
                  size="small"
                  disableUnderline
                  IconComponent={() => null}
                  sx={{
                    fontSize: '0.75rem',
                    width: '60px',
                    '& .MuiInputBase-root': {
                      borderBottom: 'none',
                    },
                    '& .MuiInputBase-input': {
                      padding: '2px',
                      height: '20px',
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                    },
                    height: '25px',
                  }}
                >
                  {Array.from({ length: 100 }, (_, i) => (i + 1) * 2.5).map(value => (
                    <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                      {value}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            )}
          </>
        ) : (exercise.intensityType === 'RM' || exercise.intensityType === 'BW%') ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
              <Select
                value={exercise.intensityValue1 || '1'}
                onChange={handleInputChange('intensityValue1')}
                size="small"
                disableUnderline
                IconComponent={() => null}
                sx={{
                  fontSize: '0.75rem',
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                  },
                  height: '25px',
                }}
              >
                {exercise.intensityType === 'RM' ? (
                  Array.from({ length: 15 }, (_, i) => i + 1).map(value => (
                    <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                      {value}RM
                    </MenuItem>
                  ))
                ) : (
                  Array.from({ length: 25 }, (_, i) => (i + 1) * 10).map(value => (
                    <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                      {value}%
                    </MenuItem>
                  ))
                )}
              </Select>
            </Box>
            {exercise.prescriptionType === 'EL UNI' && (
              <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
                <Select
                  value={exercise.intensityValue2 || '1'}
                  onChange={handleInputChange('intensityValue2')}
                  size="small"
                  disableUnderline
                  IconComponent={() => null}
                  sx={{
                    fontSize: '0.75rem',
                    width: '60px',
                    '& .MuiInputBase-root': {
                      borderBottom: 'none',
                    },
                    '& .MuiInputBase-input': {
                      padding: '2px',
                      height: '20px',
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                    },
                    height: '25px',
                  }}
                >
                  {exercise.intensityType === 'RM' ? (
                    Array.from({ length: 15 }, (_, i) => i + 1).map(value => (
                      <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                        {value}RM
                      </MenuItem>
                    ))
                  ) : (
                    Array.from({ length: 25 }, (_, i) => (i + 1) * 10).map(value => (
                      <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                        {value}%
                      </MenuItem>
                    ))
                  )}
                </Select>
              </Box>
            )}
          </>
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
              <TextField
                type="text"
                value={exercise.intensityValue1 || ''}
                onChange={handleInputChange('intensityValue1')}
                size="small"
                sx={{
                  mt: 0,
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  height: '25px',
                }}
                InputLabelProps={{ shrink: true }}
                variant="filled"
              />
            </Box>
            {exercise.prescriptionType === 'EL UNI' && (
              <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
                <TextField
                  type="text"
                  value={exercise.intensityValue2 || ''}
                  onChange={handleInputChange('intensityValue2')}
                  size="small"
                  sx={{
                    mt: 0,
                    width: '60px',
                    '& .MuiInputBase-root': {
                      borderBottom: 'none',
                    },
                    '& .MuiInputBase-input': {
                      padding: '2px',
                      height: '20px',
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                    height: '25px',
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                />
              </Box>
            )}
          </>
        )}
      </>
    ) : (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0, marginBottom: '25.5px' }}>
          {exercise.intensityType === 'Kg' ? (
            <>
              <Select
                value={exercise.intensityValue1 || '1'}
                onChange={handleInputChange('intensityValue1')}
                size="small"
                disableUnderline
                IconComponent={() => null}
                sx={{
                  fontSize: '0.75rem',
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                  },
                  height: '25px',
                }}
              >
                {Array.from({ length: 100 }, (_, i) => (i + 1) * 2.5).map(value => (
                  <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                    {value}
                  </MenuItem>
                ))}
              </Select>
            </>
          ) : (exercise.intensityType === 'RM' || exercise.intensityType === 'BW%') ? (
            <>
              <Select
                value={exercise.intensityValue1 || '1'}
                onChange={handleInputChange('intensityValue1')}
                size="small"
                disableUnderline
                IconComponent={() => null}
                sx={{
                  fontSize: '0.75rem',
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                  },
                  height: '25px',
                }}
              >
                {exercise.intensityType === 'RM' ? (
                  Array.from({ length: 15 }, (_, i) => i + 1).map(value => (
                    <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                      {value}RM
                    </MenuItem>
                  ))
                ) : (
                  Array.from({ length: 25 }, (_, i) => (i + 1) * 10).map(value => (
                    <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                      {value}%
                    </MenuItem>
                  ))
                )}
              </Select>
            </>
          ) : (
            <>
              <TextField
                type="text"
                value={exercise.intensityValue1 || ''}
                onChange={handleInputChange('intensityValue1')}
                size="small"
                sx={{
                  mt: 0,
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  height: '25px',
                }}
                InputLabelProps={{ shrink: true }}
                variant="filled"
              />
            </>
          )}
        </Box>
      </>
    )}
    <Divider sx={{ width: '100%', backgroundColor: '#1A2B3C', height: '3px', marginTop: '5px' }} />
  </FormControl>
)}


  {trainingComponent !== 'Football' && (
  <FormControl
    variant="filled"
    size="small"
    sx={{
      backgroundColor: 'transparent',
      margin: 0,
      padding: 0,
      width: '60px',
      '& .MuiFilledInput-root': {
        padding: 0,
        minHeight: 'auto',
        height: '25px',
        backgroundColor: 'transparent',
        borderBottom: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      '& .MuiSelect-select': {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        boxSizing: 'border-box',
        height: '25px',
        width: '60px',
        fontSize: '0.75rem',
        color: 'lightgrey',
        backgroundColor: 'transparent',
        borderBottom: 'none',
        textAlign: 'center',
      },
      '& .MuiSelect-icon': {
        display: 'none',
      },
    }}
  >
    <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
      <Select
        value={exercise.tempoType || 'TEM'}
        onChange={handleInputChange('tempoType')}
        sx={{
          fontSize: '0.75rem',
          width: '60px',
          color: 'lightgrey',
          textAlign: 'center',
          backgroundColor: 'transparent',
          borderBottom: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
        }}
        disableUnderline
      >
        <MenuItem value="TEM" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>TEM</MenuItem>
        <MenuItem value="Tem" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Tem</MenuItem>
        <MenuItem value="Effort" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Effort</MenuItem>
        <MenuItem value="Con" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Con</MenuItem>
      </Select>
    </Box>

    {exercise.prescriptionType === 'EL UNI' || !exercise.prescriptionType ? (
      <>
        {exercise.tempoType === 'Tem' ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
              <Select
                value={exercise.tempoValue1 || '3:0:1'}
                onChange={handleInputChange('tempoValue1')}
                size="small"
                disableUnderline
                IconComponent={() => null}
                sx={{
                  fontSize: '0.75rem',
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                  },
                  height: '25px',
                }}
              >
               <MenuItem value="0:0:0" sx={{ fontSize: '0.75rem' }}>0:0:0</MenuItem>
                  <MenuItem value="1:0:1" sx={{ fontSize: '0.75rem' }}>1:0:1</MenuItem>
                  <MenuItem value="2:0:1" sx={{ fontSize: '0.75rem' }}>2:0:1</MenuItem>
                  <MenuItem value="1:1:1" sx={{ fontSize: '0.75rem' }}>1:1:1</MenuItem>
                  <MenuItem value="1:2:1" sx={{ fontSize: '0.75rem' }}>1:2:1</MenuItem>
                  <MenuItem value="2:0:1" sx={{ fontSize: '0.75rem' }}>2:0:1</MenuItem>
                  <MenuItem value="3:0:1" sx={{ fontSize: '0.75rem' }}>3:0:1</MenuItem>
                
              </Select>
            </Box>
            {exercise.prescriptionType === 'EL UNI' && (
              <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
                <Select
                  value={exercise.tempoValue2 || '3:0:1'}
                  onChange={handleInputChange('tempoValue2')}
                  size="small"
                  disableUnderline
                  IconComponent={() => null}
                  sx={{
                    fontSize: '0.75rem',
                    width: '60px',
                    '& .MuiInputBase-root': {
                      borderBottom: 'none',
                    },
                    '& .MuiInputBase-input': {
                      padding: '2px',
                      height: '20px',
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                    },
                    height: '25px',
                  }}
                >
                  <MenuItem value="0:0:0" sx={{ fontSize: '0.75rem' }}>0:0:0</MenuItem>
                  <MenuItem value="1:0:1" sx={{ fontSize: '0.75rem' }}>1:0:1</MenuItem>
                  <MenuItem value="2:0:1" sx={{ fontSize: '0.75rem' }}>2:0:1</MenuItem>
                  <MenuItem value="1:1:1" sx={{ fontSize: '0.75rem' }}>1:1:1</MenuItem>
                  <MenuItem value="1:2:1" sx={{ fontSize: '0.75rem' }}>1:2:1</MenuItem>
                  <MenuItem value="2:0:1" sx={{ fontSize: '0.75rem' }}>2:0:1</MenuItem>
                  <MenuItem value="3:0:1" sx={{ fontSize: '0.75rem' }}>3:0:1</MenuItem>
                </Select>
              </Box>
            )}
          </>
        ) : (exercise.tempoType === 'Effort' || exercise.tempoType === 'Con') ? (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
              <Select
                value={exercise.tempoValue1 || 'Mode'}
                onChange={handleInputChange('tempoValue1')}
                size="small"
                disableUnderline
                IconComponent={() => null}
                sx={{
                  fontSize: '0.75rem',
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                  },
                  height: '25px',
                }}
              >
                {exercise.tempoType === 'Effort' ? (
                   <>
                    <MenuItem value="Effort" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Effort</MenuItem>
                    <MenuItem value="Mode" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Mode</MenuItem>

                    </> 
                  
                ) : (
                  <>
                  <MenuItem value="Effort" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Effort</MenuItem>
                  <MenuItem value="Mode" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Mode</MenuItem>

                  </> 
                  
                )}
              </Select>
            </Box>
            {exercise.prescriptionType === 'EL UNI' && (
              <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
                <Select
                  value={exercise.tempoValue2 || '1'}
                  onChange={handleInputChange('tempoValue2')}
                  size="small"
                  disableUnderline
                  IconComponent={() => null}
                  sx={{
                    fontSize: '0.75rem',
                    width: '60px',
                    '& .MuiInputBase-root': {
                      borderBottom: 'none',
                    },
                    '& .MuiInputBase-input': {
                      padding: '2px',
                      height: '20px',
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                    },
                    height: '25px',
                  }}
                >
                  {exercise.tempoType === 'Effort' ? (
                   <>
                   <MenuItem value="Effort" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Effort</MenuItem>
                   <MenuItem value="Mode" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Mode</MenuItem>

                   </> 
                  ) : (
                    <>
                    <MenuItem value="Effort" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Effort</MenuItem>
                    <MenuItem value="Mode" sx={{ fontSize: '0.75rem', color: 'lightgrey', textAlign: 'center' }}>Mode</MenuItem>

                    </> 
                  )}
                </Select>
              </Box>
            )}
          </>
        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
              <TextField
                type="text"
                value={exercise.tempoValue1 || ''}
                onChange={handleInputChange('tempoValue1')}
                size="small"
                sx={{
                  mt: 0,
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  height: '25px',
                }}
                InputLabelProps={{ shrink: true }}
                variant="filled"
              />
            </Box>
            {exercise.prescriptionType === 'EL UNI' && (
              <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
                <TextField
                  type="text"
                  value={exercise.tempoValue2 || ''}
                  onChange={handleInputChange('tempoValue2')}
                  size="small"
                  sx={{
                    mt: 0,
                    width: '60px',
                    '& .MuiInputBase-root': {
                      borderBottom: 'none',
                    },
                    '& .MuiInputBase-input': {
                      padding: '2px',
                      height: '20px',
                      textAlign: 'center',
                      backgroundColor: 'transparent',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    },
                    height: '25px',
                  }}
                  InputLabelProps={{ shrink: true }}
                  variant="filled"
                />
              </Box>
            )}
          </>
        )}
      </>
    ) : (
      <>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0, marginBottom: '25.5px' }}>
          {exercise.tempoType === 'Tem' ? (
            <>
              <Select
                value={exercise.tempoValue1 || '1'}
                onChange={handleInputChange('tempoValue1')}
                size="small"
                disableUnderline
                IconComponent={() => null}
                sx={{
                  fontSize: '0.75rem',
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                  },
                  height: '25px',
                }}
              >
                <MenuItem value="0:0:0" sx={{ fontSize: '0.75rem' }}>0:0:0</MenuItem>
                  <MenuItem value="1:0:1" sx={{ fontSize: '0.75rem' }}>1:0:1</MenuItem>
                  <MenuItem value="2:0:1" sx={{ fontSize: '0.75rem' }}>2:0:1</MenuItem>
                  <MenuItem value="1:1:1" sx={{ fontSize: '0.75rem' }}>1:1:1</MenuItem>
                  <MenuItem value="1:2:1" sx={{ fontSize: '0.75rem' }}>1:2:1</MenuItem>
                  <MenuItem value="2:0:1" sx={{ fontSize: '0.75rem' }}>2:0:1</MenuItem>
                  <MenuItem value="3:0:1" sx={{ fontSize: '0.75rem' }}>3:0:1</MenuItem>
              </Select>
            </>
          ) : (exercise.tempoType === 'Effort' || exercise.tempoType === 'Con') ? (
            <>
              <Select
                value={exercise.tempoValue1 || ''}
                onChange={handleInputChange('tempoValue1')}
                size="small"
                disableUnderline
                IconComponent={() => null}
                sx={{
                  fontSize: '0.75rem',
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                  },
                  height: '25px',
                }}
              >
                {exercise.tempoType === 'Effort' ? (
                  <>
                    <MenuItem value="Easy" sx={{ fontSize: '0.75rem', color: 'green', textAlign: 'center' }}>Easy</MenuItem>
                    <MenuItem value="Moderate" sx={{ fontSize: '0.75rem', color: 'orange', textAlign: 'center' }}>Moderate</MenuItem>
                    <MenuItem value="Hard" sx={{ fontSize: '0.75rem', color: 'red', textAlign: 'center' }}>Hard</MenuItem>
                    <MenuItem value="AllOut" sx={{ fontSize: '0.75rem', color: 'red', textAlign: 'center' }}>AllOut</MenuItem>

                  </>
                ) : (
                  Array.from({ length: 15 }, (_, i) => i + 1).map(value => (
                    <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                      {value}Effort2
                    </MenuItem>
                  ))
                )}
              </Select>
            </>
          ) : (
            <>
              <TextField
                type="text"
                value={exercise.tempoValue1 || ''}
                onChange={handleInputChange('tempoValue1')}
                size="small"
                sx={{
                  mt: 0,
                  width: '60px',
                  '& .MuiInputBase-root': {
                    borderBottom: 'none',
                  },
                  '& .MuiInputBase-input': {
                    padding: '2px',
                    height: '20px',
                    textAlign: 'center',
                    backgroundColor: 'transparent',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  },
                  height: '25px',
                }}
                InputLabelProps={{ shrink: true }}
                variant="filled"
              />
            </>
          )}
        </Box>
      </>
    )}
    <Divider sx={{ width: '100%', backgroundColor: '#1A2B3C', height: '3px', marginTop: '5px' }} />
  </FormControl>
)}



<FormControl
  variant="filled"
  size="small"
  sx={{
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
    width: '60px',
    '& .MuiFilledInput-root': {
      padding: 0,
      minHeight: 'auto',
      height: '25px',
      backgroundColor: 'transparent',
      borderBottom: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    '& .MuiSelect-select': {
      padding: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      height: '25px',
      width: '60px',
      fontSize: '0.75rem',
      color: 'lightgrey',
      backgroundColor: 'transparent',
      borderBottom: 'none',
      textAlign: 'center',
    },
    '& .MuiSelect-icon': {
      display: 'none',
    },
  }}
>
  <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
   
    <Tooltip title="Recovery" arrow>
      <Select
        value={exercise.recoveryType || 'Rec'}
        onChange={handleInputChange('recoveryType')}
        sx={{
          fontSize: '0.6rem',
          width: '60px',
          color: 'grey',
          textAlign: 'center',
          backgroundColor: 'transparent',
          borderBottom: 'none',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '25px',
        }}
        disableUnderline
      >
        <MenuItem value="Rec" sx={{ fontSize: '0.6rem', color: 'grey', textAlign: 'center' }}>
          Rec
        </MenuItem>
      </Select>
    </Tooltip>
  </Box>

  {/* Conditionally apply styling based on prescription type */}
  {exercise.prescriptionType === 'EL UNI' || !exercise.prescriptionType ? (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
       
        <Select
          value={exercise.recoveryValue1 || '60'}
          onChange={handleInputChange('recoveryValue1')}
          size="small"
          disableUnderline
          IconComponent={() => null}
          sx={{
            fontSize: '0.75rem',
            width: '60px',
            '& .MuiInputBase-root': {
              borderBottom: 'none',
            },
            '& .MuiInputBase-input': {
              padding: '2px',
              height: '20px',
              textAlign: 'center',
              backgroundColor: 'transparent',
            },
            height: '25px',
          }}
        >
          {Array.from({ length: (180 - 5) / 5 + 1 }, (_, i) => {
              const value = 5 + i * 5;
              return (
                <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
                  {value} s
                </MenuItem>
              );
            })}

        </Select>
      </Box>
      {exercise.prescriptionType === 'EL UNI' && (
        <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: 0 }}>
         
          <Select
            value={exercise.recoveryValue2 || '60'}
            onChange={handleInputChange('recoveryValue2')}
            size="small"
            disableUnderline
            IconComponent={() => null}
            sx={{
              fontSize: '0.75rem',
              width: '60px',
              '& .MuiInputBase-root': {
                borderBottom: 'none',
              },
              '& .MuiInputBase-input': {
                padding: '2px',
                height: '20px',
                textAlign: 'center',
                backgroundColor: 'transparent',
              },
              height: '25px',
            }}
          >
           {Array.from({ length: 36 }, (_, i) => (i + 1) * 5).map(value => (
            <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
              {value}s
            </MenuItem>
          ))}

          </Select>
        </Box>
      )}
    </>
  ) : (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '25px', margin: '0 0 25.5px 0' }}>
      
      <Select
        value={exercise.recoveryValue1 || '3'}
        onChange={handleInputChange('recoveryValue1')}
        size="small"
        disableUnderline
        IconComponent={() => null}
        sx={{
          fontSize: '0.75rem',
          width: '60px',
          '& .MuiInputBase-root': {
            borderBottom: 'none',
          },
          '& .MuiInputBase-input': {
            padding: '2px',
            height: '20px',
            textAlign: 'center',
            backgroundColor: 'transparent',
          },
          height: '25px',
        }}
      >
       {Array.from({ length: 36 }, (_, i) => (i + 1) * 5).map(value => (
          <MenuItem key={value} value={value} sx={{ fontSize: '0.75rem' }}>
            {value}s
          </MenuItem>
        ))}

      </Select>
    </Box>
  )}

  <Divider sx={{ width: '100%', backgroundColor: '#1A2B3C', height: '3px', marginTop: '5px' }} />
</FormControl>






     



      </Box>
    </>
  );

  return (
    <>
      <Box
        ref={drag}
        sx={{
          width: '100%',
          padding: '0.5px',
          border: '0px solid #000000',
          borderRadius: '4px',
          opacity,
          backgroundColor: 'transparent',
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          boxSizing: 'border-box',
          position: 'relative',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative' }}>
          <Typography 
            variant="body1" 
            sx={{ 
              fontSize: '0.85rem', 
              overflow: 'hidden', 
              fontWeight: 'bold',
              color: 'lightgrey',
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              textTransform: 'uppercase', 
              marginBottom: '0px',
              textAlign: 'center',  // Centering the text horizontally
            }}
          >
            {exercise.name}
          </Typography>
          {trainingComponent === 'Football' && (
            <IconButton 
              onClick={handleDialogOpen} 
              size="small" 
              sx={{ 
                color: 'lightgrey', 
                position: 'absolute', 
                right: 0 
              }}
            >
              <InfoIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
        {renderInputs()}
      </Box>

      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Instruction for {exercise.name}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {exercise.instruction || 'No instruction available.'}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};

DraggableExercise.propTypes = {
  exercise: PropTypes.object.isRequired,
  cardIndex: PropTypes.number.isRequired,
  exerciseIndex: PropTypes.number.isRequired,
  updateExercise: PropTypes.func.isRequired,
  trainingComponent: PropTypes.string.isRequired,
};

const ExerciseDropZone = ({ cardIndex, moveExercise, currentExercise, handleExerciseDialogOpen, isAddNewGroupZone }) => {
  const [{ isOver, canDrop }, drop] = useDrop({
    accept: ItemType.EXERCISE,
    canDrop: () => currentExercise === null || isAddNewGroupZone,
    drop: (item) => {
      if (isAddNewGroupZone) {
        handleExerciseDialogOpen(item);
      } else {
        moveExercise(item.cardIndex, item.exerciseIndex, cardIndex);
      }
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  return (
    <Box
      ref={drop}
      sx={{
        width: '100%',
        padding: currentExercise || isAddNewGroupZone ? '0' : '0px',
        backgroundColor: isOver ? (canDrop ? '#red' : 'red') : 'transparent',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'relative',
        border: currentExercise || isAddNewGroupZone ? 'none' : '1px dashed #ccc',
        borderRadius:'10px',
        minHeight: currentExercise || isAddNewGroupZone ? 'auto' : '30px',
        boxSizing: 'border-box',
        flex: 1,
      }}
    >
      {currentExercise}
      {!currentExercise && !isAddNewGroupZone && (
        <IconButton onClick={handleExerciseDialogOpen} color="primary">
          <AddIcon />
        </IconButton>
      )}
      {isAddNewGroupZone && (
        <Typography variant="body2" color="textSecondary">Drop here to create a new group</Typography>
      )}
    </Box>
  );
};

ExerciseDropZone.propTypes = {
  cardIndex: PropTypes.number.isRequired,
  moveExercise: PropTypes.func.isRequired,
  currentExercise: PropTypes.node,
  handleExerciseDialogOpen: PropTypes.func.isRequired,
  isAddNewGroupZone: PropTypes.bool,
};

ExerciseDropZone.defaultProps = {
  isAddNewGroupZone: false,
};
const AddDrill = ({ trainingComponent, selectedMethod }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [exercises, setExercises] = useState([]);
  const [cards, setCards] = useState([[]]);
  const [openExerciseDialog, setOpenExerciseDialog] = useState(false);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const [selectedExercise, setSelectedExercise] = useState('');

  // Conditionally set default values based on trainingComponent and selectedMethod
  useEffect(() => {
    if (trainingComponent === 'Strength' && selectedMethod === 'Maximal Strength') {
      const initialExercise = {
        volume2Type: 'REP',  // Preselect REP
        volume2Value1: 5,     // Preselect 5 Reps
      };
      setCards(prevCards => {
        const updatedCards = [...prevCards];
        if (updatedCards[0].length === 0) {
          updatedCards[0].push(initialExercise);
        }
        return updatedCards;
      });
    }
  }, [trainingComponent, selectedMethod]);
  useEffect(() => {
    loadExerciseData(); // Corrected function name
  }, []);
  

  const loadExerciseData = async () => {
    try {
      const exercisesCollection = collection(db, 'exercises');
      const exerciseSnapshot = await getDocs(exercisesCollection);
      const exerciseList = exerciseSnapshot.docs.map(doc => ({
        id: doc.id,  // Adding the document ID for reference
        ...doc.data()
      }));
      console.log('Loaded exercises:', exerciseList);  // Debug log
      setExercises(exerciseList);
    } catch (error) {
      console.error('Error loading exercises:', error);
    }
  };
  


  const handleExerciseDialogOpen = (cardIndex) => {
    setCurrentCardIndex(cardIndex);
    setOpenExerciseDialog(true);
  };

  const handleExerciseDialogClose = (newSelectedExercises) => {
    const updatedCards = [...cards];
    newSelectedExercises.forEach((exercise) => {
      updatedCards[currentCardIndex].push(exercise);
    });
    setCards(updatedCards);
    setOpenExerciseDialog(false);
  
    if (newSelectedExercises.length > 0) {
      const selected = newSelectedExercises[0];
  
      if (selected.component === 'Football') {
        setSelectedExercise(selected.name);
      } else {
        setSelectedExercise(''); // Clear if the exercise is not football-related
      }
    }
  };

  const moveExercise = (fromCardIndex, fromExerciseIndex, toCardIndex) => {
    const updatedCards = [...cards];
    const [movedExercise] = updatedCards[fromCardIndex].splice(fromExerciseIndex, 1);
    if (toCardIndex === cards.length) {
      updatedCards.push([movedExercise]);
    } else {
      updatedCards[toCardIndex].push(movedExercise);
    }
    setCards(updatedCards);
  };

  const addCard = (item) => {
    const updatedCards = [...cards];
    const [movedExercise] = updatedCards[item.cardIndex].splice(item.exerciseIndex, 1);
    updatedCards.push([movedExercise]);
    setCards(updatedCards);
  };

  const updateExercise = (cardIndex, exerciseIndex, updatedExercise) => {
    const updatedCards = [...cards];
    updatedCards[cardIndex][exerciseIndex] = updatedExercise;
    setCards(updatedCards);
  };

  useEffect(() => {
    if (trainingComponent && selectedExercise) {
      const exerciseData = exercises.find(
        (exercise) => exercise.component === trainingComponent && exercise.name === selectedExercise
      );
      if (exerciseData) {
        
      }
    }
  }, [trainingComponent, selectedExercise, exercises]);


  useEffect(() => {
    const updateSubsequentExercises = () => {
      const updatedCards = cards.map(card => {
        if (card.length > 0) {
          const firstExercise = card[0];
          return card.map((exercise, index) => {
            if (index === 0) {
              return exercise;
            }
            // Update subsequent exercises based on the first exercise
            return {
              ...exercise,
              volumeType: firstExercise.volumeType,
              volumeValue1: firstExercise.volumeValue1,
              volumeValue2: firstExercise.volumeValue2,
              volume2Type: firstExercise.volume2Type,
              intensityType: firstExercise.intensityType,
              intensityValue1: firstExercise.intensityValue1,
              intensityValue2: firstExercise.intensityValue2,
              tempoType: firstExercise.tempoType,
              tempoValue1: firstExercise.tempoValue1,
              tempoValue2: firstExercise.tempoValue2,
              recoveryType: firstExercise.recoveryType,
              recoveryValue1: firstExercise.recoveryValue1,
              recoveryValue2: firstExercise.recoveryValue2,
            };
          });
        }
        return card;
      });
      setCards(updatedCards);
    };

    updateSubsequentExercises();
  }, [cards]);

  const colors = ['#FF6859', '#FFCF44', '#B15DFF', '#72DEFF', '#1E90FF', '#FF69B4', '#32CD32', '#FFA500'];

  const renderBox = (card, cardIndex) => (
    <Box key={cardIndex} sx={{ mb: 1, mt: 0, width: '100%', ml: 0, pl: 0, backgroundColor: '#1A2B3C', borderRadius: '8px', overflow: 'hidden' }}>
      <Box sx={{ p: 0, backgroundColor: '#1A2B3C' }}>
        <Box sx={{ height: '6px', borderTopRightRadius: '25px', marginBottom: '5px', borderTopLeftRadius: '25px', backgroundColor: colors[cardIndex % colors.length], width: '100%' }} />
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: 'column', 
            gap: 0.5, 
            width: '100%', 
            backgroundColor: 'rgba(255, 255, 255, 0.05)'
          }}
        >
          {card.map((exercise, exerciseIndex) => (
            <Box 
              key={exerciseIndex}
              sx={{ 
                position: 'relative', // Required for pseudo-element positioning
                display: 'flex', 
                flexDirection: 'column', 
                gap: 0.5, 
                width: '100%', 
                borderRadius: '8px',
                overflow: 'hidden',
                '&::after': {
                  content: '""',
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  backgroundImage: `url(${exercise.photoUrl})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  opacity: 0.2, // Adjust the opacity for transparency
                  zIndex: 0
                }
              }}
            >
              <Box sx={{ position: 'relative', zIndex: 1 }}>
                <ExerciseDropZone
                  cardIndex={cardIndex}
                  moveExercise={moveExercise}
                  currentExercise={<DraggableExercise exercise={exercise} cardIndex={cardIndex} exerciseIndex={exerciseIndex} trainingComponent={trainingComponent} updateExercise={updateExercise} />}
                  handleExerciseDialogOpen={() => handleExerciseDialogOpen(cardIndex)}
                />
              </Box>
            </Box>
          ))}
          <Box sx={{ height: '6px', borderBottomRightRadius: '25px', marginBottom: '5px', borderBottomLeftRadius: '25px', backgroundColor: colors[cardIndex % colors.length], width: '100%' }} />
          <ExerciseDropZone
            cardIndex={cardIndex}
            moveExercise={moveExercise}
            handleExerciseDialogOpen={() => handleExerciseDialogOpen(cardIndex)}
            currentExercise={null}
          />
        </Box>
      </Box>
    </Box>
  );

  return (
    <DndProvider backend={MultiBackend} options={{
      backends: [
        {
          backend: HTML5Backend,
          preview: true,
        },
        {
          backend: TouchBackend,
          options: { enableMouseEvents: true },
          preview: true,
          transition: TouchTransition,
        },
      ],
    }}>
      <Box sx={{ bgcolor: '#1A2B3C', px: 1, pt: 0, minHeight: '100vh', color: 'text.primary' }}>
      

        <Box sx={{ mb: 1, display: 'flex', justifyContent: 'flex-end' }}></Box>
        <Box 
  sx={{ 
    display: 'grid', 
    gridTemplateColumns: {
      xs: 'repeat(2, 1fr)', // 2 columns for extra-small screens (mobile)
      sm: 'repeat(4, 1fr)' // 4 columns for small and larger screens (tablets, laptops, desktops)
    }, 
    gap: 1, 
    mb: 1 
  }}
>
  {cards.map((card, cardIndex) => renderBox(card, cardIndex))}
  <Box 
    sx={{ 
      display: 'flex', 
      borderRadius: '10px', 
      alignItems: 'center', 
      justifyContent: 'center', 
      p: 1, 
      border: '1px dashed #ccc', 
      minHeight: '128px', 
      width: '100%' 
    }}
  >
    <ExerciseDropZone
      cardIndex={cards.length}
      moveExercise={moveExercise}
      handleExerciseDialogOpen={addCard}
      isAddNewGroupZone
    />
  </Box>
  
</Box>

        <ExerciseSelectionDialog
          open={openExerciseDialog}
          onClose={handleExerciseDialogClose}
          exercises={exercises}
          trainingComponent={trainingComponent} // Pass the training component to the dialog
        />
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
            Operation successful!
          </Alert>
        </Snackbar>
      </Box>
    </DndProvider>
  );
};


AddDrill.propTypes = {
  trainingComponent: PropTypes.string.isRequired,
};

export default AddDrill;