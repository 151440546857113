// ChatInput.js
import React, { useState } from 'react';
import {TextField, Stack, IconButton } from '@mui/material';
import MicIcon from '@mui/icons-material/Mic';
import SendIcon from '@mui/icons-material/Send';




const ChatInput = ({ onAnswerSubmit }) => {
  const [input, setInput] = useState('');
  const [isListening, setIsListening] = useState(false);

  const handleInputChange = (event) => {
    setInput(event.target.value);
  };

  const submitAnswer = () => {
    onAnswerSubmit(input);
    setInput(''); // Clear input after submission
  };
  const handleVoiceRecognition = () => {
    if ('webkitSpeechRecognition' in window) {
      const recognition = new window.webkitSpeechRecognition();
      recognition.lang = 'en-US';
      recognition.start();
  
      recognition.onstart = () => {
        setIsListening(true);
      };
  
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        // Update setInput to use a callback to ensure state is updated before submitting
        setInput(transcript, () => {
          submitAnswer(); // Call submitAnswer here to ensure it happens after state update
        });
      };
  
      recognition.onerror = (event) => {
        console.error('Voice recognition error', event.error);
        setIsListening(false);
      };
  
      recognition.onend = () => {
        setIsListening(false);
      };
    } else {
      alert('Your browser does not support speech recognition. Please type your answer.');
    }
  };
  

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <TextField
        fullWidth
        variant="outlined"
        placeholder="Type your answer here..."
        value={input}
        onChange={handleInputChange}
        onKeyPress={(e) => e.key === 'Enter' && submitAnswer()}
      />
      <IconButton color="primary" component="span" onClick={handleVoiceRecognition} disabled={isListening}>
        <MicIcon />
      </IconButton>
      <IconButton color="primary" component="span" onClick={submitAnswer}>
        <SendIcon />
      </IconButton>
    </Stack>
  );
};

export default ChatInput;
