import * as React from 'react';
import { BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter'; // Training icon
import BarChartIcon from '@mui/icons-material/BarChart';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ScannerIcon from '@mui/icons-material/Scanner';
import SpaIcon from '@mui/icons-material/Spa'; // Wellness icon

const NavbarAthlete = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const getPathIndex = (path) => {
    switch (path) {
      case '/training':
        return 0;
      case '/chart':
        return 1;
      case '/calendar':
        return 2;
      case '/scan':
        return 3;
      case '/wellness':
        return 4;
      default:
        return 0;
    }
  };

  const [bottomNavValue, setBottomNavValue] = React.useState(getPathIndex(location.pathname));

  const handleBottomNavChange = (event, newValue) => {
    setBottomNavValue(newValue);
    switch (newValue) {
      case 0:
        navigate('/training');
        break;
      case 1:
        navigate('/chart');
        break;
      case 2:
        navigate('/calendar');
        break;
      case 3:
        navigate('/scan');
        break;
      case 4:
        navigate('/wellness');
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ position: 'fixed', bottom: 0, width: '100%', display: 'flex', justifyContent: 'center' }}>
      <BottomNavigation
        value={bottomNavValue}
        onChange={handleBottomNavChange}
        showLabels
        sx={{
          backgroundColor: '#303E4A',
          height: '50px',
          width: { xs: '100%', md: '33%' }, // Centered and one-third of the width on larger screens
          '& .Mui-selected': {
            color: '#1EB980 !important',
          },
        }}
      >
        <BottomNavigationAction
          label="Training"
          icon={<FitnessCenterIcon />}
          sx={{ color: bottomNavValue === 0 ? '#1EB980' : '#fff' }}
        />
        <BottomNavigationAction
          label="Chart"
          icon={<BarChartIcon />}
          sx={{ color: bottomNavValue === 1 ? '#1EB980' : '#fff' }}
        />
        <BottomNavigationAction
          label="Calendar"
          icon={<CalendarTodayIcon />}
          sx={{ color: bottomNavValue === 2 ? '#1EB980' : '#fff' }}
        />
        <BottomNavigationAction
          label="Scan"
          icon={<ScannerIcon />}
          sx={{ color: bottomNavValue === 3 ? '#1EB980' : '#fff' }}
        />
        <BottomNavigationAction
          label="Wellness"
          icon={<SpaIcon />}
          sx={{ color: bottomNavValue === 4 ? '#1EB980' : '#fff' }}
        />
      </BottomNavigation>
    </Box>
  );
};

export default NavbarAthlete;
