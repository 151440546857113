import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  Button,
} from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './firebase-config';

const ExerciseSelectDialog = ({ open, onClose, onSave }) => {
  const [exercises, setExercises] = useState([]);
  const [selectedExercises, setSelectedExercises] = useState([]);

  useEffect(() => {
    const loadExercises = async () => {
      const exercisesSnapshot = await getDocs(collection(db, 'exercises'));
      const exercisesList = exercisesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
      setExercises(exercisesList);
    };

    if (open) {
      loadExercises();
    }
  }, [open]);

  const handleToggle = (exercise) => {
    const currentIndex = selectedExercises.indexOf(exercise);
    const newChecked = [...selectedExercises];

    if (currentIndex === -1) {
      newChecked.push(exercise);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setSelectedExercises(newChecked);
  };

  const handleSave = () => {
    onSave(selectedExercises);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Exercises</DialogTitle>
      <DialogContent>
        <List>
          {exercises.map((exercise) => (
            <ListItem key={exercise.id} button onClick={() => handleToggle(exercise)}>
              <Checkbox checked={selectedExercises.includes(exercise)} />
              <ListItemText primary={exercise.name} />
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExerciseSelectDialog;
