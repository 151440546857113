import * as React from 'react';
import PropTypes from 'prop-types';
import { 
  Box, Typography, TextField, Grid, Button, MenuItem, Select, InputLabel, FormControl, 
  Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Tooltip, Card, CardContent, 
  CardMedia, Checkbox, FormControlLabel 
} from '@mui/material';
import { collection, addDoc, getDocs, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase-config';
import AddIcon from '@mui/icons-material/Add';

const Exercises = ({ loadFirebaseData }) => {
  const [exercises, setExercises] = React.useState([]);
  const [name, setName] = React.useState('');
  const [component, setComponent] = React.useState('');
  const [detail, setDetail] = React.useState('');
  const [subDetail, setSubDetail] = React.useState('');
  const [subSubDetail, setSubSubDetail] = React.useState('');
  const [equipmentCategory, setEquipmentCategory] = React.useState('');
  const [equipment, setEquipment] = React.useState('');
  const [prescriptionType, setPrescriptionType] = React.useState('');
  const [showAddExerciseModal, setShowAddExerciseModal] = React.useState(false);
  const [showEditExerciseModal, setShowEditExerciseModal] = React.useState(false);
  const [filter, setFilter] = React.useState('');
  const [selectedExercise, setSelectedExercise] = React.useState(null);
  const [videoUrl, setVideoUrl] = React.useState('');
  const [photoUrl, setPhotoUrl] = React.useState('');
  const [isWarmingUp, setIsWarmingUp] = React.useState(false);
  const [isCoolDown, setIsCoolDown] = React.useState(false);
  const [isTest, setIsTest] = React.useState(false);

  // New state variables for the added fields
  const [fieldSizeWidth, setFieldSizeWidth] = React.useState('');
  const [fieldSizeLength, setFieldSizeLength] = React.useState('');
  const [numberOfPlayers, setNumberOfPlayers] = React.useState('');
  const [objectives, setObjectives] = React.useState(['', '', '']);
  const [instruction, setInstruction] = React.useState('');
  const [intensity, setIntensity] = React.useState('');

  const [loadingSide, setLoadingSide] = React.useState('');
  const [bodyRegion, setBodyRegion] = React.useState('');
  const [liftPriority, setLiftPriority] = React.useState('');
  const [movementDirection, setMovementDirection] = React.useState('');
  const [diagnosis, setDiagnosis] = React.useState('');
  const [position, setPosition] = React.useState('');
  const [coefficient1, setCoefficient1] = React.useState('');
  const [coefficient2, setCoefficient2] = React.useState('');
  const [coefficient3, setCoefficient3] = React.useState('');
  const [method, setMethod] = React.useState('');
  const [muscle, setMuscle] = React.useState('');
  const [sportTask, setSportTask] = React.useState('');
  const [location, setLocation] = React.useState([]);
  const [sport, setSport] = React.useState([]);

  // Options for the dropdowns
  const prescriptionOptions = ["EL BIL", "EL UNI", "INE BIL", "INE UNI"];
  const loadingSideOptions = ["Unilateral", "Bilateral", "Quadruped", "Monopedal", "Bipedal"];
  const bodyRegionOptions = ["Total Body", "Upper Body", "Lower Body", "Core"];
  const liftPriorityOptions = ["Main Lift", "Supplemental Lift", "Assistance Lift"];
  const movementDirectionOptions = ["Linear", "Lateral"];
  const diagnosisOptions = ["ACL Reconstruction", "Ankle Sprain"];
  const positionOptions = ["Central Defender", "Central Midfielder", "Striker", "Goalkeeper"];
  const methodOptions = ["Hypertrophy", "Power", "Reactive Strength", "Explosive Strength", "Braking Strength"];
  const sportTaskOptions = ["Sprint", "Acceleration", "Duel", "Deceleration", "Heading", "Jump", "Kicking", "Falling Prone", "Falling Supine"];
  const locationOptions = ["Gym", "Pitch", "Home"];
  const sportOptions = ["General", "Football", "Basketball", "Baseball", "Hockey", "Tennis", "Swimming"];
  const intensityOptions = ["Easy", "Moderate", "Hard", "Very Hard"];

  // Dummy muscle options
  const muscleOptions = ["Frontalis", "Orbicularis oculi", "Orbicularis oris", "Masseter", "Temporalis", "Sternocleidomastoid", "Platysma", "Pectoralis major", "Pectoralis minor", "Serratus anterior", "Trapezius", "Rhomboid major", "Rhomboid minor", "Deltoid", "Biceps brachii", "Brachialis", "Triceps brachii", "Brachioradialis", "Flexor carpi radialis", "Extensor carpi radialis", "Flexor carpi ulnaris", "Extensor carpi ulnaris", "Rectus abdominis", "External oblique", "Internal oblique", "Transversus abdominis", "Latissimus dorsi", "Erector spinae", "Gluteus maximus", "Gluteus medius", "Gluteus minimus", "Tensor fasciae latae", "Quadriceps", "Hamstrings", "Adductor group", "Sartorius", "Gastrocnemius", "Soleus", "Tibialis anterior", "Peroneus longus", "Extensor digitorum longus", "Flexor digitorum longus"];

  const exerciseComponents = [
    "Strength",
    "Speed",
    "Endurance",
    "ROM",
    "Coordination",
    "Warming Up",
    "Cool Down",
    "Football"
  ];

  const exerciseDetails = {
    Strength: ["General Strength", "Power", "Corrective", "Agile", "Other"],
    Speed: ["Acceleration", "Deceleration", "Peak Speed", "COD", "Agility"], 
    Endurance: ["Aerobic Capacity", "Aerobic Power", "Anaerobic Capacity", "Anaerobic Power"],
    ROM: ["Flexibility", "Mobility"],
    Coordination: ["Technical work", "Running Mechanics", "Jumping/landing", "Orientation", "Dissociation", "Balance", "Foot Alignment", "Reaction", "Breathing"],
    "Football": ["Small Game", "Applied Game", "Possession", "Finishing", "Technical"]
  };

  const detailSubDetails = {
    Power: ["Olympic Lifts", "Fast General", "Jumping", "Throwing", "Running based", "Reactive", "Explosive", "Braking"],
    Agile: ["Partner Resistance", "Unstable Load", "Mirror Drills"],
    "General Strength": ["Concentric", "Eccentric", "Isometric", "Con-Ecc", "Ecc-Con", "Na"],
    Corrective: ["Ankle", "Knee", "Hip", "Spine", "Shoulder", "Neck"],
    Flexibility: ["Ballistic stretching", "Dynamic stretching", "Active Stretching", "Passive (or relaxed) stretching", "Static stretching", "Isometric stretching", "PNF stretching"],
    Mobility: ["Dynamic Warm Up", "WOD", "Use of Load", "Exercise ROM"],
    Acceleration: ["Generic", "Game Specific", "Hybrid"],
    Generic: ["Resisted", "Assisted"],
    "Game Specific": ["Resisted", "Assisted"],
    Hybrid: ["Resisted", "Assisted"],
    COD: ["Resisted", "Assisted"],
    Agility: ["Resisted", "Assisted"]
  };

  const equipmentCategories = [
    "Cardio Equipment",
    "Strength Training Equipment",
    "Free Weight Accessories",
    "Bodyweight Training Equipment",
    "Functional Training Equipment",
    "Specialty Machines",
    "Miscellaneous Equipment",
    "Football Equipment"  // Added category
  ];

  const equipmentOptions = {
    "Cardio Equipment": [
      "Treadmill",
      "Elliptical Trainer",
      "Stationary Bike (Upright/Recumbent)",
      "Rowing Machine",
      "Stair Climber/Stepper",
      "Spin Bike",
      "Air Bike",
      "Arc Trainer"
    ],
    "Strength Training Equipment": [
      "Barbells",
      "Olympic Barbell",
      "EZ Curl Bar",
      "Dumbbells",
      "Adjustable Dumbbells",
      "Fixed Dumbbells",
      "Kettlebells",
      "Weight Plates",
      "Standard Weight Plates",
      "Bumper Plates",
      "Bench",
      "Flat Bench",
      "Adjustable/Incline Bench",
      "Decline Bench",
      "Power Rack/Squat Rack",
      "Smith Machine",
      "Cable Machine",
      "Functional Trainer",
      "Lat Pulldown Machine",
      "Cable Crossover Machine",
      "Leg Press Machine",
      "Hack Squat Machine",
      "Leg Extension/Leg Curl Machine",
      "Preacher Curl Bench",
      "Chest Press Machine",
      "Shoulder Press Machine",
      "Pec Deck Machine",
      "Seated Row Machine",
      "Abdominal Crunch Machine",
      "Back Extension Bench"
    ],
    "Free Weight Accessories": [
      "Weightlifting Belt",
      "Lifting Straps",
      "Weightlifting Gloves",
      "Foam Roller",
      "Resistance Bands",
      "Medicine Ball",
      "Slam Ball",
      "Battle Ropes",
      "Sandbags"
    ],
    "Bodyweight Training Equipment": [
      "Pull-Up Bar",
      "Dip Station",
      "Gymnastic Rings",
      "Parallettes",
      "Push-Up Bars",
      "Ab Wheel"
    ],
    "Functional Training Equipment": [
      "TRX Suspension Trainer",
      "Plyo Box (Plyometric Box)",
      "Jump Rope",
      "Agility Ladder",
      "BOSU Ball",
      "Stability Ball",
      "Balance Board"
    ],
    "Specialty Machines": [
      "Smith Machine",
      "Glute Ham Developer (GHD)",
      "T-Bar Row Machine",
      "Chest Fly Machine",
      "Seated Calf Raise Machine",
      "Vertical Knee Raise/Dip Station"
    ],
    "Miscellaneous Equipment": [
      "Gym Timer/Clock",
      "Sound System",
      "Flooring Mats",
      "Water Fountain/Cooler",
      "Towel Rack",
      "Mirrors"
    ],
    "Football Equipment": [  // Added options for new category
      "Balls",
      "Marks",
      "Bibs",
      "Sticks",
      "Mannequins"
    ]
  };

  React.useEffect(() => {
    loadExercises();
  }, []);

  const loadExercises = async () => {
    const exercisesSnapshot = await getDocs(collection(db, 'exercises'));
    const exercisesList = exercisesSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
    setExercises(exercisesList);
  };

  const handleExerciseChange = (setter) => (event) => setter(event.target.value);

  const handleObjectiveChange = (index) => (event) => {
    const newObjectives = [...objectives];
    newObjectives[index] = event.target.value;
    setObjectives(newObjectives);
  };

  const resetForm = () => {
    setName('');
    setComponent('');
    setDetail('');
    setSubDetail('');
    setSubSubDetail('');
    setEquipmentCategory('');
    setEquipment('');
    setPrescriptionType('');
    setLoadingSide('');
    setBodyRegion('');
    setLiftPriority('');
    setMovementDirection('');
    setDiagnosis('');
    setPosition('');
    setCoefficient1('');
    setCoefficient2('');
    setCoefficient3('');
    setMethod('');
    setMuscle('');
    setSportTask('');
    setLocation([]);
    setSport([]);
    setVideoUrl('');
    setPhotoUrl('');
    setIsWarmingUp(false);
    setIsCoolDown(false);
    setIsTest(false);
    setFieldSizeWidth('');
    setFieldSizeLength('');
    setNumberOfPlayers('');
    setObjectives(['', '', '']);
    setInstruction('');
    setIntensity('');
  };

  const saveExercise = async () => {
    const exerciseData = { 
      name, component, detail, subDetail, subSubDetail, equipmentCategory, equipment, 
      prescriptionType, loadingSide, bodyRegion, liftPriority, movementDirection, diagnosis, 
      position, coefficient1, coefficient2, coefficient3, method, muscle, sportTask, 
      location, sport, videoUrl, photoUrl, isWarmingUp, isCoolDown, isTest,
      fieldSizeWidth, fieldSizeLength, numberOfPlayers, objectives, instruction, intensity // Added new fields
    };
    
    try {
      if (selectedExercise) {
        await updateDoc(doc(db, 'exercises', selectedExercise.id), exerciseData);
      } else {
        await addDoc(collection(db, 'exercises'), exerciseData);
      }
      
      resetForm();
      loadFirebaseData();
      loadExercises();
      setShowAddExerciseModal(false);
      setShowEditExerciseModal(false);
    } catch (e) {
      console.error('Error saving exercise: ', e);
    }
  };

  const deleteExercise = async () => {
    try {
      if (selectedExercise) {
        await deleteDoc(doc(db, 'exercises', selectedExercise.id));
        resetForm();
        loadFirebaseData();
        loadExercises();
        setShowEditExerciseModal(false);
      }
    } catch (e) {
      console.error('Error deleting exercise: ', e);
    }
  };

  const handleExerciseClick = (exercise) => {
    setSelectedExercise(exercise);
    setName(exercise.name);
    setComponent(exercise.component);
    setDetail(exercise.detail);
    setSubDetail(exercise.subDetail || '');
    setSubSubDetail(exercise.subSubDetail || '');
    setEquipmentCategory(exercise.equipmentCategory || '');
    setEquipment(exercise.equipment || '');
    setPrescriptionType(exercise.prescriptionType || '');
    setLoadingSide(exercise.loadingSide || '');
    setBodyRegion(exercise.bodyRegion || '');
    setLiftPriority(exercise.liftPriority || '');
    setMovementDirection(exercise.movementDirection || '');
    setDiagnosis(exercise.diagnosis || '');
    setPosition(exercise.position || '');
    setCoefficient1(exercise.coefficient1 || '');
    setCoefficient2(exercise.coefficient2 || '');
    setCoefficient3(exercise.coefficient3 || '');
    setMethod(exercise.method || '');
    setMuscle(exercise.muscle || '');
    setSportTask(exercise.sportTask || '');
    setLocation(exercise.location || []);
    setSport(exercise.sport || []);
    setVideoUrl(exercise.videoUrl || '');
    setPhotoUrl(exercise.photoUrl || '');
    setIsWarmingUp(exercise.isWarmingUp || false);
    setIsCoolDown(exercise.isCoolDown || false);
    setIsTest(exercise.isTest || false);
    setFieldSizeWidth(exercise.fieldSizeWidth || '');
    setFieldSizeLength(exercise.fieldSizeLength || '');
    setNumberOfPlayers(exercise.numberOfPlayers || '');
    setObjectives(exercise.objectives || ['', '', '']);
    setInstruction(exercise.instruction || '');
    setIntensity(exercise.intensity || '');
    setShowEditExerciseModal(true);
  };

  const filteredExercises = filter ? exercises.filter(exercise => exercise.component === filter) : exercises;

  const renderValue = (selected) => (Array.isArray(selected) ? selected.join(', ') : '');

  return (
    <Box>
      <Typography variant="h5" align="center" gutterBottom>Exercises</Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 2, mb: 2 }}>
        {exerciseComponents.map((comp, index) => (
          <Tooltip key={index} title={comp}>
            <IconButton 
              onClick={() => setFilter(filter === comp ? '' : comp)} 
              sx={{ 
                borderRadius: '50%', 
                border: '2px solid grey', 
                backgroundColor: filter === comp ? 'rgba(255, 255, 255, 0.2)' : 'transparent',
                '&:hover': {
                  backgroundColor: 'lightgrey'
                }
              }}
            >
              <img src={`/icons/${comp}.svg`} alt={comp} style={{ width: '25px', height: '25px', borderRadius: '50%' }} />
            </IconButton>
          </Tooltip>
        ))}
        <IconButton onClick={() => { setSelectedExercise(null); setShowAddExerciseModal(true); }} sx={{ borderRadius: '50%', border: '2px solid grey', '&:hover': { backgroundColor: 'lightgrey' } }}>
          <AddIcon />
        </IconButton>
      </Box>
      <Grid container spacing={2}>
        {filteredExercises.map((exercise, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card onClick={() => handleExerciseClick(exercise)} sx={{ cursor: 'pointer', boxShadow: selectedExercise?.id === exercise.id ? '0 0 10px blue' : 'none', backgroundColor: '#303E4A', borderRadius: '16px' }}>
              <CardMedia
                component="img"
                height="140"
                image={exercise.photoUrl}
                alt={exercise.name}
              />
              <CardContent>
                <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                  {exercise.name}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {exercise.component} - {exercise.detail}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Dialog open={showAddExerciseModal || showEditExerciseModal} onClose={() => { resetForm(); setShowAddExerciseModal(false); setShowEditExerciseModal(false); }}>
        <DialogTitle>{selectedExercise ? 'Edit Exercise' : 'Add Exercise'}</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Exercise Name"
            variant="outlined"
            value={name}
            onChange={handleExerciseChange(setName)}
            sx={{ mb: 2 }}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Video URL"
                variant="outlined"
                value={videoUrl}
                onChange={handleExerciseChange(setVideoUrl)}
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px dashed grey', height: '200px', cursor: 'pointer' }} onClick={() => document.getElementById('video-upload').click()}>
                {videoUrl ? (
                  <video src={videoUrl} controls style={{ maxWidth: '100%', maxHeight: '100%' }} />
                ) : (
                  <Typography>Click to add video</Typography>
                )}
              </Box>
              <input id="video-upload" type="file" accept="video/*" style={{ display: 'none' }} onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  const videoUrl = URL.createObjectURL(file);
                  setVideoUrl(videoUrl);
                }
              }} />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Photo URL"
                variant="outlined"
                value={photoUrl}
                onChange={handleExerciseChange(setPhotoUrl)}
                sx={{ mb: 2 }}
              />
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', border: '1px dashed grey', height: '200px', cursor: 'pointer' }} onClick={() => document.getElementById('photo-upload').click()}>
                {photoUrl ? (
                  <img src={photoUrl} alt="Exercise" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                ) : (
                  <Typography>Click to add photo</Typography>
                )}
              </Box>
              <input id="photo-upload" type="file" accept="image/*" style={{ display: 'none' }} onChange={(event) => {
                const file = event.target.files[0];
                if (file) {
                  const photoUrl = URL.createObjectURL(file);
                  setPhotoUrl(photoUrl);
                }
              }} />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="component-select-label">Exercise Component</InputLabel>
                <Select
                  labelId="component-select-label"
                  id="component-select"
                  value={component}
                  label="Exercise Component"
                  onChange={handleExerciseChange(setComponent)}
                >
                  {exerciseComponents.map((comp, index) => (
                    <MenuItem key={index} value={comp}>{comp}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isWarmingUp}
                    onChange={(e) => setIsWarmingUp(e.target.checked)}
                    name="isWarmingUp"
                    color="primary"
                  />
                }
                label="Warming Up"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isCoolDown}
                    onChange={(e) => setIsCoolDown(e.target.checked)}
                    name="isCoolDown"
                    color="primary"
                  />
                }
                label="Cool Down"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isTest}
                    onChange={(e) => setIsTest(e.target.checked)}
                    name="isTest"
                    color="primary"
                  />
                }
                label="Test"
              />
            </Grid>
            <Grid item xs={6}>
              {component && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="detail-select-label">Type</InputLabel>
                  <Select
                    labelId="detail-select-label"
                    id="detail-select"
                    value={detail}
                    label="Type"
                    onChange={handleExerciseChange(setDetail)}
                  >
                    {exerciseDetails[component]?.map((det, index) => (
                      <MenuItem key={index} value={det}>{det}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            {detail && detailSubDetails[detail] && (
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="sub-detail-select-label">Sub Type</InputLabel>
                  <Select
                    labelId="sub-detail-select-label"
                    id="sub-detail-select"
                    value={subDetail}
                    label="Sub Type"
                    onChange={handleExerciseChange(setSubDetail)}
                  >
                    {detailSubDetails[detail].map((subDet, index) => (
                      <MenuItem key={index} value={subDet}>{subDet}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
            {subDetail && detailSubDetails[subDetail] && (
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="sub-sub-detail-select-label">Sub-Sub Detail</InputLabel>
                  <Select
                    labelId="sub-sub-detail-select-label"
                    id="sub-sub-detail-select"
                    value={subSubDetail}
                    label="Sub-Sub Detail"
                    onChange={handleExerciseChange(setSubSubDetail)}
                  >
                    {detailSubDetails[subDetail].map((subSubDet, index) => (
                      <MenuItem key={index} value={subSubDet}>{subSubDet}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="equipment-category-select-label">Equipment Category</InputLabel>
                <Select
                  labelId="equipment-category-select-label"
                  id="equipment-category-select"
                  value={equipmentCategory}
                  label="Equipment Category"
                  onChange={handleExerciseChange(setEquipmentCategory)}
                >
                  {equipmentCategories.map((category, index) => (
                    <MenuItem key={index} value={category}>{category}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              {equipmentCategory && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <InputLabel id="equipment-select-label">Equipment</InputLabel>
                  <Select
                    labelId="equipment-select-label"
                    id="equipment-select"
                    value={equipment}
                    label="Equipment"
                    onChange={handleExerciseChange(setEquipment)}
                  >
                    {equipmentOptions[equipmentCategory]?.map((equip, index) => (
                      <MenuItem key={index} value={equip}>{equip}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Field Size Width (m)"
                variant="outlined"
                value={fieldSizeWidth}
                onChange={handleExerciseChange(setFieldSizeWidth)}
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Field Size Length (m)"
                variant="outlined"
                value={fieldSizeLength}
                onChange={handleExerciseChange(setFieldSizeLength)}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="number-of-players-select-label">Number of Players</InputLabel>
                <Select
                  labelId="number-of-players-select-label"
                  id="number-of-players-select"
                  value={numberOfPlayers}
                  label="Number of Players"
                  onChange={handleExerciseChange(setNumberOfPlayers)}
                >
                  {Array.from({ length: 26 }, (_, i) => i + 1).map((num) => (
                    <MenuItem key={num} value={num}>{num}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="intensity-select-label">Intensity</InputLabel>
                <Select
                  labelId="intensity-select-label"
                  id="intensity-select"
                  value={intensity}
                  label="Intensity"
                  onChange={handleExerciseChange(setIntensity)}
                >
                  {intensityOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body2">Objectives (up to 3):</Typography>
              {objectives.map((objective, index) => (
                <TextField
                  key={index}
                  fullWidth
                  variant="outlined"
                  value={objective}
                  onChange={handleObjectiveChange(index)}
                  placeholder={`Objective ${index + 1}`}
                  sx={{ mb: 2 }}
                />
              ))}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={5}
                label="Instruction"
                variant="outlined"
                value={instruction}
                onChange={handleExerciseChange(setInstruction)}
                sx={{ mb: 2 }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="prescription-select-label">Prescription</InputLabel>
                <Select
                  labelId="prescription-select-label"
                  id="prescription-select"
                  value={prescriptionType}
                  label="Prescription"
                  onChange={handleExerciseChange(setPrescriptionType)}
                >
                  {prescriptionOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="lift-priority-select-label">Exercise Priority</InputLabel>
                <Select
                  labelId="lift-priority-select-label"
                  id="lift-priority-select"
                  value={liftPriority}
                  label="Exercise Priority"
                  onChange={handleExerciseChange(setLiftPriority)}
                >
                  {liftPriorityOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="method-select-label">Method</InputLabel>
                <Select
                  labelId="method-select-label"
                  id="method-select"
                  value={method}
                  label="Method"
                  onChange={handleExerciseChange(setMethod)}
                >
                  {methodOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="loading-side-select-label">Loading Side</InputLabel>
                <Select
                  labelId="loading-side-select-label"
                  id="loading-side-select"
                  value={loadingSide}
                  label="Loading Side"
                  onChange={handleExerciseChange(setLoadingSide)}
                >
                  {loadingSideOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="body-region-select-label">Body Region</InputLabel>
                <Select
                  labelId="body-region-select-label"
                  id="body-region-select"
                  value={bodyRegion}
                  label="Body Region"
                  onChange={handleExerciseChange(setBodyRegion)}
                >
                  {bodyRegionOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="movement-direction-select-label">Movement Direction</InputLabel>
                <Select
                  labelId="movement-direction-select-label"
                  id="movement-direction-select"
                  value={movementDirection}
                  label="Movement Direction"
                  onChange={handleExerciseChange(setMovementDirection)}
                >
                  {movementDirectionOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="diagnosis-select-label">Diagnosis</InputLabel>
                <Select
                  labelId="diagnosis-select-label"
                  id="diagnosis-select"
                  value={diagnosis}
                  label="Diagnosis"
                  onChange={handleExerciseChange(setDiagnosis)}
                >
                  {diagnosisOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="position-select-label">Position</InputLabel>
                <Select
                  labelId="position-select-label"
                  id="position-select"
                  value={position}
                  label="Position"
                  onChange={handleExerciseChange(setPosition)}
                >
                  {positionOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Coefficient 1"
                variant="outlined"
                value={coefficient1}
                onChange={handleExerciseChange(setCoefficient1)}
                sx={{ mb: 2 }}
                inputProps={{ maxLength: 3, style: { textAlign: 'center' } }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Coefficient 2"
                variant="outlined"
                value={coefficient2}
                onChange={handleExerciseChange(setCoefficient2)}
                sx={{ mb: 2 }}
                inputProps={{ maxLength: 3, style: { textAlign: 'center' } }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                fullWidth
                label="Coefficient 3"
                variant="outlined"
                value={coefficient3}
                onChange={handleExerciseChange(setCoefficient3)}
                sx={{ mb: 2 }}
                inputProps={{ maxLength: 3, style: { textAlign: 'center' } }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="muscle-select-label">Muscle</InputLabel>
                <Select
                  labelId="muscle-select-label"
                  id="muscle-select"
                  value={muscle}
                  label="Muscle"
                  onChange={handleExerciseChange(setMuscle)}
                >
                  {muscleOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="sport-task-select-label">Sport Task</InputLabel>
                <Select
                  labelId="sport-task-select-label"
                  id="sport-task-select"
                  value={sportTask}
                  label="Sport Task"
                  onChange={handleExerciseChange(setSportTask)}
                >
                  {sportTaskOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="location-select-label">Location</InputLabel>
                <Select
                  labelId="location-select-label"
                  id="location-select"
                  multiple
                  value={location}
                  label="Location"
                  onChange={(e) => setLocation(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                  renderValue={(selected) => renderValue(selected)}
                >
                  {locationOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="sport-select-label">Sport</InputLabel>
                <Select
                  labelId="sport-select-label"
                  id="sport-select"
                  multiple
                  value={sport}
                  label="Sport"
                  onChange={(e) => setSport(typeof e.target.value === 'string' ? e.target.value.split(',') : e.target.value)}
                  renderValue={(selected) => renderValue(selected)}
                >
                  {sportOptions.map((option, index) => (
                    <MenuItem key={index} value={option}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { resetForm(); setShowAddExerciseModal(false); setShowEditExerciseModal(false); }} color="secondary">Cancel</Button>
          <Button onClick={saveExercise} color="primary">{selectedExercise ? 'Save' : 'Add'}</Button>
          {selectedExercise && <Button onClick={deleteExercise} color="error">Delete</Button>}
        </DialogActions>
      </Dialog>
    </Box>
  );
};

Exercises.propTypes = {
  loadFirebaseData: PropTypes.func.isRequired
};

export default Exercises;
