import * as React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

export default function Solutions() {
  return (
    <Box
      id="solutions"
      sx={{
        pt: { xs: 4, sm: 8 },
        pb: { xs: 8, sm: 12 },
        color: 'white',
        bgcolor: '#0090a',
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 4 },
          maxWidth: '100%', // Make the container full width
          px: { xs: 2, md: 4 }, // Add padding for small screens
        }}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} textAlign="center">
            <Typography component="h2" variant="h4" color="text.primary" gutterBottom>
              Solutions
            </Typography>
            <Typography
              variant="body1"
              color="text.secondary"
              sx={{ mb: { xs: 1, sm: 2 }, maxWidth: '800px', margin: '0 auto' }}
            >
              We are fusing technology into various fitness segments.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={10} md={8}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: 2,
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <Typography component="h3" variant="h5" color="text.primary" sx={{ mb: 2 }}>
                FitCode Pro
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
              Are you involved in competitive sports, where precision to detail is a game-changer? Do you want to gain a comprehensive understanding of every aspect of your athletes' training process? We offer a complete tool to gain insight into the training load of your team or individual athletes. Turn the gym blind spot into a well-defined training space with our innovative solutions.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper
                    sx={{
                      p: 2,
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                      borderRadius: 2,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundImage: 'url(/path/to/outdoor-image.jpg)',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <Typography component="h4" variant="h6" color="inherit">
                      Teams
                    </Typography>
                    <Typography variant="body2" color="inherit">
                    
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper
                    sx={{
                      p: 2,
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                      borderRadius: 2,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundImage: 'url(/path/to/indoor-image.jpg)',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <Typography component="h4" variant="h6" color="inherit">
                    Individuals
                    </Typography>
                    <Typography variant="body2" color="inherit">
                      
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid item xs={12} sm={10} md={8}>
            <Paper
              elevation={3}
              sx={{
                p: 3,
                bgcolor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: 2,
                transition: 'transform 0.3s',
                '&:hover': {
                  transform: 'scale(1.05)',
                },
              }}
            >
              <Typography component="h3" variant="h5" color="text.primary" sx={{ mb: 2 }}>
                FitCode Life
              </Typography>
              <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
                Unleash the ultimate fitness experience for your clients with FitCode Life! This innovative training system combines cutting-edge technology (think interactive trackers, real-time progress monitoring) with a vibrant community, fostering lasting program attachment and driving results. Whether seasoned athletes or fitness newbies, your clients will be empowered by personalized training plans and a supportive environment that fuels motivation and celebrates success. FitCode Life: Where innovation meets community for lifelong wellness.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Paper
                    sx={{
                      p: 2,
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                      borderRadius: 2,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundImage: 'url(/path/to/outdoor-image.jpg)',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <Typography component="h4" variant="h6" color="inherit">
                      Indoor
                    </Typography>
                    <Typography variant="body2" color="inherit">
                      
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Paper
                    sx={{
                      p: 2,
                      bgcolor: 'rgba(255, 255, 255, 0.1)',
                      borderRadius: 2,
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      backgroundImage: 'url(/path/to/indoor-image.jpg)',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center',
                      color: 'white',
                      textAlign: 'center',
                    }}
                  >
                    <Typography component="h4" variant="h6" color="inherit">
                      Outdoor
                    </Typography>
                    <Typography variant="body2" color="inherit">
                      
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
