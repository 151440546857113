import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import SettingsSuggestRoundedIcon from '@mui/icons-material/SettingsSuggestRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';
import Link from '@mui/material/Link';

const items = [
  {
    icon: <SettingsSuggestRoundedIcon />,
    title: 'Movement Technique',
    description:
      'Perfect your form and technique with our real-time feedback tool, minimizing the risk of injury and maximizing efficiency.',
    links: [
      { label: 'Squatting', href: 'https://squat-analysis.netlify.app/' },
      { label: 'Hinging', href: '#' },
      { label: 'Pressing', href: '#' },
      { label: 'Pulling', href: '#' },
      { label: 'Jumping', href: '#' },
    ],
  },
  {
    icon: <ConstructionRoundedIcon />,
    title: 'Performance Testing',
    description:
      'Assess your strength, endurance, and power with our comprehensive testing tool, tailored to your specific sport or activity.',
    links: [
      { label: 'Vertical Jump', href: 'https://squat-analysis.netlify.app/' },
      { label: 'Broad Jump', href: 'Recorder' },
      { label: 'Ankle Jumps - 10/5', href: '#' },
      { label: 'Heading', href: 'https://movenet-jump.netlify.app' },
    ],
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Mobility Assesment',
    description:
      'Unlock your full range of motion with our mobility measurement tool, ensuring you move freely and efficiently in all directions.',
    links: [
      { label: 'ROM', href: 'https://llove01.github.io/mediapipe_web_app/' },
      { label: 'Joint by Joint', href: '#' },
    ],
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Balance Assesment',
    description:
      'Achieve optimal stability and control with our balance measurement tool, essential for injury prevention and performance enhancement.',
    links: [
      { label: 'Link 6', href: '#' },
      { label: 'Link 7', href: '#' },
      { label: 'Link 8', href: '#' },
    ],
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Movement Velocity Assesment',
    description:
      'Unleash your speed potential with our tool that accurately measures and analyzes your movement velocity, helping you outpace the competition.',
    links: [
      { label: 'Link 6', href: '#' },
      { label: 'Link 7', href: '#' },
      { label: 'Link 8', href: '#' },
    ],
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Activity Monitoring',
    description:
      'Stay on track with your training goals with our tool that monitors the quality and consistency of your exercise delivery, ensuring you get the most out of every session.',
    links: [
      { label: 'Link 6', href: '#' },
      { label: 'Link 7', href: '#' },
      { label: 'Link 8', href: '#' },
    ],
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Video Library',
    description:
      'Stay on track with your training goals with our tool that monitors the quality and consistency of your exercise delivery, ensuring you get the most out of every session.',
    links: [
      { label: 'Avatar', href: 'https://avatar11.netlify.app' },
      { label: 'Link 7', href: '#' },
      { label: 'Link 8', href: '#' },
    ],
  },
  {
    icon: <ThumbUpAltRoundedIcon />,
    title: 'Interactive Training',
    description:
      'Stay on track with your training goals with our tool that monitors the quality and consistency of your exercise delivery, ensuring you get the most out of every session.',
    links: [
      { label: 'Lights', href: 'https://gameg1.netlify.app' },
      { label: 'Link 7', href: '#' },
      { label: 'Link 8', href: '#' },
    ],
  },
];

export default function Tools() {
  const [expandedItemIndex, setExpandedItemIndex] = React.useState(null);

  const handleItemClick = (index) => {
    setExpandedItemIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <Box
      id="highlights"
      sx={{
        pt: { xs: 4, sm: 12 },
        pb: { xs: 8, sm: 16 },
        color: 'white',
        bgcolor: '#121212', // Change page background color here
      }}
    >
      <Container
        sx={{
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: '100%', md: '60%' },
            textAlign: 'center', // Align text in the center
          }}
        >
          <Typography component="h2" variant="h4">
            TOOLS
          </Typography>
          <Typography variant="body1" sx={{ color: 'grey.400' }}>
            Explore why our product stands out: adaptability, durability,
            user-friendly design, and innovation. Enjoy reliable customer support and
            precision in every detail.
          </Typography>
        </Box>
        <Grid container spacing={2.5}>
          {items.map((item, index) => (
            <Grid item xs={12} md={4} key={index}>
              <Stack
                direction="column"
                color="inherit"
                component={Card}
                spacing={1}
                useFlexGap
                sx={{
                  p: {xs: 2, sm: 2},
                  border: '0.5px solid',
                  borderColor: '#90caf9',
                  background: 'transparent',
                  backgroundColor: 'rgba(255, 255, 255, 0.05)', // 5% white transparent background
                  borderRadius: '60px',
                  cursor: 'pointer',
                  boxShadow: expandedItemIndex === index ? '0 0 20px rgba(0, 0, 0, 0.2)' : '',
                  transition: 'background-color 0.3s', // Add transition for smooth effect
                  '&:hover': {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // 10% white transparent background on hover
                  },
                  ...(expandedItemIndex === index && {
                    backgroundColor: 'rgba(255, 255, 255, 0.1)', // Change background color when selected
                  }),
                  textAlign: 'center', // Align text in the center
                }}
                onClick={() => handleItemClick(index)}
              >

                <Box sx={{ opacity: '100%', color: '#90caf9' }}>{item.icon}</Box>
                <div>
                  <Typography fontWeight="medium" gutterBottom>
                    {item.title}
                  </Typography>
                  <Typography variant="body2" sx={{ color: 'grey.400' }}>
                    {item.description}
                  </Typography>
                </div>
                {expandedItemIndex === index && (
                  <Stack direction="column" spacing={1}>
                    {item.links.map((link, linkIndex) => (
                      <Link key={linkIndex} href={link.href}>
                        {link.label}
                      </Link>
                    ))}
                  </Stack>
                )}
              </Stack>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
}
